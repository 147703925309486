import {
  FC,
  HTMLAttributes,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'

import { getFilterTokens, getTokenExchangesAndSocial } from '@/api/tokens'
import { Icon, InputSearchDashboardResultCard } from '@/libs/common'
import { IconName, LocalStorageItem, TabName } from '@/libs/enums'
import { TokesSearchEvent } from '@/libs/enums/tokes-search-event.enum'
import { handleError } from '@/libs/helper/handleError'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentToken } from '@/store/slices/chain.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

import { SearchTextfield } from './libs'
import styles from './styles.module.scss'

type TInputSearchProps = Omit<
  AutocompleteProps<unknown, false, false, false, 'div'>,
  'renderInput' | 'options'
> & {
  placeholder?: string
}
const InputSearch: FC<TInputSearchProps> = ({ placeholder }) => {
  const currentTokenGlobal = useAppSelector((state) => state.chain.currentToken)
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [option, setOption] = useState<ITokenInfo[]>([])
  const [selectedAddress, setSelectedAddress] = useState('')
  const timerRef: MutableRefObject<null | NodeJS.Timeout> = useRef(null)
  const chain = useAppSelector((state) => state.chain.currentChain)
  const handleChangeInput = (_: SyntheticEvent<Element, Event>, value: unknown) => {
    setInputValue((value as string) || '')

    if (selectedAddress) {
      setSelectedAddress('')
    }
  }
  const getTokensList = async (phrase: string) => {
    if (phrase === currentTokenGlobal?.token.address) {
      searchParams.set('token', phrase)
      setSearchParams(searchParams)
      return
    }
    setLoading(true)
    const addressLength = 42
    const tokenEvent = searchParams.get('tokenEvent')
    try {
      const { data } = await getFilterTokens(phrase, chain.id)
      if (Array.isArray(data?.data) && data?.data.length > 0) {
        if (data?.data.length > 1) setOption(data?.data)
        if (data?.data.length === 1) {
          const currentToken = data?.data[0]
          const res = await getTokenExchangesAndSocial(currentToken.token.address, chain.id)
          dispatch(fetchUserWalletsWithTokensForce(currentToken.token.address))

          if (phrase.length === addressLength) {
            localStorage.setItem(LocalStorageItem.TOKEN_ADDRESS, currentToken.token.address)
            if (tokenEvent === TokesSearchEvent.BUY || !currentTokenGlobal) {
              searchParams.set(TabName.BUY_SELL, 'volume')
            }
            const socialLinks = { ...res.data.data.token.social }
            const stats = { ...res.data.data.statistics }
            const info = { ...res.data.data.token }
            dispatch(setCurrentToken({ ...currentToken, socialLinks, stats, info }))
            searchParams.set('token', phrase)
            setSearchParams(searchParams)
          }
        }
      } else {
        setOption([])
      }
    } catch (error) {
      handleError(error, 'Error fetching tokens: ')
    } finally {
      if (tokenEvent) {
        searchParams.delete('tokenEvent')
      }
      setSearchParams(searchParams)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!inputValue) {
      setOption([])
      setSelectedAddress('')
      return
    }

    if (!selectedAddress) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      let searchPhrase = inputValue

      if (inputValue.length > 42) {
        const regex = /0x[a-fA-F0-9]{40}/
        const tokenAddress = inputValue.match(regex)
        searchPhrase = tokenAddress ? tokenAddress[0] : inputValue
      }

      timerRef.current = setTimeout(() => {
        getTokensList(searchPhrase)
      }, 400)
    } else {
      getTokensList(selectedAddress)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [inputValue, selectedAddress])

  useEffect(() => {
    const tokenFromPath = searchParams.get('token')
    if (currentTokenGlobal && !tokenFromPath && !location.pathname.includes('modal')) {
      searchParams.set('token', currentTokenGlobal.token.address)
      setSearchParams(searchParams)
    }
  }, [currentTokenGlobal])

  useEffect(() => {
    const tokenFromPath = searchParams.get('token')
    if (!tokenFromPath) return

    if (tokenFromPath !== inputValue) setInputValue(tokenFromPath)
  }, [searchParams])

  useEffect(() => {
    const tokenFromPath = searchParams.get('token')
    if (tokenFromPath) return

    const tokenAddress = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)

    if (tokenAddress) {
      setSelectedAddress(tokenAddress)
    }
  }, [])

  const handleClear = () => {
    setInputValue('')
    setOption([])
    setSelectedAddress('')
  }

  const inputOptions = option.map(
    (tokenInfo) => `${tokenInfo.token.name} | ${tokenInfo.token.address}`,
  )

  const renderOptions = (props: HTMLAttributes<HTMLLIElement>, optionSelected: string) => {
    if (!option) {
      return null
    }

    const entry = option.find(
      (tokenInfo) => `${tokenInfo.token.name} | ${tokenInfo.token.address}` === optionSelected,
    )

    return (
      <li {...props} key={entry?.token.address}>
        <InputSearchDashboardResultCard
          option={entry as ITokenInfo}
          setSelectedAddress={setSelectedAddress}
        />
      </li>
    )
  }

  const getOptionLabel = (option: string) => option.split(' | ')[0]

  return (
    <Autocomplete
      classes={styles}
      className={styles.input}
      freeSolo
      loading={loading}
      aria-expanded={true}
      renderInput={(params) => (
        <SearchTextfield
          {...params}
          placeholder={placeholder}
          variant="standard"
          className="test"
          InputProps={params.InputProps}
        />
      )}
      options={inputOptions}
      renderOption={renderOptions}
      getOptionLabel={getOptionLabel}
      clearIcon={<Icon name={IconName.CLOSE_SMALL} />}
      componentsProps={{
        clearIndicator: {
          onClick: handleClear,
        },
      }}
      value={inputValue}
      onInputChange={handleChangeInput}
    />
  )
}

export { InputSearch, type TInputSearchProps }
