import { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Stack } from '@mui/material'

import { SelectWallet } from '@/components/select-wallet'
import { Button } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { useAppSelector } from '@/store'

const ExportTab: FC = () => {
  const [wallet, setWallet] = useState<string[]>([])
  const wallets = useAppSelector((state) => state.user.userWallets)
  const navigate = useNavigate()
  const { mode } = useParams()

  const handleWalletChange = useCallback((value: string[]) => {
    setWallet(value)
  }, [])

  const handleClick = () => {
    localStorage.exporWallets = JSON.stringify(wallet)
    navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.MASTER_PASSWORD}`)
  }

  return (
    <Stack spacing={3} useFlexGap position="relative">
      <SelectWallet
        tooltipInfo="Select which wallets will be used for export."
        isMulti
        wallet={wallet}
        onChange={handleWalletChange}
        wallets={wallets}
        label="Select wallets to export"
      />

      <Button disabled={wallet.length === 0} onClick={handleClick} checkOnAccountLock>
        Export selected wallets
      </Button>
    </Stack>
  )
}

export { ExportTab }
