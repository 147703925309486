import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { TProfitSellItem, TProfitWallet } from '@/api/profit-loss/types'
import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { PositionCard } from '@/components/position-card'
import { useUpdatingStore } from '@/hooks/updateStore.hook'
import { useAppMode } from '@/hooks/useAppMode'
import {
  Button,
  Divider,
  TokenPlaceholder,
  TokenRedirect,
  Tooltip,
  Typography,
} from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, getPriceImpactColor, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentPosition } from '@/store/slices/profit-sell.slice'

import styles from './styles.module.scss'

type TProps = {
  position: TProfitSellItem
  usedInModal?: boolean
}

const PositionsPage: FC<TProps> = ({ position, usedInModal }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { updateStoreAfterBuySell } = useUpdatingStore()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const returnToPositionsSummariesPage = () => dispatch(setCurrentPosition(null))

  const { mode } = useAppMode()

  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({ loadingAtLeastOne: false })
  const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
    checkedAtLeastOne: false,
    checkedAll: false,
  })

  const availableWallets = useMemo(
    () => position.panel_data.seperate.data.filter((wallet) => +wallet.currentTokens !== 0),
    [position],
  )

  const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedState = {
      ...positionsChecked,
      [event.target.name]: checked,
    }
    const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
    const positionsValues = Object.values(onlyPositions)
    const chackedPositions = positionsValues.filter((checked) => checked)
    const atLeastOneChecked = !!chackedPositions.length
    const checkedAllPositions = positionsValues.length === chackedPositions.length

    setPositionsChecked({
      ...updatedState,
      checkedAtLeastOne: atLeastOneChecked,
      checkedAll: checkedAllPositions,
    })
  }

  const handleLoading = (loading: boolean, selectedWallets: TProfitWallet[]) => {
    const updatedState: Record<string, boolean> = {
      loadingAtLeastOne: loading && !!selectedWallets.length,
    }
    selectedWallets.forEach((el) => (updatedState[el.publicWallet.public_key] = loading))
    setIsLoading(updatedState)
  }

  const sellPosition = async (selectedWallets: TProfitWallet[], type: 'all' | 'initial') => {
    if (!selectedWallets.length) return

    handleLoading(true, selectedWallets)
    const wallets = selectedWallets.map((item) => ({
      name: item.publicWallet.name,
      public_key: item.publicWallet.public_key,
    }))

    const payload = await createSellPayload({
      token: position.panel.token_address,
      wallets,
      mode: type === 'all' ? 0 : 2,
      sellPercentage: type === 'all' ? 100 : 0,
    })

    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }

    handleLoading(false, selectedWallets)
  }

  const setAllChecked = (isChecked = !positionsChecked.checkedAll) => {
    if (!availableWallets) return

    const allPositionsChecked = availableWallets.reduce((sum, { publicWallet }) => {
      return { ...sum, [publicWallet.public_key]: isChecked }
    }, {})

    setPositionsChecked({
      ...positionsChecked,
      ...allPositionsChecked,
      checkedAtLeastOne: isChecked,
      checkedAll: isChecked,
    })
  }

  const handleSellSelected = (type: 'all' | 'initial') => {
    if (!availableWallets) return
    const wallets = availableWallets.filter(
      (wallet) => wallet && positionsChecked[wallet.publicWallet.public_key],
    ) as TProfitWallet[]
    if (wallets?.length && wallets[0]) sellPosition(wallets, type)
  }

  const handleOpenManualSellModal = () => {
    navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.MANUAL_SELL}`, {
      state: {
        wallets: availableWallets.filter(
          (wallet) => wallet && positionsChecked[wallet.publicWallet.public_key],
        ),
        position,
      },
    })
    setAllChecked(false)
  }

  // const [isActiveLoading, setIsActiveLoading] = useState(false)
  // const [isActive, setIsActive] = useState(position.panel.isActive)
  // const [isSellAll, setIsSellAll] = useState(false)
  // const [isSellInitial, setIsSellInitial] = useState(false)
  // const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
  //   checkedAtLeastOne: false,
  //   checkedAll: false,
  // })

  // const handleActive = async (_: BaseSyntheticEvent, newValue: boolean) => {
  //   try {
  //     setIsActiveLoading(true)
  //     setIsActive(newValue)
  //     await setProfitPanelActivity(position.panel._id, newValue)
  //     await dispatch(fetchProfitSell()).unwrap()
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //     setIsActive(!newValue)
  //   } finally {
  //     setIsActiveLoading(false)
  //   }
  // }

  // const handleCloseSellMessage = () => {
  //   if (isSellAll) {
  //     setIsSellAll(false)
  //   } else if (isSellInitial) {
  //     setIsSellInitial(false)
  //   }
  // }

  // const handleOpenEditPositions = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}`, { state: { positionsChecked } })
  // }

  // const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   const updatedState = {
  //     ...positionsChecked,
  //     [event.target.name]: checked,
  //   }

  //   const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
  //   const atLeastOneChecked = Object.values(onlyPositions).some((checked) => checked)

  //   setPositionsChecked({
  //     ...updatedState,
  //     checkedAtLeastOne: atLeastOneChecked,
  //   })
  // }
  //   setPositionsChecked({
  //     ...positionsChecked,
  //     ...allPositionsChecked,
  //     checkedAtLeastOne: !positionsChecked.checkedAll,
  //     checkedAll: !positionsChecked.checkedAll,
  //   })
  // }

  // const invertAllChecked = () => {
  //   const invertedPositionsChecked = mockPositionsData.reduce((result, { id }) => {
  //     return { ...result, [id]: !positionsChecked[id] }
  //   }, {})

  //   setPositionsChecked({
  //     ...positionsChecked,
  //     ...invertedPositionsChecked,
  //     checkedAtLeastOne: !positionsChecked.checkedAll,
  //     checkedAll: !positionsChecked.checkedAll,
  //   })
  // }

  // const handleOpenShareProfitModal = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
  //     state: {
  //       positionsSummary: positionSummary,
  //     },
  //   })
  // }

  return (
    <>
      <Grid container wrap="nowrap" alignItems="center">
        <Button
          className={styles.buttonBack}
          styleVariant="borderless-gold"
          iconStartName={IconName.ARROW_RIGHT}
          onClick={returnToPositionsSummariesPage}
          transparent
        />
        <Typography variant="body2" textColor="color-grey-4">
          Select position
        </Typography>
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <TokenRedirect className={styles.rowContainerItem} address={position.panel.token_address}>
          <Grid container alignItems="center" gap={1}>
            <Grid container className={styles.iconHeader}>
              {position.panel_data.tokenMetadata.logo ? (
                <img
                  className={styles.tokenLogo}
                  src={position.panel_data.tokenMetadata.logo}
                  alt={position.panel_data.tokenMetadata.name + ' token'}
                />
              ) : (
                <TokenPlaceholder token={{} as any} />
              )}
            </Grid>
            {position.panel_data.tokenMetadata.name && (
              <Typography variant="body2" component="span" textColor="light-grey">
                {position.panel_data.tokenMetadata.name}
              </Typography>
            )}
            <Typography variant="body2" component="span">
              ({formatTokenPrice(`${position.panel_data.currentTokens}`).formatted})
            </Typography>
          </Grid>
        </TokenRedirect>

        {/* <Grid container className={styles.rowContainerItem}>
          <Typography variant="body2" noWrap>
            P/S Panel active
          </Typography>
          <Switch checked={isActive} onChange={handleActive} disabled={isActiveLoading} />
        </Grid> */}
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <VerticalGroup
          title={'Profit/Loss'}
          description={
            <span
              className={cn(position.panel_data.profitLoss > 0 ? styles.isProfit : styles.isLoss)}
            >
              {'%'}
              {formatNumber(Math.abs(position.panel_data.profitLoss)).formatted}
            </span>
          }
        />
        <VerticalGroup
          title={'Cost'}
          description={
            <>
              {formatTokenPrice(`${position.panel_data.boughtAmountEth}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'Worth'}
          descClassName={
            position.panel_data.worth > position.panel_data.boughtAmountEth ? styles.greenText : ''
          }
          description={
            <>
              {formatTokenPrice(`${position.panel_data.worth}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'After Tax'}
          descClassName={
            position.panel_data.afterSell > position.panel_data.boughtAmountEth
              ? styles.greenText
              : ''
          }
          description={
            <>
              {formatTokenPrice(`${position.panel_data.afterSell}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'Sold'}
          description={<>{formatNumber(position.panel_data.soldEth).formatted} ETH</>}
        />
        <VerticalGroup
          title={'Price impact'}
          descClassName={getPriceImpactColor(position.panel_data.priceImpact * 100)}
          description={<>{formatNumber(position.panel_data.priceImpact * 100).formatted}%</>}
        />
      </Grid>

      <Divider color="dashboard-border" />

      {availableWallets.length ? (
        <>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" gap={1} className={styles.rowContainerItem}>
              <Typography variant="body2" component="span" textColor="light-grey">
                Open positions
              </Typography>
            </Grid>

            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              className={styles.buttonGroup}
            >
              <Button
                styleVariant="borderless-gold"
                transparent
                className={styles.button}
                onClick={() => setAllChecked()}
              >
                {!positionsChecked.checkedAll ? 'Select all' : 'Unselect all'}
              </Button>

              {/* <Button
                styleVariant="borderless-gold"
                iconStartName={IconName.SHARE}
                transparent
                className={styles.button}
                // disabled={!positionsChecked.checkedAtLeastOne}
                // onClick={handleOpenShareProfitModal}
                disabled
              >
                Share selected
              </Button>

              <Button
                styleVariant="borderless-gold"
                iconStartName={IconName.EDIT}
                transparent
                className={styles.button}
                // disabled={!positionsChecked.checkedAtLeastOne}
                // onClick={handleOpenEditPositions}
                disabled
              >
                Edit selected
              </Button> */}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap={1.5}
            className={cn(styles.cardsContainer, { [styles.usedInModal]: usedInModal })}
          >
            {availableWallets.map((wallet) => (
              <PositionCard
                className={styles.card}
                variant="profit"
                key={wallet.publicWallet.public_key}
                // message={
                //   <PositionSoldCard
                //     isSellAll={isSellAll}
                //     isSellInitial={isSellInitial}
                //     soldTokenData={soldTokenData}
                //     handleCloseSellMessage={handleCloseSellMessage}
                //     setIsSellInitial={setIsSellInitial}
                //     tokenInfo={positionData}
                //   />}
                message={<div></div>}
                isMarketSellLoading={isLoading[wallet.publicWallet.public_key]}
                onMarketSellAll={() => {
                  sellPosition([wallet], 'all')
                }}
                onSellInitial={() => sellPosition([wallet], 'initial')}
                positionData={{
                  id: wallet.publicWallet.public_key,
                  isActive: true,
                  coin: position.panel_data.tokenMetadata.symbol || '',
                  coinCompairedWith: currentChain.description,
                  totalBalance: +wallet.currentTokens || 0,
                  totalWallets: position.panel_data.userWallets.length || 0,
                  profitOrLoss: wallet.profitLoss,
                  cost: `${wallet.boughtAmountEth}`,
                  soldEth: wallet.soldEth,
                  worth: wallet.worth,
                  afterTax: wallet.afterSell || 0,
                  priceImpact: wallet.priceImpact,
                }}
                isSold={false}
                checkboxProps={{
                  name: wallet.publicWallet.public_key,
                  checked: positionsChecked[wallet.publicWallet.public_key] ?? false,
                  onChange: handlePositionChecking,
                }}
              />
            ))}
          </Grid>

          <Grid container wrap="nowrap" className={styles.footer}>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={() => handleSellSelected('all')}
              >
                Sell All
              </Button>
            </Tooltip>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={() => handleSellSelected('initial')}
              >
                Sell Initial
              </Button>
            </Tooltip>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={handleOpenManualSellModal}
              >
                Manual Sell
              </Button>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
          You have no wallets
        </Typography>
      )}
    </>
  )
}

export { PositionsPage }
