import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { TSellForm } from '@/libs/types'
import { TWallet } from '@/libs/types/transaction'
import { store } from '@/store'

type TProps = {
  token?: string
  data?: TSellForm
  wallets: TWallet[]
  sellPercentage: number
  mode?: number
}

const createSellPayload = async ({ token, wallets, sellPercentage, data, mode }: TProps) => {
  const defaultPriorities = getPrioritiesDefault()
  const userData = store.getState().user.userData
  const currentChain = store.getState().chain.currentChain
  const tokenFormStage = store.getState().chain.currentToken?.token.address
  let currentToken
  let currentDex

  if (token && token !== tokenFormStage) {
    const holding = store
      .getState()
      .user.userHoldingsWebsocket?.v.t.find((holding) => holding.a.toLowerCase() === token)

    currentToken = token
    currentDex = holding?.sd
  } else {
    currentToken = tokenFormStage
    currentDex = store.getState().chain.currentTokenSimulationWebsocket?.l.d
  }

  if (!userData) return

  if (!userData || !currentToken || !currentDex) return

  return {
    userId: userData.user_id,
    token: currentToken, // Token address a user wants to sell
    network: {
      blockchain: currentChain.indexerChainId, // Current blockchain id
      dex: +currentDex, // Selected dex service
      // The custom field is not used so far (but it's required by the Sniping), as we specify the default provider in the dex field
      custom: {
        custom_router: '',
        custom_pair: '',
        custom_factory: '',
        mapping: {
          activate: true,
          file_name: '',
        },
      },
    },
    wallets,
    operation: {
      private_tx: data ? data.privateTransaction : true,
      tx_type: 1, // Default value
      bribe_amount: 0, // Default value
      contract: {
        separate_txs: true, // Combined transactions are not working so far, so we use separate ones
      },
      gas: {
        gas_limit: 1000000, // Default value for the internal tests. Later the limit will be calculated automatically
        auto_calculate: true,
        gas: 10, // Remove after the internal tests
        miner_tip: data
          ? +data.sellPriority || +defaultPriorities.sellPriority
          : +defaultPriorities.sellPriority,
      },
      pair: {
        routing: {
          mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
          path: [], // Not specified, as we only use native tokens so far
        },
        fee: {
          mode: 1, // 1 for the automatic calculation
          path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
        },
      },
      setup: {
        mode: mode || (sellPercentage ? 0 : 3), // Sell percentage of amount or sell specific amount of tokens
        sell_percentage: sellPercentage,
        sell_amount: data
          ? +convertScientificNotationNumber(
              mode === 1 ? +data.receive : +data.sell,
              MAX_TRX_DECIMALS,
            )
          : 0,
        rounds_to_sell: 1,
        slippage: data ? +data.slippage : 50,
        sell_to_another_wallet: false, // Default value
        sell_wallet: '', // Default value
      },
      // Shields are default values so far
      shields: {
        anti_tax_mode: 0,
        anti_tax: false,
        sell_tax: 0,
        anti_price_impact: false,
        price_impact: 0,
      },
    },
  }
}

export { createSellPayload }
