/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ManualSnipeForm } from '@/components/manual-snipe-form'
import { SpendReceiveAmount } from '@/components/spend-receive-amount'
import { TemplateSettingsList } from '@/components/template-settings-list'
import { TemplatesControl } from '@/components/templates-control'
import { BackButton, Typography } from '@/libs/common'
import { TSnipeTemplate } from '@/libs/types/snipe.type'

import styles from './styles.module.scss'

const mockTemplates: TSnipeTemplate[] = [
  {
    _id: '1',
    name: 'Manual',
  },
  {
    _id: '2',
    name: 'Fast Buy',
  },
  {
    _id: '3',
    name: 'Max profit',
  },
  {
    _id: '4',
    name: 'Quick trade',
  },
]

const SnipeSettings: FC = () => {
  const { token } = useParams()

  const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  const [currentTemplate, setCurrentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])

  const customizeTemplate = () => {
    if (currentTemplate?._id === '2') {
      setCurrentTemplate(mockTemplates[0])
    }
  }

  return (
    <div>
      <BackButton isAbsolute />

      <Typography variant="h1" className={styles.title}>
        Edit {token} Snipe settings
      </Typography>
      <div className={styles.content}>
        <TemplatesControl
          templates={templates}
          currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
          onSelectTemplate={(id) => id}
          onCustomize={customizeTemplate}
          customizeDisabled={currentTemplate?._id === '1'}
          editDisabled={currentTemplate?._id === '1'}
          deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate._id)}
        />
        {currentTemplate?._id === '1' && <ManualSnipeForm buttonName="Save" />}
        {currentTemplate?._id === '2' && (
          <>
            <TemplateSettingsList />
            <SpendReceiveAmount buttonTitle="Snipe" />
          </>
        )}
      </div>
    </div>
  )
}

export { SnipeSettings }
