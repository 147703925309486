import { FC, useMemo } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cls from 'classnames'

import { useUpdatingStore } from '@/hooks/updateStore.hook'
import { createSellPayload, handleSell } from '@/libs/helper'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { TUserWallet } from '@/libs/types/user.type'

import { Row } from './libs/components/row/row'
import styles from './styles.module.scss'

type TProperty = {
  wallet: TUserWallet
  isHidden?: boolean
  filteredHoldings?: THoldingsToken[] | null
  isHoneypot?: boolean
}

const WalletHoldingsTable: FC<TProperty> = ({ wallet, isHidden, filteredHoldings, isHoneypot }) => {
  const { updateStoreAfterBuySell } = useUpdatingStore()

  const walletHoldings = useMemo(() => {
    return (
      filteredHoldings?.filter((token) => {
        return !!token.w.find(
          (tokenWallet) => tokenWallet.a.toLowerCase() === wallet.public_key.toLowerCase(),
        )
      }) || []
    )
  }, [filteredHoldings])

  const handleMarketSellAll = async (holding: THoldingsToken) => {
    const wallets = [
      {
        name: wallet.name,
        public_key: wallet.public_key,
      },
    ]
    const payload = await createSellPayload({
      token: holding.a,
      wallets,
      sellPercentage: 100,
    })
    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }
  }

  return (
    <div className={styles.container}>
      <Table className={styles.table}>
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell className={cls(styles.cell, styles.bold)}>Token</TableCell>
            <TableCell className={cls(styles.cell, styles.bold)}>Token price</TableCell>
            {/* <TableCell className={cls(styles.cell, styles.bold)}>Description</TableCell> */}
            <TableCell align="right" className={cls(styles.cell, styles.bold, styles.sticky)}>
              Balance
            </TableCell>
            <TableCell className={cls(styles.cell, styles.visible)}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walletHoldings.map((holding) => (
            <Row
              key={holding.a}
              holding={holding}
              isHidden={isHidden}
              onMarketSellAll={() => handleMarketSellAll(holding)}
              isHoneypot={isHoneypot}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export { WalletHoldingsTable }
