import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { lockUser, unlockUser } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { Button, Icon, Typography } from '@/libs/common'
import { OptionalInput } from '@/libs/common/optional-input'
import { AppRoute, IconName } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { passwordValidation } from '@/libs/validations/user'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'

const defaultValues = {
  masterPassword: '',
}

const validationSchema = yup.object({
  masterPassword: passwordValidation,
})

const AccountLocking = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const isToUnlockAccount = userData?.locked

  const [loading, setLoading] = useState(false)

  const { mode } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: isToUnlockAccount ? 'Unlock your account' : 'Lock your account' })
  }, [userData])

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({ masterPassword }) => {
    if (!userData) return
    try {
      setLoading(true)
      const payload = {
        master: masterPassword,
      }

      await (isToUnlockAccount ? unlockUser : lockUser)(payload)
      await dispatch(fetchUserById(userData.user_id)).unwrap()
      CustomToast(
        'success',
        `Your account has been successfully ${isToUnlockAccount ? 'unlocked' : 'locked'}`,
      )
      navigate(`${AppRoute.DASHBOARD}/${mode}`)
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid display="flex" flexDirection="column" alignItems="center" rowGap={3}>
        <Icon name={IconName.LOCK_WITH_REFLECTION} />

        {!isToUnlockAccount && (
          <Grid display="flex" gap={1}>
            <div>
              <Icon name={IconName.INFO_ICON} />
            </div>
            <Typography>
              If your account is locked and you lose your master password, account recovery will not
              be possible.
            </Typography>
          </Grid>
        )}

        <Controller
          name="masterPassword"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              label="Master password"
              placeholder="Enter master password"
              endAdornment={<></>}
              error={!!errors.masterPassword?.message}
              {...field}
            />
          )}
        />

        <Button type="submit" isLoading={loading}>
          {isToUnlockAccount ? 'Unlock' : 'Lock'}
        </Button>
      </Grid>
    </form>
  )
}

export { AccountLocking }
