import { Socket } from 'socket.io-client'

import { getTransactionsEnsNames } from '@/api/tokens'
import { TTokenTransaction } from '@/api/tokens/types'
import { SocketEvent } from '@/socket/libs/constants/constants'

class DefinedService {
  private tokenPairId = ''
  private transactionPairId = ''
  private contractCreator = ''
  constructor(private readonly socket: Socket) {}

  subscribeToken(pairId: string, onDataReceived: (data: any) => void) {
    this.tokenPairId = pairId
    this.socket.emit(SocketEvent.SUBSCRIBE_TOKEN, { pairId })
    this.socket.on(SocketEvent.BARS_UPDATE, (data: any) => {
      onDataReceived(data)
    })
  }
  subscribeTransaction(pairId: string, onDataReceived: (data: any) => void) {
    this.transactionPairId = pairId
    this.socket.emit(SocketEvent.SUBSCRIBE_TRANSACTIONS, { pairId })
    this.socket.on(SocketEvent.TRANSACTIONS_UPDATED, async (data: TTokenTransaction[]) => {
      const makers = data.map((el) => el.maker)
      const { data: ensResponse } = await getTransactionsEnsNames(makers)

      onDataReceived(
        data.map((el) => {
          let ensName = '-'
          if (ensResponse.status === 'success') {
            const currentEnsResponse = ensResponse?.data.find((ens: any) => ens.wallet === el.maker)
            ensName = currentEnsResponse?.data ? currentEnsResponse?.data : '-'
          }
          return {
            ...el,
            ensName,
          }
        }),
      )
    })
  }
  unsubscribeTransaction() {
    this.socket.emit(SocketEvent.UNSUBSCRIBE_TRANSACTIONS, { pairId: this.transactionPairId })
    this.socket.off(SocketEvent.TRANSACTIONS_UPDATED)
  }

  unsubscribeToken() {
    this.socket.off(SocketEvent.BARS_UPDATE)
    this.socket.emit(SocketEvent.UNSUBSCRIBE_TOKEN, { pairId: this.tokenPairId })
  }

  subscribeMempoolTransactions(address: string, onDataReceived: (data: any) => void) {
    this.contractCreator = address
    this.socket.emit(SocketEvent.SUBSCRIBE_MEMPOOL_TRANSACTIONS, { address })
    this.socket.on(SocketEvent.MEMPOOL_TRANSACTION_UPDATE, (data: any) => {
      onDataReceived(data)
    })
  }

  unsubscribeMempoolTransactions() {
    this.socket.off(SocketEvent.MEMPOOL_TRANSACTION_UPDATE)
    this.socket.emit(SocketEvent.UNSUBSCRIBE_MEMPOOL_TRANSACTIONS, {
      address: this.contractCreator,
    })
  }
}

export { DefinedService }
