import { FC, useMemo, useState } from 'react'

import { FormControlLabel, Grid, RadioGroup } from '@mui/material'
import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { useHoldingsWithSettings } from '@/hooks/useHoldingsWithSettings.hook'
import { Accordion, Checkbox, Typography } from '@/libs/common'
import { Radio } from '@/libs/common/radio'
import { SpinnerSize } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppSelector } from '@/store'

import { HoldingsElement } from './libs/holdings-element'
import styles from './styles.module.scss'

// const options = [
//   { value: '1D' },
//   { value: '7D' },
//   { value: '1M' },
//   { value: '1Y' },
//   { value: 'All' },
// ]

// const values = {
//   totalHoldings: '44,003.54',
//   percents: -44.56,
//   ethProfit: 18.151345,
// }

enum EFilter {
  ALL = 'All',
  WALLETS = 'Wallets',
  TOKENS = 'Tokens',
}

const HoldingsAccordion: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const honeypotHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket?.s)
  const holdingsLoading = useAppSelector((state) => state.user.userHoldingsLoading)
  const userData = useAppSelector((state) => state.user.userData)

  const [expanded, setExpanded] = useState(!!userData)
  const [isValid, setIsValid] = useState(true)
  const [filter, setFilter] = useState(EFilter.ALL)

  const { filteredHoldings, holdingsSettings, toggleSort, handleHideSmallBalances } =
    useHoldingsWithSettings()

  const totalHoldingByFilter = useMemo(
    () => ({
      [EFilter.ALL]: {
        usd: filteredHoldings?.tvu,
        native: filteredHoldings?.tvc,
      },
      [EFilter.WALLETS]: {
        usd: filteredHoldings?.tvufw,
        native: filteredHoldings?.tvcfw,
      },
      [EFilter.TOKENS]: {
        usd: filteredHoldings?.tvuft,
        native: filteredHoldings?.tvcft,
      },
    }),
    [filteredHoldings],
  )

  return (
    <Accordion
      title={
        <div className={styles.titleGroup}>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(true)
            }}
          >
            Holdings
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: !isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(false)
            }}
          >
            Honeypot
          </Typography>
        </div>
      }
      enableSorting={isValid && !!filteredHoldings}
      sorting={holdingsSettings.sorting}
      onSortingClick={toggleSort}
      contentWithScroll="holdings"
      expanded={expanded}
      onChange={setExpanded}
    >
      {holdingsLoading ? (
        <Grid marginTop={2}>
          <Spinner size={SpinnerSize.SMALL} centered />
        </Grid>
      ) : (
        <>
          {isValid && !!filteredHoldings && (
            <>
              <RadioGroup
                defaultValue={EFilter.ALL}
                name="balance-filters"
                onChange={(_, value) => setFilter(value as EFilter)}
                className={styles.balanceFilters}
              >
                <FormControlLabel
                  value={EFilter.ALL}
                  control={<Radio label="All" />}
                  label=""
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value={EFilter.WALLETS}
                  control={<Radio label="Balances" />}
                  label=""
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value={EFilter.TOKENS}
                  control={<Radio label="Tokens" />}
                  label=""
                  className={styles.radioWrapper}
                />
              </RadioGroup>
              <Checkbox
                label="Hide Small Balances"
                value={holdingsSettings.hideSmallBalances}
                checked={holdingsSettings.hideSmallBalances}
                onChange={(_, value) => handleHideSmallBalances(value)}
              />
            </>
          )}
          {!!filteredHoldings && isValid && (
            <Grid container className={styles.chartWrapper}>
              {/* <Checkbox label="Hide Honeypot Tokens" /> */}

              <div>
                <Grid container alignItems="center" gap={0.6}>
                  <Typography className={styles.totalHoldings}>
                    ${formatTokenPrice(totalHoldingByFilter[filter].usd || '0').formatted}
                  </Typography>

                  {/* <Typography
            variant="body2"
            className={cn(values.percents > 0 ? styles.profit : styles.loss)}
          >
            {Math.abs(values.percents)}% (All)
          </Typography> */}
                </Grid>

                <Typography variant="body2" className={styles.totalNative}>
                  {formatTokenPrice(totalHoldingByFilter[filter].native || '0').formatted}
                  {currentChain.description}
                </Typography>
              </div>

              {/* <Chart isMiniChart />

      <TimeFrameToggle options={options} /> */}
            </Grid>
          )}
          {isValid && !filteredHoldings?.t.length && (
            <Typography
              variant="body1"
              component="span"
              textColor="grey"
              align="center"
              marginTop={2}
            >
              No holdings
            </Typography>
          )}
          {!isValid && !honeypotHoldings?.length && (
            <Typography
              variant="body1"
              component="span"
              textColor="grey"
              align="center"
              marginTop={2}
            >
              No honeypots
            </Typography>
          )}

          {(isValid ? filteredHoldings?.t : honeypotHoldings)?.map((holding) => (
            <HoldingsElement key={holding.a} holding={holding} isHoneypot={!isValid} />
          ))}
        </>
      )}
    </Accordion>
  )
}

export { HoldingsAccordion }
