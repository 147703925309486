import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'

import { setDecimals } from './set-decimals'

const formatBalanceForHoldings = (holdings: THoldingsToken[]) => {
  return holdings.map((token) => {
    return {
      ...token,
      balanceFormatted: setDecimals(token.tb, +token.d),
      w: token.w.map((wallet) => {
        return {
          ...wallet,
          balanceFormatted: setDecimals(wallet.b, +token.d),
        }
      }),
    }
  })
}

export { formatBalanceForHoldings }
