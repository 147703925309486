import { useEffect, useRef, useState } from 'react'

import cls from 'classnames'

import { CustomToast } from '@/components/custom-toast'
import { FavoritesTable } from '@/components/favorites-table'
import { HoldingsTable } from '@/components/holdings-table'
import { LiteHistoryTable } from '@/components/lite-history-table'
import { PairStats } from '@/components/pair-stats'
import { NoTokenSelected } from '@/components/search-with-token-info/libs/components/no-token-selected'
import { SelectedTokenInfo } from '@/components/selected-token-info'
import { Spinner } from '@/components/spinner'
import { TradingView } from '@/components/trading-view'
import useComponentWidth from '@/hooks/useComponentWidth'
import { InputSearch, Tabs } from '@/libs/common'
import { SpinnerSize } from '@/libs/enums'
// import { PendingBuySellsTable } from '@/components/pending-buy-sells-table'
import { useAppDispatch, useAppSelector } from '@/store'
import { setIsNavigateAvailable } from '@/store/slices/app.slice'
import { fetchUserHistory } from '@/store/slices/favorites-tokens.slice'

import { BuySellBlock } from './libs/buy-sell-block'
import { TokenCard } from './libs/token-card'
import { TransactionsContainer } from './libs/transactions-container'
import styles from './styles.module.scss'

const DashboardComponentLite = () => {
  const dispatch = useAppDispatch()
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [isLoading, setIsLoading] = useState(false)
  const refInfo = useRef<HTMLInputElement>(null)

  const [componentWidth] = useComponentWidth(refInfo)

  useEffect(() => {
    dispatch(setIsNavigateAvailable(true))
  }, [])

  const tabs = [
    {
      label: 'Transactions',
      content: (
        <div className={styles.tabContainer}>
          <TransactionsContainer />
        </div>
      ),
    },
    {
      label: 'My positions',
      content: <HoldingsTable />,
    },
    {
      label: 'Orders History',
      content: <LiteHistoryTable />,
    },
    {
      label: 'Favorites',
      content: <FavoritesTable />,
    },
  ]

  const handleUserHistory = async () => {
    if (!selectedToken) return

    setIsLoading(true)

    try {
      await dispatch(fetchUserHistory(currentChain.id)).unwrap()
    } catch (err) {
      CustomToast('error', 'Cannot fetch user favorites tokens.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleUserHistory()
  }, [selectedToken])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.searchBlock}>
          <InputSearch placeholder="Enter token name or address" />
        </div>

        <div className={cls(styles.block, styles.main)}>
          <div className={styles.tokenInfoBlock} ref={refInfo}>
            {selectedToken ? (
              isLoading ? (
                <Spinner size={SpinnerSize.MEDIUM} centered />
              ) : (
                <SelectedTokenInfo selectedToken={selectedToken} />
              )
            ) : (
              <NoTokenSelected />
            )}
          </div>
          <TradingView componentWidth={componentWidth} />

          <div className={styles.tabWrapper}>
            <Tabs
              tabs={tabs}
              variant={componentWidth < 500 ? 'scrollable' : 'standard'}
              scrollButtons={componentWidth < 500}
              allowScrollButtonsMobile={componentWidth < 500}
            />
          </div>
        </div>
        <div className={styles.sidebar}>
          <TokenCard selectedToken={selectedToken} />
          <PairStats />
          <BuySellBlock />
        </div>
      </div>
    </div>
  )
}

export { DashboardComponentLite }
