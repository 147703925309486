import { importWallets } from '@/api/wallet'
import { TImportWalletParsed, TImportWalletsErrorResponseDataItem } from '@/api/wallet/types'
import { CustomToast } from '@/components/custom-toast'

import { extractErrorDescription } from './extractErrorDescription'

type TProps = {
  wallets: TImportWalletParsed[]
  onSuccess?: () => any
  onPartialSuccess?: (walletsWithError: TImportWalletsErrorResponseDataItem[]) => any
  onError?: () => any
}

const importWalletsHandler = async ({ wallets, onSuccess, onError }: TProps) => {
  try {
    await importWallets(wallets.map((wallet) => wallet.private_key))
    CustomToast('success', 'Your wallets were successfully imported')
    await onSuccess?.()
    // // If there is a global error
    // if (data.status === EWalletImportStatus.ERROR) {
    //   throw new Error(data.error)
    // }
    //
    // const walletsWithError = data.data.reduce((acc, item) => {
    //   if (item.status === EWalletImportStatus.SUCCESS) return acc
    //   acc.push(item)
    //   return acc
    // }, [] as TImportWalletsErrorResponseDataItem[])
    //
    // // If all the wallets were impoted
    // if (!walletsWithError.length) {
    //   await onSuccess?.()
    //   return
    // }
    //
    // // If some wallets were not imported
    // if (walletsWithError.length < wallets.length) {
    //   let message = 'Some wallets were not imported:'
    //   walletsWithError.forEach((wallet) => {
    //     message += `\n${wallet.description};`
    //   })
    //   CustomToast('warning', message)
    //   await onPartialSuccess?.(walletsWithError)
    // }
    // // If no wallets were imported
    // else {
    //   throw new Error('Your wallets were not imported')
    // }
  } catch (err) {
    extractErrorDescription(err)
    await onError?.()
  }
}

export { importWalletsHandler }
