import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client'

type TConnectProps = Partial<ManagerOptions & SocketOptions> & {
  url?: string
}

class SocketService {
  private socket!: Socket
  private url: string = import.meta.env.VITE_INDEXER_URL

  public connect(props?: TConnectProps): void {
    const { url, ...rest } = props || {}
    if (this.socket) {
      return
    }
    if (url) {
      this.url = url
    }
    this.socket = io(this.url, {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      path: '/socket.io',
      transports: ['websocket'],
      ...rest,
    })
  }

  public reconnect(): void {
    if (this.socket) {
      this.disconnect()
    }
    this.socket = io(import.meta.env.VITE_INDEXER_URL, {
      path: '/socket.io',
      transports: ['websocket'],
    })
  }

  public emit(event: string, data: any) {
    this.socket.emit(event, data)
  }

  public on(event: string, listener: any) {
    this.socket.on(event, listener)
  }

  public disconnect = () => {
    this.socket.disconnect()
  }

  public getSocket(): Socket {
    return this.socket
  }
}

export { SocketService }
