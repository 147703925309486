import { BuyTab } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/buy-tab'
import { Typography } from '@/libs/common'

const LiteBuy = () => {
  return (
    <>
      <Typography variant="h2" marginBottom={3}>
        Buy
      </Typography>

      <BuyTab />
    </>
  )
}

export { LiteBuy }
