import { api } from '@/api'

import {
  TCreateProfitPanelPayload,
  TProfitSellDetails,
  TProfitSellItem,
  TProfitSellPanel,
} from './types'

const BASE_URL = 'profit-panel'

const getProfitLossForTokens = async () => api.get<TProfitSellItem[]>(`${BASE_URL}`)
const getProfitLossDetails = async (token: string) =>
  api.get<TProfitSellDetails>(`${BASE_URL}/seperate/${token}`)

const createProfitPanel = (body: TCreateProfitPanelPayload) => api.post(`${BASE_URL}`, body)

const deleteProfitPanel = (panel: TProfitSellPanel) => api.patch(`${BASE_URL}/${panel._id}`, panel)

const setProfitPanelActivity = (panelId: string, isActive: boolean) =>
  api.patch(`${BASE_URL}/${panelId}`, { isActive })

export {
  getProfitLossForTokens,
  createProfitPanel,
  deleteProfitPanel,
  setProfitPanelActivity,
  getProfitLossDetails,
}
