import { store } from '@/store'

const getDefaultTeplate = () => {
  const userWallets = store.getState().user.userWallets

  const mainWallet = userWallets?.find((wallet) => wallet.is_default) || userWallets?.[0]

  if (!mainWallet) {
    return null
  }

  return {
    isPredefined: true,
    id: 'Fast Buy default',
    name: 'Fast Buy',
    setup: {
      token: '',
      network: {
        blockchain: 0,
        dex: 0,
        // dex: null,
      },
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.public_key,
        },
      ],
      operation: {
        private_tx: true,
        tx_type: 1,
        bribe_amount: 0,
        contract: {
          separate_txs: true,
        },
        gas: {
          approve: {
            gas: 10,
            miner_tip: 7,
            auto_calculate: true,
            gas_limit: 1000000,
          },
          buy: {
            gas: 10,
            miner_tip: 7,
            auto_calculate: true,
            gas_limit: 1000000,
          },
        },
        pair: {
          routing: {
            mode: 2,
            path: [],
          },
          fee: {
            mode: 1,
            path_fees: [],
          },
        },
        setup: {
          buy_mode: 0,
          buy_amount: 0,
          buy_tokens: 0,
          supply_percentage: 0,
          rounds_to_buy: 1,
          slippage: 50,
          auto_approve: true,
          force_buy: true,
        },
        shields: {
          anti_fake_liquidity: false,
          anti_tax: true,
          anti_honeypot: true,
          anti_market_cap: false,
          buy_tax: 50,
          sell_tax: 70,
          maximum_liquidity: 0,
          minimum_liquidity: 0,
          maximum_market_cap: 0,
          minimum_market_cap: 0,
          anti_honeypot_mode: 0,
          anti_fake_liquidity_mode: 0,
          anti_tax_mode: 0,
          anti_price_impact: false,
          price_impact: 0,
          anti_buy_sell_ratio: false,
          buy_sell_ratio: 0,
        },
      },
    },
  }
}

export { getDefaultTeplate }
