import { useEffect } from 'react'

import { useUpdatingStore } from '@/hooks/updateStore.hook'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchCurrentTokenSimulation } from '@/store/slices/chain.slice'
import { fetchUserFavorites, fetchUserHistory } from '@/store/slices/favorites-tokens.slice'
import { fetchProfitSell } from '@/store/slices/profit-sell.slice'
import { fetchUserWalletsById } from '@/store/slices/user.slice.ts'

import { useWatchBalances } from './libs/hooks/watch-balances.hook'
import { useWatchChainInfo } from './libs/hooks/watch-chain-info.hook'
import { useWatchHoldings } from './libs/hooks/watch-holdings.hook'
import { useWatchSimulation } from './libs/hooks/watch-simulation.hook'

const DataWatcher = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChainBlock = useAppSelector((state) => state.chain.currentChainBlock)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const { updateStoreAfterBuySell } = useUpdatingStore()

  const dispatch = useAppDispatch()

  // Sniper websockets
  // useWatchChainInfo()
  // useWatchHoldings()
  // useWatchBalances()
  // useWatchSimulation()

  // Handle data once new block is created
  useEffect(() => {
    if (!currentChainBlock || !userData || isAppIdle) {
      return
    }

    dispatch(fetchProfitSell())
    updateStoreAfterBuySell()
  }, [currentChainBlock, isAppIdle])

  // Handle data every block and once new token is selected
  useEffect(() => {
    if (!currentChainBlock || !currentToken || !userData || isAppIdle) {
      return
    }

    dispatch(
      fetchCurrentTokenSimulation({
        mode: 0,
        operation: {
          token: currentToken.token.address,
          bytecode: '0x',
        },
        network: {
          blockchain: currentChain.indexerChainId,
        },
      }),
    )
  }, [currentChainBlock, currentToken, currentChain, isAppIdle])

  useEffect(() => {
    if (!userData || isAppIdle) {
      return
    }
    dispatch(fetchUserFavorites({ networkId: currentChain.id }))
    dispatch(fetchUserHistory(currentChain.id))
    dispatch(fetchUserWalletsById(userData.user_id))
  }, [currentChain.id, isAppIdle, userData])

  return <></>
}

export { DataWatcher }
