import { api } from '@/api'
import { TOrderResponse } from '@/libs/types'

import { TSimulationBody, TSimulationResponse } from './types'

const BASE_URL = 'orders'

const makeManualBuy = (body: any) => api.post(`${BASE_URL}/buy`, body)

const makeApprove = (body: any) => api.post(`${BASE_URL}/approval`, body)

const makeSell = (body: any) => api.post(`${BASE_URL}/sell`, body)

const getSimulation = (body: TSimulationBody) =>
  api.post<TSimulationResponse>(`${BASE_URL}/simulation-extra`, body)

const getOrdersHistory = () => api.get<TOrderResponse>(`${BASE_URL}/history/parsed`)

const disperse = (body: any) => api.post(`${BASE_URL}/disperse`, body)

const collect = (body: any) => api.post(`${BASE_URL}/collect`, body)

export { makeManualBuy, makeApprove, makeSell, getSimulation, getOrdersHistory, disperse, collect }
