import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { updateFavoritesTokensOrder } from '@/api/favorites'
import { TUserFavorite } from '@/api/favorites/types'
import { CustomToast } from '@/components/custom-toast'
import {
  Accordion,
  AccordionListElement,
  ButtonIcon,
  TokenPlaceholder,
  Typography,
} from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import { removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const FavoritesAccordion: FC = () => {
  const navigate = useNavigate()
  const userFavorites = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const userHistory = useAppSelector((state) => state.favoritesTokens.userHistory)
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)
  const dispatch = useAppDispatch()

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)
  const [items, setItems] = useState<TUserFavorite[] | null>(null)
  const [isFavorites, setIsFavorites] = useState(true)

  useEffect(() => {
    if (isFavorites) {
      if (userFavorites) setItems(userFavorites)
    } else {
      if (userFavorites) setItems(userHistory)
    }
  }, [userFavorites, userHistory, isFavorites])

  const handleChangeOrder = (_: number, newIndex: number) => {
    const changedItem = items?.[newIndex]
    if (!changedItem) return
    updateFavoritesTokensOrder({
      blockchain: networkId,
      token: changedItem.token,
      order: (newIndex + 1).toString(),
    })
  }

  const handleRemoveFavoriteToken = (tokenAddress: string) => {
    dispatch(removeFavoriteToken({ tokenAddress, networkId }))
  }

  const setNewCurrentToken = (tokenAddress: string) => {
    dispatch(fetchNewCurrentToken(tokenAddress))
    dispatch(fetchUserWalletsWithTokensForce(tokenAddress))
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}?${TabName.DASHBOARD_NAV}=dashboard&token=${tokenAddress}`,
    )
  }

  const renderItem = useCallback(
    (item: TUserFavorite, isDragging: boolean) => (
      <AccordionListElement
        enableBurger={isFavorites}
        className={styles.favoriteItem}
        disableHover={isSomeElementDragging && !isDragging}
        onClick={() => {
          setNewCurrentToken(item.token)
        }}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          className={styles.container}
        >
          <div className={styles.controls}>
            {isFavorites && (
              <ButtonIcon
                icon={IconName.CROSSED_STAR}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveFavoriteToken(item.token)
                }}
              />
            )}

            <div className={styles.iconWrapper}>
              {item.image ? (
                <img src={item.image} className={styles.icon} loading="lazy" alt={item.name} />
              ) : (
                <TokenPlaceholder token={item} size="35px" />
              )}

              {/* <img
                src={item.pairImg}
                loading="lazy"
                alt="network logo"
                className={styles.network}
              /> */}
            </div>

            <Grid container flexDirection="column">
              <Typography variant="body2" className={styles.symbol}>
                {item.symbol}
              </Typography>

              <Typography variant="body2" className={styles.name}>
                {item.name}
              </Typography>
            </Grid>
          </div>

          <Grid className={styles.info}>
            <Typography variant="body2" className={styles.price}>
              ${formatTokenPrice(item.price).formatted}
            </Typography>

            <Typography
              variant="body2"
              textAlign="end"
              className={cn(Number(item.price_change_24h) > 0 ? styles.profit : styles.loss)}
            >
              {formatTokenPrice(item.price_change_24h).formatted}%
            </Typography>
          </Grid>
        </Grid>
      </AccordionListElement>
    ),
    [isSomeElementDragging, isFavorites],
  )

  return (
    <Accordion
      title={
        <div className={styles.titleGroup}>
          <Typography
            className={cn(styles.titleTab, isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(true)
            }}
          >
            Favorites
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, !isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(false)
            }}
          >
            History
          </Typography>
        </div>
      }
      contentWithScroll="favorites"
    >
      {items?.length ? (
        isFavorites ? (
          <DragAndDropList
            propOfItemForKey="token"
            data={items}
            setData={setItems}
            renderItem={renderItem}
            onDragStateChange={(state) => {
              setIsSomeElementDragging(state)
            }}
            onOrderChanged={handleChangeOrder}
          />
        ) : (
          items.map((item) => renderItem(item, false))
        )
      ) : (
        <Typography variant="body1" component="span" textColor="grey" align="center">
          No favorites
        </Typography>
      )}
    </Accordion>
  )
}

export { FavoritesAccordion }
