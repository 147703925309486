import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { createBuyTemplate, updateBuyTemplate } from '@/api/templates'
import { CustomToast } from '@/components/custom-toast'
import { SelectWallet } from '@/components/select-wallet'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { BackButton, Button, Input, Tabs, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import {
  AppMode,
  AppRoute,
  ETemplateType,
  IconName,
  ManualBuyTemplateAction,
  ManualSettingsSection,
} from '@/libs/enums'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { validateRequiredFields } from '@/libs/helper/validateRequiredFields'
import { TBuyTemplate, TBuyTemplatePayload } from '@/libs/types/template'
import { TWallet } from '@/libs/types/transaction'
import { getManualBuyValidationSchema } from '@/libs/validations/buy'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserBuyTemplates, setUserBuyTemplates } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const advancedBuyDefaultValues = {
  buyPriority: '',
  approvePriority: '',
  minPercentTokenOrFail: '',
  maxTxOrFail: false,
}

const shieldsDefaultValues = {
  buy_tax: '',
  sell_tax: '',
  minimum_liquidity: '',
  maximum_liquidity: '',
  maximum_market_cap: '',
}

const ManualBuyTemplate: FC = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const buyTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { id: templateId } = useParams()

  const { setModalProps } = useModal()

  const templateToEdit = useMemo(
    () => buyTemplates?.find((item) => item.id === templateId),
    [buyTemplates],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isContinueDisabled, setIsContinueDisabled] = useState(true)
  // const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [walletsOnProfit, setWalletsOnProfit] = useState<number[]>(
  //   defaultValues.walletsToSellOnProfit,
  // )

  const isLastMainTab = currentTab === Object.values(ManualSettingsSection).length - 1

  const schema = yup.object({
    name: yup.string().required().min(4).max(64),
    template: getManualBuyValidationSchema(0, { excludeBuyFields: true }),
  })

  const ordinaryBuyDefaultValues = useMemo(() => {
    const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.is_default)

    return {
      privateTransaction: true,
      degenChadMode: false,
      slippage: 50,
      // buyPriority: '',
      selectedWallets: defaultWallet ? [defaultWallet.public_key] : [],
      // onLimit: {
      //   dip: '',
      //   marketcap: '',
      //   price: '',
      //   triggerPricePercent: '',
      //   expiration: '',
      // },
      spend: '',
      receive: '',
    }
  }, [userWallets])

  const defaultValues = {
    name: '',
    template: {
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
    },
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues:
      !!templateId && !!templateToEdit
        ? createBuyFormStateFromTemplate(templateToEdit)
        : defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const fieldsRequiredForBuy = watch([
    'template.ordinaryBuy.slippage',
    'template.ordinaryBuy.selectedWallets',
  ])

  useEffect(() => {
    const action = templateId ? ManualBuyTemplateAction.EDIT : ManualBuyTemplateAction.ADD
    setModalProps({
      title: getTemplateActionTitle(action),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  useEffect(() => {
    setIsContinueDisabled(validateRequiredFields(fieldsRequiredForBuy))
  }, [fieldsRequiredForBuy])

  // const handleProfitTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }, [])

  const handleChangeMainTab = (value: number) => {
    setCurrentTab(value)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userWallets || !userData) return

    try {
      setIsLoading(true)

      const wallets: TWallet[] = []
      data.template.ordinaryBuy.selectedWallets.forEach((publicKey) => {
        const wallet = userWallets.find((item) => item.address === publicKey)

        if (wallet) {
          wallets.push({
            name: wallet.name,
            public_key: wallet.address,
          })
        }
      })

      const templateSetup = createManualBuyPayload({
        data: data.template,
        wallets,
        isTemplate: true,
      })

      if (!templateSetup) {
        throw new Error('Failed to create template')
      }

      const payload = {
        blockchain: templateSetup.network.blockchain,
        name: data.name,
        template: {
          type: ETemplateType.MANUAL_BUY,
          setup: templateSetup,
        },
      }

      let newTemplatesList: TBuyTemplate[] = []

      if (templateId) {
        if (payload.template.setup?.network && templateToEdit?.setup.network) {
          payload.template.setup.network.blockchain = templateToEdit.setup?.network.blockchain
          payload.blockchain = templateToEdit.setup.network.blockchain
        }
        const { data } = await updateBuyTemplate(templateId, payload as TBuyTemplatePayload)
        newTemplatesList = data.data
      } else {
        const { data } = await createBuyTemplate(payload as TBuyTemplatePayload)
        newTemplatesList = data.data
      }

      dispatch(setUserBuyTemplates(newTemplatesList))

      navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)

      CustomToast('success', `Your template was successfully ${templateId ? 'edited' : 'created'}`)
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 630

  return (
    <div>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid className={styles.settings} container rowGap={0.5}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    label="Template Name"
                    placeholder="Enter template name"
                    className={styles.input}
                    error={!!errors.name?.message}
                    maxLength={64}
                    {...field}
                  />
                )}
              />

              <Tabs
                value={currentTab}
                handleChangeTab={handleChangeMainTab}
                tabs={[
                  {
                    label: ManualSettingsSection.BUY,
                    content: (
                      <Grid container rowGap={2}>
                        <Grid
                          container
                          gap={3}
                          flexWrap="nowrap"
                          flexDirection={isResponsive ? 'column' : 'row'}
                        >
                          <Controller
                            name="template.ordinaryBuy.privateTransaction"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Anti-Mev"
                                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="template.ordinaryBuy.degenChadMode"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Degen Chad Mode"
                                tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <Controller
                          name="template.ordinaryBuy.slippage"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label="Slippage"
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                              placeholder="X"
                              error={!!errors.template?.ordinaryBuy?.slippage?.message}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="template.advancedBuy.buyPriority"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              isNumeric
                              className={styles.input}
                              label="Buy Priority"
                              placeholder="Enter gwei amount"
                              tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                              endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                              error={!!errors.template?.advancedBuy?.buyPriority?.message}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="template.ordinaryBuy.selectedWallets"
                          control={control}
                          render={({ field: { ref, value, onChange, ...field } }) => (
                            <SelectWallet
                              tooltipInfo="Select which wallets will be used to purchase token."
                              isMulti
                              wallet={value}
                              wallets={userWallets}
                              onChange={(value) => {
                                onChange(value)
                              }}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="template.advancedBuy.minPercentTokenOrFail"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              className={styles.input}
                              label="Min % / Tokens or fail"
                              placeholder="Enter token amount or %"
                              isNumeric
                              tooltipInfo="Minimal percentage of total supply or exact amount in ETH equivalent of tokens that should be bought or the transaction will fail."
                              endAdornment={<></>}
                              disabled={watch('template.advancedBuy.maxTxOrFail')}
                              error={!!errors.template?.advancedBuy?.minPercentTokenOrFail?.message}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="template.advancedBuy.maxTxOrFail"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <SwitchInline
                              label="Max Tx or fail"
                              tooltipInfo="Will try to buy maximum available amount of tokens in one transaction. The transaction will fail if this cannot be done."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    ),
                  },
                  {
                    isDisabled: isContinueDisabled,
                    label: ManualSettingsSection.SAFETY,
                    content: (
                      <Grid container rowGap={2} flexDirection="column">
                        <Grid
                          container
                          flexWrap="nowrap"
                          gap={3}
                          flexDirection={isResponsive ? 'column' : 'row'}
                        >
                          <Controller
                            name="template.shields.buy_tax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Buy Tax"
                                adornmentText="%"
                                tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                error={!!errors.template?.shields?.buy_tax?.message}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="template.shields.sell_tax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Sell Tax"
                                adornmentText="%"
                                tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                error={!!errors.template?.shields?.sell_tax?.message}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          container
                          flexWrap="nowrap"
                          gap={3}
                          flexDirection={isResponsive ? 'column' : 'row'}
                        >
                          <Controller
                            name="template.shields.minimum_liquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter min liquidity amount"
                                isNumeric
                                label="Min Liquidity"
                                adornmentText="$"
                                tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                error={!!errors.template?.shields?.minimum_liquidity?.message}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="template.shields.maximum_liquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter max liquidity amount"
                                isNumeric
                                label="Max Liquidity"
                                adornmentText="$"
                                tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                error={!!errors.template?.shields?.maximum_liquidity?.message}
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <Controller
                          name="template.shields.maximum_market_cap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter max marketcap"
                              isNumeric
                              label="Max Marketcap"
                              adornmentText="$"
                              tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                              error={!!errors.template?.shields?.maximum_market_cap?.message}
                              {...field}
                            />
                          )}
                        />
                        {/* <Grid container columnGap={3} flexWrap="nowrap">
                          <Controller
                            name="transferOnBlacklist"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Transfer On Blacklist"
                                tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="antiBlacklist"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Anti-Blacklist"
                                tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <HiddenInputs
                          expanded={isAntiRugSettings}
                          trigger={
                            <Controller
                              name="antiRug"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Anti-Rug"
                                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    setIsAntiRugSettings(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          }
                        >
                          <Grid container rowGap={2}>
                            <Controller
                              name="antiRugThreshold"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <PercentsInput
                                  label="Anti-Rug Threshold"
                                  onOptionSelect={(value) => field.onChange(value)}
                                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="sellRugExtraTip"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Amount"
                                  isNumeric
                                  label="Sell Rug Extra Tip"
                                  tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </HiddenInputs>

                        <Grid container flexWrap="nowrap" columnGap={3}>
                          <Controller
                            name="maxBuyTax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Buy Tax"
                                adornmentText="%"
                                tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="maxSellTax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Sell Tax"
                                adornmentText="%"
                                tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid container flexWrap="nowrap" columnGap={3}>
                          <Controller
                            name="minLiquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter min liquidity amount"
                                isNumeric
                                label="Min Liquidity"
                                adornmentText="$"
                                tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="maxLiquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter max liquidity amount"
                                isNumeric
                                label="Max Liquidity"
                                adornmentText="$"
                                tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Controller
                          name="maxMarketcap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter max marketcap"
                              isNumeric
                              label="Max Marketcap"
                              adornmentText="$"
                              tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                              {...field}
                            />
                          )}
                        /> */}
                      </Grid>
                    ),
                  },
                  // {
                  //   label: ManualSettingsSection.SELL,
                  //   content: (
                  //     <HiddenInputs
                  //       expanded={isAutoSellSettings}
                  //       trigger={
                  //         <Controller
                  //           name="autoSell"
                  //           control={control}
                  //           render={({ field: { ref, onChange, ...field } }) => (
                  //             <SwitchInline
                  //               label="Auto Sell"
                  //               tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                  //               {...field}
                  //               onChange={(
                  //                 _: React.ChangeEvent<HTMLInputElement>,
                  //                 checked: boolean,
                  //               ) => {
                  //                 setIsAutoSellSetting(checked)
                  //                 onChange(checked)
                  //               }}
                  //             />
                  //           )}
                  //         />
                  //       }
                  //     >
                  //       <Grid container rowGap={2} flexDirection="column">
                  //         <Controller
                  //           name="sellPriority"
                  //           control={control}
                  //           render={({ field: { ref, ...field } }) => (
                  //             <OptionalInput
                  //               placeholder="Enter gwei amount"
                  //               isNumeric
                  //               label="Sell Priority"
                  //               tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                  //               endAdornment={
                  //                 <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                  //               }
                  //               onAutoChange={() => {
                  //                 //TO DO: added auto switch
                  //               }}
                  //               {...field}
                  //             />
                  //           )}
                  //         />
                  //         <div className={styles.dividerContainer}>
                  //           <Typography variant="body2" textColor="grey">
                  //             Profit
                  //           </Typography>
                  //           <div className={styles.divider}></div>
                  //         </div>
                  //         <Controller
                  //           name="walletsToSellOnProfit"
                  //           control={control}
                  //           render={({ field: { ref, value, onChange, ...field } }) => (
                  //             <SelectWallet
                  //               tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                  //               label="Wallets to sell on Profit"
                  //               isMulti
                  //               onChange={(value) => {
                  //                 setWalletsOnProfit(value)
                  //                 onChange(value)
                  //               }}
                  //               wallet={value}
                  //               {...field}
                  //             />
                  //           )}
                  //         />

                  //         <ButtonGroupRadio
                  //           onChange={handleProfitTabChange}
                  //           value={profitTab}
                  //           exclusive
                  //           className={styles.group}
                  //         >
                  //           {ProfitSettingsTabs.map((tab) => (
                  //             <ButtonGroupRadioButton
                  //               value={tab.value}
                  //               key={tab.value}
                  //               className={cls({ [styles.bgGrey]: tab.value === profitTab })}
                  //             >
                  //               {tab.label}
                  //             </ButtonGroupRadioButton>
                  //           ))}
                  //         </ButtonGroupRadio>

                  //         {profitTab === ProfitSettingsTabs[0].value && (
                  //           <div>
                  //             <div className={styles.labelContent}>
                  //               <InputLabel className={styles.label}>
                  //                 <Typography variant="body2" textColor="light-grey">
                  //                   Sell in steps
                  //                 </Typography>
                  //               </InputLabel>
                  //               <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                  //             </div>
                  //             {walletsOnProfit.length !== 0 ? (
                  //               <div className={styles.stepWrapper}>
                  //                 {walletsOnProfit.map((wallet, index) => (
                  //                   <Controller
                  //                     key={wallet}
                  //                     name={`w${wallet}` as keyof typeof defaultValues}
                  //                     control={control}
                  //                     render={({ field: { ref, ...field } }) => (
                  //                       <StepInput
                  //                         wallet="#1"
                  //                         balance="12.58 ETH"
                  //                         className={cls({
                  //                           [styles.firstInput]: index === 0,
                  //                           [styles.lastInput]:
                  //                             index === walletsOnProfit.length - 1,
                  //                         })}
                  //                         {...field}
                  //                       />
                  //                     )}
                  //                   />
                  //                 ))}
                  //               </div>
                  //             ) : (
                  //               <Grid marginTop={1}>
                  //                 <Typography variant="body1">No wallets selected</Typography>
                  //               </Grid>
                  //             )}
                  //           </div>
                  //         )}
                  //         {profitTab === ProfitSettingsTabs[1].value && (
                  //           <Grid container flexWrap="nowrap" columnGap={3}>
                  //             <Controller
                  //               name="sellOnProfit"
                  //               control={control}
                  //               render={({ field: { ref, ...field } }) => (
                  //                 <OptionalInput
                  //                   placeholder="Enter sell on profit"
                  //                   isNumeric
                  //                   label="Sell on Profit"
                  //                   adornmentText="%"
                  //                   tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                  //                   {...field}
                  //                 />
                  //               )}
                  //             />
                  //             <Controller
                  //               name="sellOnProfitAmount"
                  //               control={control}
                  //               render={({ field: { ref, ...field } }) => (
                  //                 <OptionalInput
                  //                   placeholder="Enter sell profit amount"
                  //                   isNumeric
                  //                   label="Sell Profit Amount"
                  //                   adornmentText="%"
                  //                   tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                  //                   {...field}
                  //                 />
                  //               )}
                  //             />
                  //           </Grid>
                  //         )}
                  //         {profitTab === ProfitSettingsTabs[2].value ? (
                  //           <>
                  //             <Controller
                  //               name="walletsToTrailingSell"
                  //               control={control}
                  //               render={({ field: { ref, value, ...field } }) => (
                  //                 <SelectWallet
                  //                   isMulti
                  //                   wallet={value}
                  //                   label="Wallets to trailing sell"
                  //                   tooltipInfo="tooltipInfo"
                  //                   {...field}
                  //                 />
                  //               )}
                  //             />
                  //             <Controller
                  //               name="trailingSell"
                  //               control={control}
                  //               render={({ field: { ref, ...field } }) => (
                  //                 <OptionalInput
                  //                   placeholder="Enter trailing sell"
                  //                   isNumeric
                  //                   label="Trailing Sell"
                  //                   adornmentText="%"
                  //                   tooltipInfo="tooltipInfo"
                  //                   {...field}
                  //                 />
                  //               )}
                  //             />
                  //           </>
                  //         ) : (
                  //           <>
                  //             <div className={styles.dividerContainer}>
                  //               <Typography variant="body2" textColor="grey">
                  //                 Loss
                  //               </Typography>
                  //               <div className={styles.divider}></div>
                  //             </div>
                  //             <Controller
                  //               name="walletsToSellOnStopLoss"
                  //               control={control}
                  //               render={({ field: { ref, value, ...field } }) => (
                  //                 <SelectWallet
                  //                   tooltipInfo="Select how many wallets will sell when the loss threshold is reached. For example, 1st wallet will sell at 20% loss, 2nd wallet will sell at 30% loss."
                  //                   label="Wallets to sell on Stop Loss"
                  //                   isMulti
                  //                   wallet={value}
                  //                   {...field}
                  //                 />
                  //               )}
                  //             />
                  //             <Grid container flexWrap="nowrap" columnGap={3}>
                  //               <Controller
                  //                 name="stopLoss"
                  //                 control={control}
                  //                 render={({ field: { ref, ...field } }) => (
                  //                   <OptionalInput
                  //                     placeholder="Enter sell on loss %"
                  //                     isNumeric
                  //                     label="Stop Loss"
                  //                     tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                  //                     adornmentText="%"
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />
                  //               <Controller
                  //                 name="stopLossAmount"
                  //                 control={control}
                  //                 render={({ field: { ref, ...field } }) => (
                  //                   <OptionalInput
                  //                     placeholder="Enter sell on loss amount"
                  //                     isNumeric
                  //                     label="Stop Loss Amount"
                  //                     tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                  //                     adornmentText="%"
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />
                  //             </Grid>
                  //           </>
                  //         )}
                  //       </Grid>
                  //     </HiddenInputs>
                  //   ),
                  // },
                ]}
              />
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button
                styleVariant="black"
                onClick={() => handleChangeMainTab(currentTab + 1)}
                disabled={isContinueDisabled || isLastMainTab}
              >
                Continue
              </Button>
              <Button
                type="submit"
                disabled={!isLastMainTab}
                isLoading={isLoading}
                checkOnAccountLock
              >
                {templateId ? 'Save' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { ManualBuyTemplate }
