import { SellTab } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/sell-tab'
import { Typography } from '@/libs/common'

const LiteSell = () => {
  return (
    <>
      <Typography variant="h2" marginBottom={3}>
        Sell
      </Typography>

      <SellTab />
    </>
  )
}

export { LiteSell }
