import { useState } from 'react'

import cls from 'classnames'

import styles from '@/components/dashboard-component-lite/styles.module.scss'
import { Tabs } from '@/libs/common'
import { TabName } from '@/libs/enums'

import { BuyTab } from './libs/buy-tab'
import { SellTab } from './libs/sell-tab'

const BuySellBlock = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleExpand = (value: boolean) => setIsExpanded(value)
  const tabs = [
    {
      label: 'Buy',
      content: <BuyTab onExpand={handleExpand} />,
    },
    {
      label: 'Sell',
      content: <SellTab onExpand={handleExpand} />,
    },
  ]
  return (
    <div className={cls(styles.block, styles.buySellBock, isExpanded && styles.expanded)}>
      <Tabs
        tabs={tabs}
        name={TabName.BUY_SELL_LITE}
        onChange={() => {
          setIsExpanded(false)
        }}
      />
    </div>
  )
}

export { BuySellBlock }
