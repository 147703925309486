import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppMode, AppRoute } from '@/libs/enums'
import { useAppSelector } from '@/store'

const useCheckUser = () => {
  const navigate = useNavigate()
  const userData = useAppSelector((state) => state.user.userData)
  const { mode } = useParams()

  return useCallback(
    (callback: any) => {
      if (!userData) {
        navigate(
          `${AppRoute.DASHBOARD}/${mode || AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.CONNECT_WALLET}`,
        )
        return
      }
      if (callback) callback()
    },
    [userData, navigate],
  )
}

export default useCheckUser
