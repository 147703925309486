import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import styles from './styles.module.scss'

interface Column {
  id: 'action' | 'source' | 'ipAddress' | 'location' | 'when'
  label: string
  minWidth?: number
  align?: 'right' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'action', label: 'Action', minWidth: 230 },
  {
    id: 'source',
    label: 'Source',
    minWidth: 200,
  },
  { id: 'ipAddress', label: 'IP Address', minWidth: 190 },
  {
    id: 'location',
    label: 'Location',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'when',
    label: 'When',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
]

interface Data {
  action: string
  source: string
  ipAddress: string
  location: string
  when: string
}

function createData(
  action: string,
  source: string,
  ipAddress: string,
  location: string,
  when: string,
): Data {
  return { action, source, ipAddress, location, when }
}

const rows = [
  createData('signin', 'web', '202.124.54.144', 'Lithuania', '3 minutes ago'),
  createData('second factor failure', 'web', '212.222.54.144', 'Lithuania', '6 minutes ago'),
  createData('01:32:44', 'Buy', 'Buy', 'Lithuania', 'Buy'),
  createData('01:02:12', 'Buy', 'Buy', 'Lithuania', 'Buy'),
]

const AccountActivityTable = () => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className={styles.container}>
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={cn(styles.tableHeadCell, {
                    [styles.tableHeadCellWhen]: column.id === 'when',
                    [styles.tableHeadCellSource]: column.id === 'source',
                    [styles.tableHeadCellLocation]: column.id === 'location',
                    [styles.tableHeadCellAction]: column.id === 'action',
                  })}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {rows.map((row, indx) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.when + indx}
                  className={cn(styles.bodyRow, { [styles.background]: indx % 2 !== 0 })}
                >
                  {columns.map((column) => {
                    const value = row[column.id]

                    return (
                      <TableCell key={column.id} align={column.align} className={cn(styles.cell)}>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { AccountActivityTable }
