import { useNavigate } from 'react-router-dom'

import { signUp } from '@/api/user'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import { Button, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

export const AlreadySign = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  async function addUser() {
    try {
      const data = JSON.parse(localStorage.signData)
      return signUp(data).then(async (res) => {
        localStorage.signUpData = JSON.stringify(res.data.data)
        localStorage.token = res.data.data.token
        localStorage.refreshToken = res.data.data.refresh_token
        await dispatch(fetchUserById(res.data.data.id)).unwrap()
      })
    } catch (e) {
      console.error(e)
      CustomToast('error', (e as any).message)
    }
  }
  return (
    <AuthCard className={styles.card}>
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title} align="center">
            Did you already sign up before with an another wallet?
          </Typography>
        </div>
        <Button
          styleVariant="yellow"
          onClick={() => {
            addUser().then(() => navigate(AppRoute.CREATE_MASTER_PASSWORD, { replace: false }))
          }}
        >
          I am a new user
        </Button>

        <Button
          styleVariant="black"
          onClick={() => {
            navigate(`/${AppRoute.MODAL}/${AppRoute.WALLET_CONNECT}`)
          }}
        >
          I have signed up before with an another wallet
        </Button>
      </div>
    </AuthCard>
  )
}
