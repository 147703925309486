import { AuthCard } from '@/components/auth-card'
import { VerificationStatus } from '@/libs/enums'
import { GenerateNewWallet } from '@/pages/modal-page/libs/components'

import styles from './styles.module.scss'

const GenerateWallets = () => {
  return (
    <AuthCard
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      className={styles.card}
      isHeaderButtons
    >
      <GenerateNewWallet duringSignUp />
    </AuthCard>
  )
}

export { GenerateWallets }
