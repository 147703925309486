import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getSimulation } from '@/api/orders'
import { TSimulationBody } from '@/api/orders/types'
import { changeUserBuyTemplatesOrder } from '@/api/templates'
import { getFilterTokens, getTokenExchangesAndSocial, getTokenTransactions } from '@/api/tokens'
import { chainsConfig } from '@/libs/configs/chains.config'
import { LocalStorageItem } from '@/libs/enums'
import { getDexForToken } from '@/libs/helper/getDexForToken'
import { TChainConfig } from '@/libs/types/chain.type'
import {
  TSniperSimulationData,
  TSniperSimulationResponse,
} from '@/libs/types/sniper-simulation-socket-response.type'
import { TSniperBasicResponse } from '@/libs/types/sniper-socket-responses.type'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { TRootState } from '@/store'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TInitialState = {
  currentChain: TChainConfig & { nativeTokenPriceInUsd?: number }
  currentChainBlock: string | null
  currentGas: string | null
  currentToken: ITokenInfo | null
  currentTokenSimulationWebsocket: TSniperSimulationData | null
  simulationLoading: boolean
}

const initialState: TInitialState = {
  currentChain: chainsConfig[0],
  currentChainBlock: null,
  currentGas: null,
  currentToken: null,
  currentTokenSimulationWebsocket: null,
  simulationLoading: false,
}

const {
  thunk: fetchCurrentTokenSimulation,
  applyExtraReducers: applyExtraReducersForTokenSimulation,
} = createAsyncThunkWithControll(
  'chain/fetchCurrentTokenSimulation',
  async (body: TSimulationBody) => {
    const {
      data: { data },
    } = await getSimulation(body)
    return data
  },
)

const { thunk: fetchNewCurrentToken, applyExtraReducers: applyExtraReducersForNewCurrentToken } =
  createAsyncThunkWithControll(
    'chain/fetchNewCurrentToken',
    async (tokenAddress: string, { getState }) => {
      const {
        currentChain: { id: currentChainId },
        currentToken,
      } = (getState() as TRootState).chain

      if (currentToken?.token.address === tokenAddress) return

      const {
        data: {
          data: [tokenData],
        },
      } = await getFilterTokens(tokenAddress, currentChainId)

      const {
        data: { exchanges, socialLinks },
      } = await getTokenExchangesAndSocial(tokenAddress, currentChainId)
      const tokenDexes = getDexForToken(exchanges)

      localStorage.setItem(LocalStorageItem.TOKEN_ADDRESS, tokenAddress)

      return { ...tokenData }
    },
  )

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    setCurrentChain: (state, { payload }: PayloadAction<TChainConfig>) => {
      state.currentChain = payload
    },
    setCurrentToken: (state, { payload }: PayloadAction<ITokenInfo | null>) => {
      state.currentToken = payload
    },
    setCurrentChainBasicInfo: (state, { payload }: PayloadAction<TSniperBasicResponse>) => {
      state.currentChainBlock = payload.cb.n
      state.currentGas = payload.cb.f
      const usdPrice = +payload.p
      state.currentChain.nativeTokenPriceInUsd =
        usdPrice >= 1
          ? +usdPrice
              .toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })
              .replace(/,/g, '')
          : usdPrice
    },
    setCurrentTokenSimulationWebsocket: (
      state,
      { payload }: PayloadAction<TSniperSimulationResponse>,
    ) => {
      state.currentTokenSimulationWebsocket = {
        ...state.currentTokenSimulationWebsocket,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    applyExtraReducersForTokenSimulation(builder, {
      onFulfilled: (state, { payload }) => {
        if (payload) {
          if (state.currentTokenSimulationWebsocket) {
            state.currentTokenSimulationWebsocket = {
              ...state.currentTokenSimulationWebsocket,
              additional_data: payload.additional_data,
            }
          }
        }
      },
      onRejected: (state) => {
        if (state.currentTokenSimulationWebsocket?.additional_data)
          state.currentTokenSimulationWebsocket.additional_data = undefined
      },
    })

    applyExtraReducersForNewCurrentToken(builder, {
      onFulfilled: (state, { payload }) => {
        if (payload) state.currentToken = payload
      },
    })
  },
})

const {
  setCurrentChain,
  setCurrentToken,
  setCurrentChainBasicInfo,
  setCurrentTokenSimulationWebsocket,
} = chainSlice.actions

export {
  chainSlice,

  // Reducers
  setCurrentChain,
  setCurrentToken,
  setCurrentChainBasicInfo,
  setCurrentTokenSimulationWebsocket,

  // Thunks
  fetchNewCurrentToken,
  fetchCurrentTokenSimulation,
}
