import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardActionArea } from '@mui/material'
import cls from 'classnames'

import { useAppDispatch } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProperty = {
  className?: string
  children: ReactNode
  address: string
  redirect?: string
}

const TokenRedirect: FC<TProperty> = ({ className, children, address, redirect }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleChangeCurrentToken = () => {
    dispatch(fetchNewCurrentToken(address))
    dispatch(fetchUserWalletsWithTokensForce(address))
    if (redirect) navigate(redirect)
  }

  return (
    <CardActionArea
      disableRipple
      component="div"
      className={cls(styles.cardArea, className)}
      onClick={handleChangeCurrentToken}
    >
      {children}
    </CardActionArea>
  )
}

export { TokenRedirect }
