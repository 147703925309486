import { FC, useMemo } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { useUpdatingStore } from '@/hooks/updateStore.hook'
import { createSellPayload, handleSell } from '@/libs/helper'
import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

const HoldingsTable: FC = () => {
  const { updateStoreAfterBuySell } = useUpdatingStore()
  const profitSell = useAppSelector((state) => state.profitSell.profitSell)
  const isLoading = useAppSelector((state) => state.profitSell.isLoading)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)

  const rows = useMemo(
    () =>
      profitSell
        ? profitSell.filter((el) => {
            const panel = el.panel_data.seperate.data.find(
              (wallet) => wallet.publicWallet.public_key === mainWallet?.public_key,
            )
            return panel && +panel.currentTokens
          })
        : null,
    [profitSell],
  )

  const handleMarketSellAll = async (tokenAddress: string) => {
    if (!mainWallet) return

    const payload = await createSellPayload({
      token: tokenAddress,
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.public_key,
        },
      ],
      sellPercentage: 100,
    })
    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }
  }

  return (
    <Paper className={styles.root}>
      <TableContainer className={cn(styles.container)} id="holdings-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={styles.tableHeadCell}>Token</TableCell>
              <TableCell className={styles.tableHeadCell}>Invested</TableCell>
              <TableCell className={styles.tableHeadCell}>Remaining</TableCell>
              <TableCell className={styles.tableHeadCell}>Sold</TableCell>
              <TableCell className={styles.tableHeadCell}>Change in P&L</TableCell>
              <TableCell className={styles.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows &&
              rows.map((row) => (
                <Row
                  row={row}
                  key={row.panel.token_address}
                  handleMarketSellAll={handleMarketSellAll}
                />
              ))}
            {!rows && isLoading && <RowLoadingPlaceholder styleValiant="lite" />}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { HoldingsTable }
