const drawGradientText = (
  text: string,
  x: number,
  y: number,
  context: CanvasRenderingContext2D,
) => {
  const gradient = context.createLinearGradient(0, y - 34, 0, y)
  gradient.addColorStop(0, '#3DAF41')
  gradient.addColorStop(0.3, '#3DAF41')
  gradient.addColorStop(1, '#85E889')

  context.fillStyle = gradient
  context.fillText(text, x, y)
}

export { drawGradientText }
