import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { isArray } from 'chart.js/helpers'
import cn from 'classnames'
import moment from 'moment'

import { getTokenTransactionsFiltered } from '@/api/tokens'
import { TTokenTransactionFiltered } from '@/api/tokens/types'
import { CustomToast } from '@/components/custom-toast'
import { NoTokenSelected } from '@/components/search-with-token-info/libs/components/no-token-selected'
import { useSniperWebsocket } from '@/hooks/useSniperWebsocket.ts'
import { ButtonGroupRadio, ButtonGroupRadioButton, ButtonIcon, Typography } from '@/libs/common'
import { IconName, Sorting } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString.ts'
import { formatNumberWithCommas } from '@/libs/helper/format-numbers-with-commas'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { roundNumber } from '@/libs/helper/roundNumber'
import { ValueOf } from '@/libs/types'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TSniperBasicResponse } from '@/libs/types/sniper-socket-responses.type.ts'
import { SniperSockerService } from '@/socket'
import { definedService } from '@/socket/libs/defined'
import { useAppSelector } from '@/store'
import { setCurrentChainBasicInfo } from '@/store/slices/chain.slice.ts'

import { Row } from './libs/row'
import styles from './styles.module.scss'

const dateFormats: TSelectOption<string>[] = [
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'age',
    label: 'Age',
  },
]

type TColumn = {
  id: string
  label: string | ReactNode
  minWidth?: number
  align?: 'right' | 'left' | 'center'
  format?: (value: string) => string | ReactNode
}

type TProperty = {
  size?: 'basic' | 'small'
  styleValiant?: 'basic' | 'lite'
  ownTransactions?: boolean
  // height?: number
}
const basicSniperSocket = new SniperSockerService()

const TransactionsTable: FC<TProperty> = ({
  size,
  ownTransactions,
  styleValiant = 'basic',
  // height,
}) => {
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const [dateFormat, setDateFormat] = useState(dateFormats[0].value)
  const [rows, setRows] = useState<TTokenTransactionFiltered[]>([])
  const [cursor, setCursor] = useState<string>('')
  const [isTransactionLoading, setIsTransactionLoading] = useState(false)
  const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)

  const fetchData = async (pagination?: string) => {
    if (!currentToken || (ownTransactions && !mainWallet)) {
      setRows([])
      return
    }

    setIsTransactionLoading(true)

    try {
      const transactions = await getTokenTransactionsFiltered(
        currentToken.pair.address,
        currentChain.id,
        sorting,
        pagination ? pagination : '',
        ownTransactions && mainWallet ? mainWallet.address.toLowerCase() : '',
      )
      if (transactions.items?.length) {
        const filteredData = transactions.items.map((item) => ({
          ...item,
          ...item.data,
        }))
        setCursor(transactions.cursor)
        setRows((prev) => {
          return [...filteredData, ...(prev || [])]
        })
      }
    } catch (error) {
      CustomToast('error', 'Error fetching transactions')
    } finally {
      setIsTransactionLoading(false)
    }
  }

  useEffect(() => {
    setCursor('')
    setRows([])
    fetchData()
  }, [currentToken, mainWallet?.address, sorting, ownTransactions])

  useSniperWebsocket({
    socket: basicSniperSocket,
    verifyExistanceBeforeConnect: [currentToken],
    connectionProps: {
      endpoint: 'token/stream/transactions',
      query: createQueryString({
        b: currentChain.indexerChainId,
      }),
      onOpen: () => {
        basicSniperSocket.emit(
          JSON.stringify({
            s: currentToken?.pair?.address,
            q: currentToken?.token?.address,
          }),
        )
      },
    },
    onMessage: (jsonData) => {
      const { data } = JSON.parse(jsonData) as TSniperBasicResponse
      if (data.length && isArray(data)) {
        const currentRows: TTokenTransactionFiltered[] = data?.map(
          (item: TTokenTransactionFiltered) => ({
            block_number: item.bn,
            event_type: item.et,
            maker: item.m,
            timestamp: item.t,
            token_swap_value_usd: item.tswu,
            price_base_token_total: item.d.pb,
            price_usd_total: item.d.pu,
            amount_non_liquidity_token: item.d.an,
            transaction_hash: item.th,
          }),
        )
        setRows((prev) => {
          return [...currentRows, ...(prev || [])]
        })
      }
    },
  })

  const handleSort = () => {
    setSorting((sorting) => {
      return sorting === Sorting.ASC ? Sorting.DESC : Sorting.ASC
    })
  }

  const buttonLink = (transactionHash: string) => (
    <div className={styles.scannerIcon}>
      <ButtonIcon
        icon={IconName.ETHERSCAN}
        onClick={() => window.open(`https://etherscan.io/tx/${transactionHash}`, '_blank')?.focus()}
      />
    </div>
  )

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'timestamp',
        label: (
          <div className={styles.timeCell}>
            <ButtonGroupRadio
              className={styles.group}
              value={dateFormat}
              onChange={(_: React.BaseSyntheticEvent, newValue: string) => setDateFormat(newValue)}
              exclusive
            >
              {dateFormats.map(({ value, label }) => (
                <ButtonGroupRadioButton
                  key={value}
                  value={value}
                  className={cn(styles.button, { [styles.active]: dateFormat === value })}
                >
                  {label}
                </ButtonGroupRadioButton>
              ))}
            </ButtonGroupRadio>

            <ButtonIcon
              icon={sorting === Sorting.ASC ? IconName.SORT_ASC : IconName.SORT_DESC}
              onClick={handleSort}
            />
          </div>
        ),
        format: (value) =>
          dateFormat === dateFormats[0].value
            ? moment
                .utc(+value * 1000)
                .zone(new Date().getTimezoneOffset())
                .format('MMM D, HH:mm:ss')
            : moment
                .utc(+value * 1000)
                .startOf('second')
                .fromNow(),
      },
      {
        id: 'event_type',
        label: 'Type',
      },
      {
        id: 'token_swap_value_usd',
        label: 'Price USD',
        align: 'right',
        format: (value) => (value === 'NaN' ? '-' : <>${formatTokenPrice(value).formatted}</>),
      },
      {
        id: 'price_usd_total',
        label: 'Total USD',
        align: 'right',
        format: (value) => {
          return !value ? '-' : `$${(+value).toLocaleString('en-US', { maximumFractionDigits: 2 })}`
        },
      },
      {
        id: 'amount_non_liquidity_token',
        label: currentToken?.token.symbol || '',
        align: 'right',
        format: (value) => (value === undefined ? '-' : formatTokenPrice(value).raw),
      },
      {
        id: 'price_base_token_total',
        label: `Total ${currentChain.description}`,
        align: 'right',
        format: (value) =>
          value === undefined
            ? '-'
            : `${(+value).toLocaleString('en-US', { maximumFractionDigits: 5 })}`,
      },
      {
        id: 'maker',
        label: 'Makers',
        align: 'center',
        format: (value) =>
          (
            <Link
              to={`https://etherscan.io/address/${value}`}
              target="_blank"
              className={styles.addressLink}
            >
              {hideWalletAddress(value) as string}
            </Link>
          ) || '',
      },
      {
        id: 'transaction_hash',
        label: 'TX',
        align: 'left',
        format: (value) => buttonLink(value),
      },
    ],
    [currentToken, dateFormat, sorting],
  )

  if (!currentToken) {
    return <NoTokenSelected />
  }

  return (
    <Paper className={cn(styles.root)}>
      <InfiniteScroll
        dataLength={rows?.length || 50}
        next={() => fetchData(cursor)}
        hasMore={cursor !== null} // to avoid looping
        scrollableTarget="transactions-table"
        loader={
          isTransactionLoading && (
            <Typography
              variant="body1"
              align="center"
              className={cn(
                styles.background,
                styles.loader,
                { [styles.noData]: !rows?.length },
                styleValiant === 'basic' ? styles.basic : styles.lite,
              )}
            >
              Loading...
            </Typography>
          )
        }
      >
        <TableContainer
          className={cn(
            styles.container,
            { [styles.containerSmall]: size === 'small' },
            { [styles.noData]: !rows?.length && !isTransactionLoading },
          )}
          // style={{ maxHeight: `${height}px` }}
          id="transactions-table"
        >
          <Table aria-label="sticky table" classes={styles}>
            <TableHead className={styles.head}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={cn(
                      styles.tableHeadCell,
                      styleValiant === 'basic' ? styles.basic : styles.lite,
                      {
                        [styles.time]: column.id === 'timestamp',
                      },
                    )}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={styles.tableBody}>
              {rows?.map((row, indx) => {
                if (
                  (row.event_type === 'Mint' || row.event_type === 'Burn') &&
                  row.token0 !== '0' &&
                  row.token1 !== '0'
                ) {
                  const secondaryTokenSymbol =
                    currentToken.token.address !== currentToken.pair.address
                      ? currentToken.pair.symbol
                      : currentToken.token

                  const secondaryToken =
                    currentToken.token.address !== currentToken.pair.address ? 'token0' : 'token1'

                  const plusOrMinus = row.eventDisplayType === 'Mint' ? '+' : '-'

                  const primaryTokenPrice =
                    row[currentToken.quoteToken] !== '0'
                      ? plusOrMinus +
                        formatNumberWithCommas(roundNumber(+row[currentToken.quoteToken], 1e5)) +
                        ` ${currentToken.token.symbol}`
                      : ''

                  const secondaryTokenPrice =
                    row[secondaryToken] !== '0'
                      ? plusOrMinus +
                        formatNumberWithCommas(roundNumber(+row[secondaryToken], 1e5)) +
                        ` ${secondaryTokenSymbol}`
                      : ''

                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.transaction_hash + indx}
                      className={cn(
                        styles.bodyRow,
                        styleValiant === 'basic' ? styles.basic : styles.lite,
                      )}
                    >
                      <TableCell className={cn(styles.cell, styles.timestamp)}>
                        {dateFormat === dateFormats[0].value
                          ? moment.utc(+row.timestamp * 1000).format('MMM D, HH:mm:ss')
                          : moment
                              .utc(+row.timestamp * 1000)
                              .startOf('second')
                              .fromNow()}
                      </TableCell>
                      <TableCell
                        colSpan={columns.length - 3}
                        className={cn(styles.cell, styles.centerText, {
                          [styles.mint]: row.event_type === 'Mint',
                          [styles.burn]: row.event_type === 'Burn',
                        })}
                      >
                        {primaryTokenPrice && !secondaryTokenPrice && primaryTokenPrice}
                        {secondaryTokenPrice && !primaryTokenPrice && secondaryTokenPrice}
                        {primaryTokenPrice &&
                          secondaryTokenPrice &&
                          `${primaryTokenPrice} and ${secondaryTokenPrice}`}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={cn(styles.cell, {
                          [styles.mint]: row.event_type === 'Mint',
                          [styles.burn]: row.event_type === 'Burn',
                        })}
                      >
                        <Link
                          to={`https://etherscan.io/address/${row.maker}`}
                          target="_blank"
                          className={styles.addressLink}
                        >
                          {hideWalletAddress(row.maker)}
                        </Link>
                      </TableCell>

                      <TableCell align="left" className={cn(styles.cell)}>
                        {buttonLink(row.transaction_hash)}
                      </TableCell>
                    </TableRow>
                  )
                }
                if (row.event_type === 'Burn' || row.event_type === 'Mint') {
                  return null
                }
                return (
                  <Row
                    columns={columns}
                    row={row}
                    key={row.transaction_hash + indx}
                    styleValiant={styleValiant}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Paper>
  )
}

export { TransactionsTable }
export type { TColumn }
