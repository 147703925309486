import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import { TExportWallets } from '@/api/wallet/types'
import { PrivateKeysTable } from '@/components/private-keys-table'
import { Button, Typography } from '@/libs/common'
import { downloadCsv } from '@/libs/helper'

const ExportWallets = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState(false)

  const wallets = useMemo(
    () => JSON.parse(searchParams.get('wallets') || '[]') as TExportWallets[],
    [],
  )

  useEffect(() => {
    searchParams.delete('wallets')
    setSearchParams(searchParams, { replace: true })
  }, [])

  const submit = () => {
    setLoading(true)
    downloadCsv({
      keys: ['name', 'private_key', 'public_key'],
      data: wallets,
      fileName: 'Wallets',
    })
    setLoading(false)
  }

  return (
    <Grid display="flex" flexDirection="column" gap={3}>
      <Typography variant="h1" align="center">
        Export wallets
      </Typography>

      <PrivateKeysTable wallets={wallets} withName />

      <Button isLoading={loading} onClick={submit}>
        Download CSV
      </Button>
    </Grid>
  )
}

export { ExportWallets }
