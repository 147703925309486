import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'

const BASE_URL = 'api/v1/user/history'

const getUserHistory = (networkId: number) => {
  const userId = getCurrentUserId()
  return api.get(`${BASE_URL}/${userId}/get?l=en&b=${networkId}`)
}

export { getUserHistory }
