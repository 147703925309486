import { useEffect, useState } from 'react'

import { getWindowDimensions } from '@/libs/helper'

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  function handleResize() {
    setWindowDimensions(getWindowDimensions())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { ...windowDimensions, isBottomTabsVisible: windowDimensions.width <= 1000 }
}

export { useWindowDimensions }
