import { FC, MouseEvent, useEffect } from 'react'

import StarRateIcon from '@mui/icons-material/StarRate'
import { Button } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { CustomToast } from '@/components/custom-toast'
import { TokenPlaceholder } from '@/libs/common/token-placeholder'
import { Typography } from '@/libs/common/typography'
import { coinAge } from '@/libs/helper/coinAge'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
// import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { addFavoriteToken, removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'

import styles from './styles.module.scss'

type TOption = {
  id: string
  platformVersion: string
  pair: string
  price: string
  priceChange: string
  liquidity: string
  volume: string
  age: string
  pairAddress: string
  tokenAddress: string
  tokenName: string
}

type TInputSearchDashboardResultCardProps = {
  option: ITokenInfo
  setSelectedAddress: (value: string) => void
}

const InputSearchDashboardResultCard: FC<TInputSearchDashboardResultCardProps> = ({
  option,
  setSelectedAddress,
}) => {
  const primaryToken = option.token
  const networkId = useAppSelector((state) => state.chain.currentChain.id)
  const dispatch = useAppDispatch()
  const userFavoritesToken = useAppSelector((state) => state.favoritesTokens.userFavorites)

  const isUserFavoriteToken = userFavoritesToken?.some(
    ({ token }) => token === primaryToken.address,
  )

  const secondaryToken = option.token.address !== option?.pair.address ? option.pair : option.token

  const tokenName =
    option.token.name.length > 20 ? option.token.name.slice(0, 20) + '...' : option.token.name

  const date = coinAge(option.pair.created_at)

  const addUserFavoriteToken = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (!isUserFavoriteToken) {
      dispatch(
        addFavoriteToken({
          tokenAddress: primaryToken.address,
          networkId,
        }),
      )
    } else {
      dispatch(
        removeFavoriteToken({
          tokenAddress: primaryToken.address,
          networkId,
        }),
      )
    }
  }

  return (
    <Button
      sx={{ width: '100%', padding: 0 }}
      onClick={() => {
        setSelectedAddress(option.token.address)
      }}
    >
      <Grid container className={styles.card} direction="column" gap={1}>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid container gap={1.7} className={styles.fitContent}>
            <AvatarGroup max={2}>
              <Avatar className={styles.avatar}>
                {primaryToken.images.small ? (
                  <img src={primaryToken.images.small} alt={`${primaryToken.symbol} token`} />
                ) : (
                  <TokenPlaceholder token={primaryToken} />
                )}
              </Avatar>

              <Avatar className={styles.avatar}>
                {secondaryToken.images.small ? (
                  <img src={secondaryToken.images.small} alt={`${secondaryToken.symbol} token`} />
                ) : (
                  <TokenPlaceholder token={primaryToken} />
                )}
              </Avatar>
            </AvatarGroup>

            <Typography variant="body2" textColor="color-grey-3" className={cn(styles.symbolBlock)}>
              <span className={cn(styles.symbolWrapper)}>
                {primaryToken.symbol}{' '}
                <span className={cn(styles.secondaryText)}>/{secondaryToken.symbol}</span>
              </span>
              <span className={cn(styles.primaryText, styles.fontSize)}>{tokenName}</span>
            </Typography>
          </Grid>

          <Grid container className={styles.fitContent} gap={2} justifyContent="end">
            <Typography variant="body2" className={styles.secondaryText}>
              Pair:{' '}
              <span className={styles.secondaryTextWeightBold}>
                {hideWalletAddress(option.pair.address)}
              </span>
            </Typography>

            <Typography variant="body2" className={styles.secondaryText}>
              {primaryToken.symbol}:{' '}
              <span className={styles.secondaryTextWeightBold}>
                {hideWalletAddress(primaryToken.address)}
              </span>
            </Typography>

            <Button onClick={addUserFavoriteToken} className={styles.favoriteBtn}>
              <StarRateIcon
                className={styles.favoriteIcon}
                fontSize="small"
                sx={{
                  fill: isUserFavoriteToken ? '#FFC800' : 'transparent',
                  stroke: isUserFavoriteToken ? 'transparent' : '#979797',
                }}
              />
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          {
            <div className={styles.primaryText}>
              ${formatTokenPrice(option.token.price).formatted}
            </div>
          }

          <span
            className={cn(
              Number(option.token.price_change_24h) > 0 ? styles.isProfit : styles.isLoss,
              styles.priceWrapper,
            )}
          >
            {Math.abs(Number(option.token.price_change_24h) * 100).toFixed(2)}
            {'% '}
            <span className={styles.secondaryText}>{'(24h)'}</span>
          </span>

          <Typography variant="body2" className={styles.secondaryText}>
            Liquidity:{' '}
            <span className={styles.secondaryTextWeightBold}>
              {formatTokenPrice(option.token.liquidity).raw}
            </span>
          </Typography>

          <Typography variant="body2" className={styles.secondaryText}>
            Volume:{' '}
            <span className={styles.secondaryTextWeightBold}>
              {formatTokenPrice(option.token.volume_24h).raw}
            </span>{' '}
            (24h)
          </Typography>

          <Typography variant="body2" className={styles.secondaryText}>
            Age:{' '}
            <span className={cn(styles.secondaryTextWeightBold, styles.lowerCase)}>{date}</span>
          </Typography>
        </Grid>
      </Grid>
    </Button>
  )
}

export { InputSearchDashboardResultCard, type TInputSearchDashboardResultCardProps, type TOption }
