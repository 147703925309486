import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid } from '@mui/material'

import { AppRoute } from '@/libs/enums'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { WalletItem } from './libs/components/wallet-item'
import styles from './styles.module.scss'

const SelectWalletToDeposit: FC = () => {
  const wallets = useAppSelector((state) => state.user.userWallets)
  const location = useLocation()
  const type = location.pathname.includes(AppRoute.TRANSFER_WALLETS) ? 'transfer' : 'deposit'

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({
      title: type === 'transfer' ? 'Select wallet to transfer from' : 'Select wallet to deposit to',
    })
  }, [location])

  return (
    <div>
      <Grid rowGap={2} display="grid" className={styles.walletsList}>
        {wallets?.map((wallet) => <WalletItem wallet={wallet} key={wallet.address} type={type} />)}
      </Grid>
    </div>
  )
}

export { SelectWalletToDeposit }
