import { FC } from 'react'

import { OrderHistoryTable } from '@/components/pending-orders/libs/components/order-history-table'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

// const rows = [
//   {
//     id: 1,
//     type: 'buy',
//     token: 'PEPE',
//     amount: '0.006 ETH',
//     limit: '$172.4',
//     date: ['2024-02-07', '23:34'],
//     status: 'Complete',
//     wallets: [
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//     ],
//   },
//   {
//     id: 2,
//     type: 'sell',
//     token: 'PEPE',
//     amount: '0.006 ETH',
//     limit: '$172.4',
//     date: ['2024-02-07', '23:34'],
//     status: 'Complete',
//     wallets: [
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//     ],
//   },
//   {
//     id: 3,
//     type: 'buy',
//     token: 'PEPE',
//     amount: '0.006 ETH',
//     limit: '$172.4',
//     date: ['2024-02-07', '23:34'],
//     status: 'Complete',
//     wallets: [
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//     ],
//   },
//   {
//     id: 4,
//     type: 'sell',
//     token: 'PEPE',
//     amount: '0.006 ETH',
//     limit: '$172.4',
//     date: ['2024-02-07', '23:34'],
//     status: 'Complete',
//     wallets: [
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//       {
//         name: 'Wallet 1',
//         balance: '0.006 ETH',
//       },
//     ],
//   },
// ]

const OrderHistoryTab: FC = () => {
  const orders = useAppSelector((state) => state.orders.ordersHistory)

  return (
    <div className={styles.tableContainer}>
      <OrderHistoryTable rows={orders} />
    </div>
  )
}

export { OrderHistoryTab }
