import { FC, useEffect, useMemo, useState } from 'react'

import { BuyTab } from '@/components/buy-tab'
import { PairStats } from '@/components/pair-stats'
import { ProfitSellTab } from '@/components/profit-sell-tab'
import { SellTab } from '@/components/sell-tab'
import { TTabsProps, Tabs } from '@/libs/common/index'
import { TemplateGroup } from '@/libs/enums'
import { TTab } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearCurrentSelectedTemplate } from '@/store/slices/user.slice'

// import { SnipeTab } from './libs/components/snipe-tab'
import styles from './styles.module.scss'

const BuySellSnipeProfitSell: FC<Omit<TTabsProps, 'tabs'>> = ({ className, ...props }) => {
  const dispatch = useAppDispatch()
  const { userCurrentSelectedTemplate } = useAppSelector((state) => state.user)

  const [value, setValue] = useState<number | undefined>()

  useEffect(() => {
    if (userCurrentSelectedTemplate.group) {
      const tabIndex = tabs.findIndex(({ label }) => label === userCurrentSelectedTemplate.group)
      setValue(tabIndex)
    }
  }, [userCurrentSelectedTemplate])

  const tabs: TTab[] = useMemo(
    () => [
      {
        label: TemplateGroup.VOLUME,
        content: (
          <div className={styles.tabWrapper}>
            <PairStats variant="pro" />
          </div>
        ),
      },
      {
        label: TemplateGroup.BUY,
        content: <BuyTab />,
      },
      {
        label: 'Sell',
        content: <SellTab />,
      },
      // {
      //   label: TemplateGroup.SNIPE,
      //   content: <SnipeTab />,
      // },
      {
        label: 'Profit/Loss',
        content: <ProfitSellTab />,
      },
    ],
    [],
  )

  return (
    <div className={styles.container}>
      <Tabs
        className={className}
        tabsClasses={styles.tabs}
        value={value}
        onChange={(_, value) => {
          dispatch(clearCurrentSelectedTemplate())
          setValue(value)
        }}
        {...props}
        tabs={tabs}
        defaultValue={0}
      />
    </div>
  )
}

export { BuySellSnipeProfitSell }
