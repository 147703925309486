import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import * as yup from 'yup'

import { TProfitWallet } from '@/api/profit-loss/types'
// import { mockPositionsData } from '@/components/buy-sell-snipe-profit-sell/libs/components/profit-sell-tab/mockdata/mock-positions-data'
import { PositionCard } from '@/components/position-card/position-card'
import { useUpdatingStore } from '@/hooks/updateStore.hook'
import {
  Button, // ButtonGroupRadio,
  // ButtonGroupRadioButton,
  HiddenInputs,
  Icon,
  Input,
  InputWithRadioGroup,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName } from '@/libs/enums'
import { createSellPayload, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { validatePercentInput } from '@/libs/helper/validatePercentInput'
import { TSellForm } from '@/libs/types'
import { TSelectOption } from '@/libs/types/select-option.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserWalletsWithTokens, removeUserWalletsWithToken } from '@/store/slices/user.slice'

import { PositionCardMessage } from './libs/position-card-message'
import styles from './styles.module.scss'

// const limitOptions: TSelectOption<number>[] = [
//   {
//     value: -10,
//     label: '-10%',
//   },
//   {
//     value: -20,
//     label: '-20%',
//   },
//   {
//     value: -30,
//     label: '-30%',
//   },
//   {
//     value: -40,
//     label: '-40%',
//   },
// ]

type TProps = {
  usedInModal?: boolean
}

enum ELastUsedAmountInput {
  SELL = 'Sell',
  RECEIVE = 'Receive',
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

const defaultValues: TSellForm = {
  privateTransaction: true,
  slippage: 50,
  sellPriority: '',
  // dip: '',
  // marketcap: '',
  // price: '',
  // triggerPricePercent: '',
  // expiration: '',
  sell: '',
  receive: '',
}

const SellTab: FC<TProps> = ({ usedInModal = false }) => {
  const { updateStoreAfterBuySell } = useUpdatingStore()
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)
  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userWalletsWithSelectedToken = useAppSelector(
    (state) => state.user.userWalletsWithSelectedToken,
  )
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentChainBlock = useAppSelector((state) => state.chain.currentChainBlock)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)

  const dispatch = useAppDispatch()

  const availableWallets = useMemo(
    () => userWalletsWithSelectedToken,
    [userWalletsWithSelectedToken, currentToken],
  )

  // const [footerTab, setFooterTab] = useState<string>('market')
  const [searchParams] = useSearchParams()
  const [isDisabled, setIsDisable] = useState(true)
  const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
    checkedAtLeastOne: false,
    checkedAll: false,
  })
  const [selectedWallets, setSelectedWallets] = useState<TProfitWallet[]>([])
  const [tokensAvailable, setTokensAvailable] = useState(0)

  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({ loadingAtLeastOne: false })
  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)
  const [sellPercentage, setSellPercentage] = useState<number>(0)

  const sellCardId = searchParams.get('sellCardId')

  const schema = yup.object({
    privateTransaction: yup.boolean().default(true),
    slippage: stringOfNumbersValidation.required().test((value) => +value <= 100),
    sellPriority: stringOfNumbersValidation,
    sell:
      import.meta.env.VITE_IS_TEST_INSTANCE === 'true'
        ? stringOfNumbersValidation.required()
        : stringOfNumbersValidation.required().test((value) => +value <= tokensAvailable),
    receive: stringOfNumbersValidation.required(),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const fieldsRequiredForSellActions = watch(['slippage'])

  const currentDex = currentToken?.tokenDexes?.[0]

  useEffect(() => {
    if (isAppIdle) return

    if (currentToken?.tokenDexes?.[0]) {
      dispatch(fetchUserWalletsWithTokens(currentToken.token.address))
    } else if (!currentToken?.tokenDexes?.[0]) {
      dispatch(removeUserWalletsWithToken())
    }
  }, [currentToken, currentChainBlock, isAppIdle])

  useEffect(() => {
    let newDisabled = tokensAvailable <= 0
    if (!newDisabled) {
      for (const field of fieldsRequiredForSellActions) {
        if ((Array.isArray(field) && !field.length) || !field) {
          newDisabled = true
          break
        }
      }
    }
    setIsDisable(newDisabled)
  }, [fieldsRequiredForSellActions, tokensAvailable])

  useEffect(() => {
    let newTokensAvailable = 0
    const updatedWallets = selectedWallets.reduce((wallets, currentWallet) => {
      const availableWallet = availableWallets?.find(
        (available) => available.publicWallet.public_key === currentWallet.publicWallet.public_key,
      )
      if (availableWallet) {
        wallets.push(availableWallet)
        newTokensAvailable += +(availableWallet.currentTokens || 0)
      }
      return wallets
    }, [] as TProfitWallet[])

    setSelectedWallets(updatedWallets)
    setTokensAvailable(newTokensAvailable)
  }, [availableWallets])

  // const handleFooterTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setFooterTab(newValue)
  // }

  const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedState = {
      ...positionsChecked,
      [event.target.name]: checked,
    }

    let newTokensAvailable = 0

    const newSelectedWallets = Object.keys(updatedState)
      .map((key) => {
        if (['checkedAtLeastOne', 'checkedAll'].includes(key) || !updatedState[key]) return null
        const selectedWallet =
          availableWallets?.find((wallet) => wallet.publicWallet.public_key === key) || null
        if (!selectedWallet) return null
        newTokensAvailable += +(selectedWallet.currentTokens || 0)
        return selectedWallet
      })
      .filter(Boolean) as TProfitWallet[]

    setSelectedWallets(newSelectedWallets)
    setTokensAvailable(newTokensAvailable)

    const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
    const positionsValues = Object.values(onlyPositions)
    const chackedPositions = positionsValues.filter((checked) => checked)
    const atLeastOneChecked = !!chackedPositions.length
    const checkedAllPositions = positionsValues.length === chackedPositions.length

    setPositionsChecked({
      ...updatedState,
      checkedAtLeastOne: atLeastOneChecked,
      checkedAll: checkedAllPositions,
    })
  }

  const setAllChecked = () => {
    if (!availableWallets) return

    let newTokensAvailable = 0

    const allPositionsChecked = availableWallets.reduce((sum, { publicWallet }) => {
      return { ...sum, [publicWallet.public_key]: !positionsChecked.checkedAll }
    }, {})

    if (!positionsChecked.checkedAll) {
      newTokensAvailable = availableWallets.reduce(
        (sum, next) => sum + +(next.currentTokens || 0),
        0,
      )
    }

    setSelectedWallets(!positionsChecked.checkedAll ? availableWallets : [])
    setTokensAvailable(newTokensAvailable)

    setPositionsChecked({
      ...positionsChecked,
      ...allPositionsChecked,
      checkedAtLeastOne: !positionsChecked.checkedAll,
      checkedAll: !positionsChecked.checkedAll,
    })
  }

  const handleLoading = (loading: boolean, selectedWallets: TProfitWallet[]) => {
    const updatedState: Record<string, boolean> = {
      loadingAtLeastOne: loading && !!selectedWallets.length,
    }
    selectedWallets.forEach((el) => (updatedState[el.publicWallet.public_key] = loading))
    setIsLoading(updatedState)
  }

  const handleMarketSellAll = async (wallet: TProfitWallet) => {
    if (!userData || !currentToken || !currentDex || !wallet) return

    handleLoading(true, [wallet])
    const wallets = [
      {
        name: wallet.publicWallet.name,
        public_key: wallet.publicWallet.public_key,
      },
    ]
    const payload = await createSellPayload({
      wallets,
      sellPercentage: 100,
    })

    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }

    handleLoading(false, [wallet])
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userData || !currentToken || !currentDex || !userWallets) return

    handleLoading(true, selectedWallets)

    const wallets = selectedWallets.map((item) => {
      return {
        name: item.publicWallet.name,
        public_key: item.publicWallet.public_key,
      }
    })

    const payload = await createSellPayload({ wallets, sellPercentage, data })
    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }

    if (sellPercentage) setSellPercentage(0)
    handleLoading(false, selectedWallets)
  }

  const calculateReceive = (value: string) => {
    if (!simulation || !currentChain.nativeTokenPriceInUsd) return
    setValue('sell', value, {
      shouldValidate: true,
    })
    const coeff = +simulation.p.u / currentChain.nativeTokenPriceInUsd
    setValue(
      'receive',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      { shouldValidate: true },
    )
  }

  const calculateSell = (value: string) => {
    if (!simulation || !currentChain.nativeTokenPriceInUsd) return
    setValue('receive', value, {
      shouldValidate: true,
    })
    const coeff = currentChain.nativeTokenPriceInUsd / +simulation.p.u
    setValue(
      'sell',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  // TODO: Move this logic with 2 inputs to a separate component
  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SELL) {
      const sellValue = getValues().sell
      if (sellValue) {
        calculateReceive(sellValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().receive
      if (receiveValue) {
        calculateSell(receiveValue)
      }
    }
  }, [currentChain])

  return (
    <div>
      <Grid className={cn(styles.content, { [styles.usedInModal]: usedInModal })}>
        {!availableWallets?.length && (
          <Typography variant="body1" textColor="grey" align="center">
            No wallets available
          </Typography>
        )}
        {!!availableWallets?.length && (
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" gap={1} className={styles.rowContainerItem}>
              <Typography variant="body2" component="span" textColor="light-grey">
                Select wallets
              </Typography>
            </Grid>
            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              className={styles.buttonGroup}
            >
              <Button
                styleVariant="borderless-gold"
                transparent
                className={styles.button}
                onClick={setAllChecked}
              >
                {positionsChecked.checkedAll ? 'Unselect all' : 'Select all'}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap={1.5}
          className={styles.cardsContainer}
        >
          {availableWallets?.map((wallet) => (
            <PositionCard
              className={styles.card}
              variant="sell"
              key={wallet.publicWallet.public_key}
              message={<PositionCardMessage />}
              isMarketSellLoading={isLoading[wallet.publicWallet.public_key]}
              onMarketSellAll={() => {
                handleMarketSellAll(wallet)
              }}
              positionData={{
                id: wallet.publicWallet.public_key,
                isActive: true,
                coin: currentToken?.token.symbol || '',
                coinCompairedWith: currentChain.description,
                totalBalance: +wallet.currentTokens || 0,
                totalWallets: userWallets?.length || 0,
                profitOrLoss: wallet.profitLoss,
                cost: `${wallet.boughtAmountEth}`,
                soldEth: wallet.soldEth,
                worth: wallet.worth,
                afterTax: wallet.afterSell || 0,
                priceImpact: wallet.priceImpact,
              }}
              isSold={Boolean(sellCardId && sellCardId === wallet.publicWallet.public_key)}
              checkboxProps={{
                name: wallet.publicWallet.public_key,
                checked: positionsChecked[wallet.publicWallet.public_key] ?? false,
                onChange: handlePositionChecking,
              }}
            />
          ))}
        </Grid>
      </Grid>
      <HiddenInputs expanded={positionsChecked.checkedAtLeastOne && !!availableWallets?.length}>
        <Grid container rowGap={3} marginTop={3}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Grid className={styles.formContainer}>
              <Controller
                name="privateTransaction"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label="Anti-Mev"
                    tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                    {...field}
                  />
                )}
              />

              <Controller
                name="slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label="Slippage"
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                    placeholder="X"
                    error={!!errors.slippage?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="sellPriority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    label="Sell Priority"
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                    endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                    error={!!errors.sellPriority?.message}
                    {...field}
                  />
                )}
              />

              {/* <Grid container rowGap={2} flexDirection="column">
                <ButtonGroupRadio
                  onChange={handleFooterTabChange}
                  value={footerTab}
                  exclusive
                  className={cn(styles.group, styles.bgGrey)}
                >
                  <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
                </ButtonGroupRadio>
                {footerTab === 'limit' && (
                  <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                    <div>
                      <Typography variant="body2" textColor="light-grey">
                        Trigger price
                      </Typography>
                      <div className={styles.inputWrapper}>
                        <Controller
                          name="dip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cn(styles.input, styles.first)}
                              placeholder="Enter dip %"
                              endAdornment={<EndAdornment label="%" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="marketcap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={styles.input}
                              placeholder="Enter marketcap"
                              endAdornment={<EndAdornment label="MC" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="price"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cn(styles.input, styles.last)}
                              placeholder="Enter price"
                              endAdornment={<EndAdornment label="$" />}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      <Controller
                        name="triggerPricePercent"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <ButtonGroupRadio
                            className={styles.radioGroup}
                            onChange={(_, value: string) => onChange(value)}
                            exclusive
                            {...field}
                          >
                            {limitOptions.map((option) => (
                              <ButtonGroupRadioButton
                                value={option.value}
                                className={styles.radioButton}
                                key={option.value}
                              >
                                {option.label}
                              </ButtonGroupRadioButton>
                            ))}
                          </ButtonGroupRadio>
                        )}
                      />
                    </div>

                    <Controller
                      name="expiration"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                        tooltipInfo='Enter the number of hours the limit order is valid. After this time expires, it will be removed.'
                          label="Expiration"
                          className={styles.input}
                          placeholder="Enter the position expiration time in hours"
                          adornmentText="Hours"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid> */}
            </Grid>

            <Grid className={styles.footer}>
              <div className={styles.arrow}>
                <Icon name={IconName.ARROW_DOWN_GREY} />
              </div>

              <div className={styles.container}>
                <div className={styles.spend}>
                  <Controller
                    name="sell"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <InputWithRadioGroup
                        label="Sell"
                        placeholder="Enter amount to sell"
                        isNumeric
                        className={styles.input}
                        onOptionSelect={(value) => {
                          const newValue = convertScientificNotationNumber(
                            tokensAvailable * (+value / 100),
                            MAX_TRX_DECIMALS,
                          )
                          setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                          calculateReceive(newValue)
                          setSellPercentage(+value)
                        }}
                        endAdornment={
                          <EndAdornment
                            label={currentToken?.token.symbol}
                            imageUrl={currentToken?.token.images.small}
                            isDisabled={isDisabled}
                          />
                        }
                        radioGroupName="amount"
                        disabled={isDisabled}
                        options={spendOptions}
                        onChange={(e) => {
                          if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                          const newValue = convertScientificNotationNumber(
                            e.target.value,
                            MAX_TRX_DECIMALS,
                            true,
                          )
                          setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                          calculateReceive(newValue)
                          if (sellPercentage) setSellPercentage(0)
                        }}
                        error={!!errors.sell?.message}
                        withCustomInput
                        validateCustomInput={validatePercentInput}
                        {...field}
                      />
                    )}
                  />

                  <div className={styles.available}>
                    <Typography variant="body2" className={styles.param}>
                      Available:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      {tokensAvailable} {currentToken?.token.symbol}
                    </Typography>
                  </div>
                </div>
                <div className={styles.union}>
                  <Controller
                    name="receive"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Input
                        label="Receive"
                        placeholder="Enter amount to receive"
                        isNumeric
                        className={styles.input}
                        endAdornment={
                          <EndAdornment
                            label="ETH"
                            icon={IconName.ETHEREUM}
                            isDisabled={isDisabled}
                          />
                        }
                        disabled={isDisabled}
                        onChange={(e) => {
                          if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                          const newValue = convertScientificNotationNumber(
                            e.target.value,
                            MAX_TRX_DECIMALS,
                            true,
                          )
                          setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
                          calculateSell(newValue)
                        }}
                        error={!!errors.receive?.message}
                        {...field}
                      />
                    )}
                  />
                  <div className={cn(styles.infoContainer, { [styles.disabled]: isDisabled })}>
                    <div className={styles.info}>
                      <Typography variant="body2" className={styles.param}>
                        DEX:
                      </Typography>
                      <Typography variant="body2" className={styles.value}>
                        {currentDex?.label}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                disabled={isDisabled}
                isLoading={isLoading.loadingAtLeastOne}
                checkOnAccountLock
              >
                Sell
              </Button>
            </Grid>
          </form>
        </Grid>
      </HiddenInputs>
    </div>
  )
}

export { SellTab }
