import { useEffect, useRef, useState } from 'react'
import * as React from 'react'

import { ChartingLibraryFeatureset, widget } from '@/charting_library'
import { ChartingLibraryWidgetOptions, ResolutionString } from '@/charting_library/charting_library'
import { NoTokenSelected } from '@/components/search-with-token-info/libs/components/no-token-selected/no-token-selected.tsx'
import { getFontSize } from '@/libs/helper/getFontSize.ts'
import { useAppSelector } from '@/store/index.js'

import { useDatafeed } from './data-feed.tsx'
import { formatMCap, formatPrice, getLanguageFromURL } from './helpers.tsx'
import styles from './styles.module.scss'

interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol']
  interval: ChartingLibraryWidgetOptions['interval']
  libraryPath: ChartingLibraryWidgetOptions['library_path']
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']
  clientId: ChartingLibraryWidgetOptions['client_id']
  userId: ChartingLibraryWidgetOptions['user_id']
  fullscreen: ChartingLibraryWidgetOptions['fullscreen']
  autosize: ChartingLibraryWidgetOptions['autosize']
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides']
  container: ChartingLibraryWidgetOptions['container']
}

type TProps = {
  componentWidth: number
}

const TradingView: React.FC<TProps> = ({ componentWidth }) => {
  const [currentInterval, setCurrentInterval] = useState('1D')
  const chain = useAppSelector((state) => state.chain.currentChain)
  const [isPriceSelected, setIsPriceSelected] = useState(true)
  const [isCurrencyPriceSelected, setIsCurrencyPriceSelected] = useState(false)
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const datafeed = useDatafeed(selectedToken, isPriceSelected, isCurrencyPriceSelected) as any
  const user = useAppSelector((state) => state.user)

  const secondaryToken =
    selectedToken?.token.address !== selectedToken?.pair.address
      ? selectedToken?.pair
      : selectedToken?.token

  const defaultProps: Omit<ChartContainerProps, 'container'> = {
    symbol: `${selectedToken?.token.symbol}/${secondaryToken?.symbol}`,
    interval: 'D' as ResolutionString,
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: user.userData?.user_id || '',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  }

  useEffect(() => {
    if (!selectedToken || !chartContainerRef || !window.TradingView) {
      return
    }

    const disabledFeatures = [
      'header_symbol_search',
      'header_compare',
      'header_saveload',
      'popup_hints',
    ]

    if (componentWidth <= 600) {
      disabledFeatures.push('legend_widget') // show legend information for chart
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed,
      interval: currentInterval as ResolutionString,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      theme: 'dark',
      overrides: {
        'paneProperties.background': '#020024',
        'scalesProperties.fontSize': getFontSize(componentWidth),
      },
      locale: getLanguageFromURL() || 'en',
      disabled_features: disabledFeatures as ChartingLibraryFeatureset[],
      enabled_features: [
        'adaptive_logo',
        'side_toolbar_in_fullscreen_mode',
        'seconds_resolution',
        'show_spread_operators',
        'context_menus',
      ],
      drawings_access: {
        type: 'black',
        tools: [{ name: 'Regression Trend' }],
      },
      studies_access: {
        type: 'black', // 'black' to show indicator
        tools: [{ name: 'HighLow' }],
      },
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      auto_save_delay: 2,
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price: number) => {
              const currentPrice = isCurrencyPriceSelected
                ? price / chain.nativeTokenPriceInUsd!
                : price

              return isPriceSelected
                ? formatPrice(currentPrice)
                : formatMCap(currentPrice, +selectedToken.token.totalSupply)
            },
          }
        },
      },
    }

    const tvWidget = new widget(widgetOptions)
    tvWidget.onChartReady(() => {
      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, (interval) => {
          React.startTransition(() => {
            setCurrentInterval(interval)
          })
        })

      tvWidget.headerReady().then(() => {
        // // Пример добавления метки "Buy"
        // tvWidget.chart().createShape(
        //   { time: 1723371060000 / 1000, price: 6.07 }, // время и цена
        //   {
        //     shape: 'flag',
        //     // lock: true,
        //     disableSelection: false,
        //     overrides: {
        //       text: `B`, // bug
        //       // backgroundColor: '#0f0',
        //       textPosition: 'center',
        //       markerColor: '#1976d2',
        //     },
        //   },
        // )

        const toChainCurrencyButton = tvWidget.createButton()
        //@USD button
        toChainCurrencyButton.setAttribute('title', `Switch to ${chain.description}`)
        toChainCurrencyButton.addEventListener('click', () => {
          setIsCurrencyPriceSelected((prevIsCurrencyPriceSelected) => !prevIsCurrencyPriceSelected)
        })

        toChainCurrencyButton.innerHTML = `<span style="cursor: pointer;">Switch to ${!isCurrencyPriceSelected ? chain.description : 'USD'}</span>`

        //@Mcap button
        const MCapButton = tvWidget.createButton()
        MCapButton.setAttribute('title', `Switch to ${isPriceSelected ? 'MCap' : 'Price'} chart`)
        MCapButton.addEventListener('click', () => {
          setIsPriceSelected((prevIsPriceSelected) => !prevIsPriceSelected)
        })

        const blue = '#2962ff'

        MCapButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isPriceSelected && blue};">Price</span> / <span style="color: ${!isPriceSelected && blue};">MCap</span></div>`
      })
    })

    return () => {
      tvWidget.remove()
    }
  }, [selectedToken, isPriceSelected, currentInterval, componentWidth, isCurrencyPriceSelected])

  if (!selectedToken && !chartContainerRef.current) {
    return (
      <div className={styles.container}>
        <NoTokenSelected />
      </div>
    )
  }

  return <div ref={chartContainerRef} className={styles.container} />
}

export { TradingView }
