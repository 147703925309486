import { FC } from 'react'

import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { PositionsPage, PositionsSummariesPage } from '@/components/profit-sell-tab/libs/pages'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  usedInModal?: boolean
}

const ProfitSellTab: FC<TProps> = ({ usedInModal }) => {
  const selectedPosition = useAppSelector((state) => state.profitSell.currentPosition)

  return (
    <section id="profit-sell">
      <Stack
        spacing={1.5}
        useFlexGap
        className={cn(styles.container, { [styles.usedInModal]: usedInModal })}
      >
        {selectedPosition ? (
          <PositionsPage position={selectedPosition} usedInModal={usedInModal} />
        ) : (
          <PositionsSummariesPage usedInModal={usedInModal} />
        )}
        {/* {selectedPosition ? (
          <PositionsPage
            positionSummary={selectedPosition}
            handlePositionsSelection={setSelectedPosition}
          />
        ) : (
          <PositionsSummariesPage
            positionsSummaries={positionsSummaries}
            handlePositionsSelection={setSelectedPosition}
          />
        )} */}
      </Stack>
    </section>
  )
}

export { ProfitSellTab }
