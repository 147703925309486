import { FC, useState } from 'react'

import { TableCell, TableRow } from '@mui/material'
import cls from 'classnames'

import { RemoveHoneypotBtn } from '@/components/remove-honeypot-btn'
import { SellPopup } from '@/components/sell-popup'
import styles from '@/components/wallet-holdings-table/styles.module.scss'
import { ButtonIcon, Icon, type TOnChangeVisible, TokenRedirect } from '@/libs/common'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'

type TProperty = {
  isHidden?: boolean
  holding: THoldingsToken
  onMarketSellAll: () => Promise<void>
  isHoneypot?: boolean
}

const Row: FC<TProperty> = ({ holding, onMarketSellAll, isHoneypot }) => {
  const [sellPopup, setSellPopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  return (
    <TableRow className={styles.row}>
      <TableCell className={cls(styles.cell, styles.nameColumn)}>
        {/* <img alt="avatar" src={holding.token.logo} /> */}
        <TokenRedirect
          address={holding.a}
          redirect={`${AppRoute.DASHBOARD}/${AppMode.PRO}?${TabName.DASHBOARD_NAV}=dashboard&token=${holding.a}`}
        >
          {holding.n}
        </TokenRedirect>
      </TableCell>
      <TableCell className={cls(styles.cell, styles.priceColumn)}>
        ${isHoneypot ? ' -' : formatNumber(holding.tvu).formatted}
        {isHoneypot && <RemoveHoneypotBtn token={holding.a} className={styles.removeHoneypotBtn} />}
      </TableCell>
      {/* <TableCell
        className={cls(styles.cell, { [styles.red]: token.desc != '-' })}
        sx={{ minWidth: '89px', maxWidth: '89px' }}
      >
        {token.desc}
      </TableCell> */}
      <TableCell align="right" className={cls(styles.cell, styles.balanceColumn)}>
        {formatTokenPrice(`${holding.balanceFormatted}`).formatted}{' '}
        {/* <span className={styles.usdBalance}>
          ($
          {(holding.balance * (holding.token.priceUsd || 0)).toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
          )
        </span> */}
      </TableCell>
      <TableCell className={cls(styles.cell, styles.visible, styles.labelColumn)}>
        {/* <ButtonLink className={styles.button}>
          <Icon name={isHidden ? IconName.SHOW : IconName.HIDE} />
        </ButtonLink> */}
        {!isHoneypot && (
          <div className={styles.popupContainer}>
            <ButtonIcon
              tooltipTitle="Sell"
              onClick={(e) => setSellPopup({ visible: true, anchorE: e.currentTarget })}
            >
              <Icon name={IconName.SELL} />
            </ButtonIcon>
            <SellPopup
              tokenAddress={holding.a}
              popoverState={sellPopup}
              onChangeVisible={setSellPopup}
              onMarketSellAll={onMarketSellAll}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

export { Row }
