import { useAppDispatch } from '@/store'
import { fetchOrdersHistory } from '@/store/slices/order.slice'

export const useUpdatingStore = () => {
  const dispatch = useAppDispatch()

  const updateStoreAfterBuySell = async () => {
    dispatch(fetchOrdersHistory())
  }

  return {
    updateStoreAfterBuySell,
  }
}
