import { forwardRef, useImperativeHandle, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { updateMasterPassword } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { Accordion, Button, Input } from '@/libs/common'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { passwordValidation } from '@/libs/validations/user'

import styles from './styles.module.scss'

type TForm = {
  new_master: string
  repeat_new_master: string
  current_master: string
}

type TProps = {
  value?: string
}

const defaultValues = {
  current_master: '',
  new_master: '',
  repeat_new_master: '',
}

const validationSchema = yup.object({
  current_master: passwordValidation,
  new_master: passwordValidation,
  repeat_new_master: passwordValidation.oneOf([yup.ref('current_master')], 'Passwords must match'),
})

const ChangeMasterPassword = forwardRef(({ value = '' }: TProps, ref) => {
  const [expanded, setExpanded] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) })

  const checkPassword = (passwords: TForm) => {
    return passwords?.new_master === passwords?.repeat_new_master
  }

  const changeMaster = async (data: TForm) => {
    if (!checkPassword(data)) {
      CustomToast('error', 'Different passwords')
    }
    try {
      const payload = {
        current_master: data?.current_master,
        new_master: data?.new_master,
      }
      setInnerLoading(true)
      await updateMasterPassword(payload)
      CustomToast('success', 'Success')
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setInnerLoading(false)
      setExpanded(false)
    }
  }

  useImperativeHandle(ref, () => ({
    setExpanded,
  }))

  return (
    <Accordion
      className={styles.accordion}
      value={value}
      titleClassName={styles.title}
      title={'Change Master Password'}
      mainColorArrow
      isHorizontal
      expanded={expanded}
      onChange={setExpanded}
    >
      <Controller
        name={'current_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={'Enter current master password'}
            label={'Current Master Password'}
            error={!!errors.current_master?.message}
            {...field}
          />
        )}
      />
      <Controller
        name={'new_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={'Enter new master password'}
            label={'New Master Password'}
            error={!!errors.new_master?.message}
            {...field}
          />
        )}
      />
      <Controller
        name={'repeat_new_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={'Repeat master password'}
            label={'Repeat New Master Password'}
            error={!!errors.repeat_new_master?.message}
            {...field}
          />
        )}
      />
      <Grid container flexWrap="nowrap" gap={2}>
        <Button
          styleVariant="black"
          disabled={innerLoading}
          onClick={() => {
            setExpanded(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          isLoading={innerLoading}
          onClick={handleSubmit((data) => changeMaster(data as TForm))}
          checkOnAccountLock
        >
          Save
        </Button>
      </Grid>
    </Accordion>
  )
})

export { ChangeMasterPassword }
