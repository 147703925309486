import { FC } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'

import tableStyles from '@/components/pending-orders/table-styles.module.scss'
import { Spinner } from '@/components/spinner'
import { Icon, Typography } from '@/libs/common'
import { IconName, SpinnerSize } from '@/libs/enums'
import { TOrderHistory } from '@/libs/types/order.type'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

type TProperty = {
  rows: TOrderHistory[] | null
}

const OrderHistoryTable: FC<TProperty> = ({ rows }) => {
  return (
    <TableContainer className={styles.container}>
      {rows ? (
        rows.length !== 0 ? (
          <Table
            classes={tableStyles}
            aria-label="collapsible table"
            size="small"
            className={tableStyles.container}
          >
            <TableHead>
              <TableRow className={cn(tableStyles.tableHead, styles.headRow)}>
                <TableCell className={cn(tableStyles.cell, tableStyles.arrowCeil)} />

                <TableCell
                  className={cn(
                    tableStyles.cell,
                    tableStyles.headCell,
                    tableStyles.icon,
                    styles.wallet,
                  )}
                  align="center"
                >
                  <Icon name={IconName.WALLET} />
                </TableCell>

                <TableCell
                  className={cn(tableStyles.cell, tableStyles.headCell, styles.type)}
                  align="center"
                >
                  Type
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.token)}>
                  Token
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.amount)}>
                  Amount
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.date)}>
                  Date
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.status)}>
                  Status
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCellWidth)}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={tableStyles.tableBody}>
              {rows.map((row, index) => (
                <Row key={row._id} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
            You have no orders
          </Typography>
        )
      ) : (
        <div className={styles.message}>
          <Spinner size={SpinnerSize.MEDIUM} centered />
        </div>
      )}
    </TableContainer>
  )
}

export { OrderHistoryTable }
