import { FC } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'

import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

const LiteHistoryTable: FC = () => {
  const rows = useAppSelector((state) => state.orders.ordersHistory)
  return (
    <div>
      <TableContainer className={styles.container}>
        <Table
          classes={styles}
          aria-label="collapsible table"
          size="small"
          className={styles.container}
        >
          <TableHead>
            <TableRow className={cn(styles.tableHead, styles.headRow)}>
              <TableCell className={cn(styles.cell, styles.arrowCeil)} />

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.icon, styles.wallet)}
                align="center"
              >
                <Icon name={IconName.WALLET} />
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.type)} align="center">
                Type
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.token)}>
                Token
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.amount)}>
                Amount
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.date)}>Date</TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.status)}>
                Status
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCellWidth)}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {rows ? (
              rows.map((row, index) => <Row key={row._id} row={row} index={index} />)
            ) : (
              <RowLoadingPlaceholder styleValiant="lite" colSpan={8} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export { LiteHistoryTable }
