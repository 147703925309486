import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { collect, disperse } from '@/api/orders'
import { useAppMode } from '@/hooks/useAppMode'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
// import cls from 'classnames'
import { BackButton, Button, Typography } from '@/libs/common'
import { AppRoute, TransactionResponseType } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TPayload = {
  type: 'collect' | 'transfer' | 'disperse'
  token: THoldingsToken | null
  amount: {
    value: string
    valueUSD: number
  }
  percentage?: number
  wallets: string[]
  minerTip: number
}

const EthToken = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'

const ConfirmTransfer: FC = () => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const transferData = useLocation()?.state?.payload as TPayload | undefined
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [isLoading, setIsLoading] = useState(false)
  const currentWallet = useWalletFromParams()

  const onTransfer = async () => {
    try {
      if (!transferData || !currentWallet) return
      setIsLoading(true)
      const wallets = transferData.wallets.map((publicKey) => {
        const wallet = userWallets?.find((item) => item.address === publicKey)
        return transferData.type === 'collect'
          ? {
              name: wallet?.name || publicKey,
              public_key: publicKey,
              mode: transferData.percentage ? 1 : 0,
              percentage: transferData.percentage ? transferData.percentage : 0,
              amount: +transferData.amount.value,
            }
          : {
              name: wallet?.name || publicKey,
              public_key: publicKey,
              amount: +transferData.amount.value,
            }
      })
      const payload = {
        blockchain: currentChain.indexerChainId,
        mode: transferData.token ? 1 : 0,
        //Eth address
        token: transferData.token?.a || EthToken,
        main_wallet: {
          name: currentWallet.name,
          public_key: currentWallet.public_key,
        },
        wallets: wallets,
        gas: {
          gas_limit: 400000,
          auto_calculate: true,
          gas: 10,
          miner_tip: transferData.minerTip,
        },
      }
      if (transferData.type === 'collect') {
        const response = await collect(payload)
        processTransactionResponse(response.data.collectResponse, TransactionResponseType.TRANSFER)
      } else {
        const response = await disperse(payload)
        processTransactionResponse(response.data.disperseResponse, TransactionResponseType.TRANSFER)
      }

      setIsLoading(false)
      navigate(`${AppRoute.DASHBOARD}/${mode}`)
    } catch (err) {
      handleError(err)
      setIsLoading(false)
      navigate(`${AppRoute.DASHBOARD}/${mode}`)
    }
  }

  return (
    <div className={styles.container}>
      <BackButton isAbsolute />
      <Typography variant="h1" align="center">
        Confirm transfer
      </Typography>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              Asset
            </Typography>
            <Typography variant="body2" className={styles.main}>
              {transferData?.token
                ? `${transferData.token.n} (${transferData.token.s})`
                : 'Ethereum (ETH)'}
            </Typography>
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              From
            </Typography>
            {transferData?.type === 'collect' ? (
              <div>
                {transferData?.wallets.map((publicKey) => {
                  const wallet = userWallets?.find((item) => item.address === publicKey)
                  return (
                    <div className={styles.values} key={publicKey}>
                      <Typography variant="body2" className={styles.main}>
                        {wallet?.name}
                      </Typography>
                      <div className={styles.walletsWrapper}>
                        <Typography variant="body2" className={styles.additional}>
                          {wallet
                            ? `(${hideWalletAddress(wallet.public_key)})`
                            : publicKey && hideWalletAddress(publicKey)}
                        </Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  {currentWallet?.name}
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  ({currentWallet && hideWalletAddress(currentWallet.public_key)})
                </Typography>
              </div>
            )}
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              To
            </Typography>
            {transferData?.type === 'collect' ? (
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  {currentWallet?.name}
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  ({currentWallet && hideWalletAddress(currentWallet.public_key)})
                </Typography>
              </div>
            ) : (
              <div>
                {transferData?.wallets.map((publicKey) => {
                  const wallet = userWallets?.find((item) => item.address === publicKey)
                  return (
                    <div className={styles.values} key={publicKey}>
                      <Typography variant="body2" className={styles.main}>
                        {wallet?.name}
                      </Typography>
                      <div className={styles.walletsWrapper}>
                        <Typography variant="body2" className={styles.additional}>
                          {wallet
                            ? `(${hideWalletAddress(wallet.public_key)})`
                            : publicKey && hideWalletAddress(publicKey)}
                        </Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              Amount
            </Typography>
            <div className={styles.values}>
              {transferData?.percentage ? (
                <Typography variant="body2" className={styles.main}>
                  {transferData?.percentage} %
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" className={styles.main}>
                    {transferData?.amount.value}{' '}
                    {transferData?.token ? transferData.token.s : 'ETH'}
                  </Typography>
                  <Typography variant="body2" className={styles.additional}>
                    (~${transferData?.amount.valueUSD.toFixed(2)})
                  </Typography>
                </>
              )}
              {transferData && transferData.wallets.length > 1 && (
                <Typography variant="body2" className={styles.main}>
                  each
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.total}>
            {/* <div className={styles.totalSection}>
              <Typography variant="body2" className={styles.main}>
                Network fee
              </Typography>
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  0.00070043 ETH
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  (~$11.83)
                </Typography>
              </div>
            </div> */}
            {/* <div className={styles.totalSection}>
              <Typography variant="body2" className={cls(styles.light, styles.bold)}>
                Total
              </Typography>
              <div className={styles.values}>
                <Typography variant="body2" className={cls(styles.light, styles.bold)}>
                  0.10070043 ETH
                </Typography>
                <Typography variant="body2" className={styles.bold}>
                  (~$112.83)
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Button onClick={onTransfer} isLoading={isLoading}>
        Transfer
      </Button>
    </div>
  )
}

export { ConfirmTransfer }
