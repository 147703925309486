import BigNumber from 'bignumber.js'

import { LanguageCode } from '@/charting_library/charting_library'

import { SUBSCRIPT_NUMBER_MAP } from './constants'

const calcPricePrecision = (num: number) => {
  if (!num) return 8

  switch (true) {
    case Math.abs(+num) < 0.000000000000000000000000001:
      return 32

    case Math.abs(+num) < 0.0000000000000000000000001:
      return 30

    case Math.abs(+num) < 0.00000000000000000000001:
      return 28

    case Math.abs(+num) < 0.000000000000000000001:
      return 26

    case Math.abs(+num) < 0.0000000000000000001:
      return 24

    case Math.abs(+num) < 0.00000000000000001:
      return 22

    case Math.abs(+num) < 0.000000000000001:
      return 20

    case Math.abs(+num) < 0.0000000000001:
      return 18

    case Math.abs(+num) < 0.00000000001:
      return 16

    case Math.abs(+num) < 0.000000001:
      return 14

    case Math.abs(+num) < 0.0000001:
      return 12

    case Math.abs(+num) < 0.00001:
      return 10

    case Math.abs(+num) < 0.05:
      return 6

    case Math.abs(+num) < 1:
      return 4

    case Math.abs(+num) < 20:
      return 3

    default:
      return 2
  }
}

const formatPrice = (num: number, precision?: number, gr0 = true): string => {
  if (!num) {
    return num.toString()
  }

  if (!precision) {
    precision = calcPricePrecision(+num)
  }

  let formatted = new BigNumber(num).toFormat(precision)

  if (formatted.match(/^0\.[0]+$/g)) {
    formatted = formatted.replace(/\.[0]+$/g, '')
  }

  if (gr0 && formatted.match(/\.0{4,42}[1-9]+/g)) {
    const match = formatted.match(/\.0{4,42}/g)

    if (match) {
      const matchString = match[0].slice(1)
      formatted = formatted.replace(/\.0{4,42}/g, `.0${SUBSCRIPT_NUMBER_MAP[matchString.length]}`)
    }
  }

  return formatted
}

const formatMCap = (price: number, totalSupply: number) => {
  const marketCap = (price * totalSupply) / 1000000000

  if (marketCap >= 1) {
    return marketCap.toFixed(3).toString() + ' B'
  } else if (marketCap >= 0.001) {
    return (marketCap * 1000).toFixed(1).toString() + ' M'
  } else {
    const smallCap = (marketCap * 1000000).toFixed(4)
    const parts = smallCap.split('.')
    parts[1] = +parts[1] > 0 ? (+parts[1] / 10).toString() : '000'

    return parts.join(',') + ' K'
  }
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode)
}

const calculateResolutionCoef = (resolution: string) => {
  const num = resolution.match(/\d+/g)
  const letr = resolution.match(/[a-zA-Z]+/g)
  if (!num) return 0
  if (!letr) return +num[0] * 60
  return letr[0] == 'S' ? +num[0] : +num[0] * 60 * 60 * 24
}

export { formatPrice, formatMCap, getLanguageFromURL, calculateResolutionCoef }
