import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { ChainNameToChainElement } from '@/components/header/libs/maps/chain-name-to-chain-element'
import { Spinner } from '@/components/spinner/spinner'
import { ButtonIcon, Icon } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const ChainStatusInfo: FC = () => {
  const navigate = useNavigate()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentChainBlock = useAppSelector((state) => state.chain.currentChainBlock)
  const nativeTokenPrice = useAppSelector((state) => state.chain.currentChain.nativeTokenPriceInUsd)
  const currentGas = useAppSelector((state) => state.chain.currentGas)

  const handleClick = () => {
    navigate(`${AppRoute.MODAL}/${AppRoute.SWITCH_CHAIN}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.activeChain}>
        <Typography variant="body2" className={styles.title}>
          Chain:
        </Typography>

        <Typography variant="body2" className={styles.chain}>
          {ChainNameToChainElement[currentChain.chainName]}
        </Typography>

        <ButtonIcon icon={IconName.EXCHANGE} onClick={handleClick} />
      </div>

      <div className={styles.verticalDivider}></div>

      <div className={styles.chainDetails}>
        {currentGas ? (
          <Typography variant="body2" className={styles.column}>
            <Icon name={IconName.GAS} /> {currentGas}
          </Typography>
        ) : (
          <Spinner size={SpinnerSize.SMALL} />
        )}

        <div className={styles.divider}></div>

        {currentChainBlock ? (
          <Typography variant="body2" className={styles.column}>
            <Icon name={IconName.BLOCK} />
            {currentChainBlock}
          </Typography>
        ) : (
          <Spinner size={SpinnerSize.SMALL} />
        )}

        <div className={styles.divider}></div>

        {nativeTokenPrice ? (
          <Typography variant="body2" className={styles.column}>
            <Icon name={IconName.DIAMOND} />${Math.round(nativeTokenPrice)}
          </Typography>
        ) : (
          <Spinner size={SpinnerSize.SMALL} />
        )}
      </div>
    </div>
  )
}

export { ChainStatusInfo }
