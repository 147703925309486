import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useRef, useState } from 'react'

import { ToggleButtonGroupProps } from '@mui/material'
import cn from 'classnames'

import styles from './styles.module.scss'

type TProperty = ToggleButtonGroupProps & {
  onCodeChange: (code: string[]) => void
}
export const SixDigitForm: React.FC<TProperty> = ({ onCodeChange }) => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code]
      newCode[index] = value
      setCode(newCode)
      onCodeChange(newCode)
      if (index < 5) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (code[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus()
        }
      } else {
        const newCode = [...code]
        newCode[index] = ''
        setCode(newCode)
        onCodeChange(newCode)
      }
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    const pasteData = e.clipboardData.getData('text')
    if (/^\d{6}$/.test(pasteData)) {
      const newCode = pasteData.split('')
      setCode(newCode)
      onCodeChange(newCode)
      inputRefs.current[5]?.focus()
    }
  }

  return (
    <div onPaste={handlePaste} className={styles.inputs}>
      {code.map((digit, index) => (
        <input
          key={index}
          type="text"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          placeholder=""
          className={cn(styles.input, index === 2 ? styles.inputSpace : '')}
        />
      ))}
    </div>
  )
}
