import { TOrderHistory, TOrderResponse } from '@/libs/types'

import { dateFromObjectId } from './dataFromObjectId'

export const handleOrderHistoryResponse = (data: TOrderResponse): TOrderHistory[] => {
  const buys = data.buys.map(({ wallets, operation, ...rest }) => {
    const innerWallets = wallets.wallets

    return {
      type: 'buy',
      wallets: innerWallets,
      date: dateFromObjectId(rest._id).toISOString(),
      ...rest,
    }
  })
  const sells = data.sells.map(({ operation, ...rest }) => {
    return {
      type: 'sell',
      date: dateFromObjectId(rest._id).toISOString(),
      ...rest,
    }
  })

  return [...buys, ...sells].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}
