import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'

import { useAppSelector } from '@/store'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

const TokenHistoryTable = () => {
  const rows = useAppSelector((stare) => stare.profitSell.profitSell)

  return (
    <TableContainer component={Paper} className={styles.container}>
      <Table classes={styles} aria-label="collapsible table" size="small" className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            <TableCell className={cn(styles.cell, styles.arrowCeil)} />

            <TableCell
              className={cn(styles.cell, styles.headCell, styles.headCellPadding, styles.time)}
            >
              Time
            </TableCell>

            <TableCell className={cn(styles.cell, styles.headCell, styles.tokenCeil)} align="left">
              Token
            </TableCell>

            <TableCell className={cn(styles.cell)} />

            <TableCell className={cn(styles.cell, styles.headCell, styles.spentCeil)} align="right">
              Total Spent
            </TableCell>

            <TableCell
              className={cn(styles.cell, styles.headCell, styles.revenueCeil)}
              align="right"
            >
              Total Revenue
            </TableCell>

            <TableCell className={cn(styles.cell, styles.headCell, styles.plCeil)} align="right">
              Realized P/L
            </TableCell>

            <TableCell className={cn(styles.cell, styles.headCell, styles.plCeil)} align="right">
              Potential P/L
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={styles.tableBody}>
          {rows?.map((row, index) => <Row key={row.panel._id} row={row} index={index} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { TokenHistoryTable }
