const SocketEvent = {
  CONNECT_ERROR: 'connect_error',
  CONNECT: 'connect',
  SUBSCRIBE_TOKEN: 'subscribe-token',
  BARS_UPDATE: 'bars-update',
  SUBSCRIBE_TRANSACTIONS: 'subscribe-transactions',
  TRANSACTIONS_UPDATED: 'transactions-updated',
  UNSUBSCRIBE_TRANSACTIONS: 'unsubscribe-transactions',
  UNSUBSCRIBE_TOKEN: 'unsubscribe-token',
  SUBSCRIBE_MEMPOOL_TRANSACTIONS: 'subscribe-mempool-transactions',
  UNSUBSCRIBE_MEMPOOL_TRANSACTIONS: 'unsubscribe-mempool-transactions',
  MEMPOOL_TRANSACTION_UPDATE: 'mempool-transaction-update',
}

export { SocketEvent }
