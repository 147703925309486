import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from '@/components/spinner'
import { useAppMode } from '@/hooks/useAppMode'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, ButtonIcon, Icon, Popover, type TPopoverProps, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName, SpinnerSize, TabName } from '@/libs/enums'
import { TokesSearchEvent } from '@/libs/enums/tokes-search-event.enum'
import { createQueryString } from '@/libs/helper/createQueryString'
import { useAppDispatch } from '@/store'
import { fetchOrdersHistory } from '@/store/slices/order.slice'

import styles from './styles.module.scss'

type TSellPopupProps = Omit<TPopoverProps, 'children'> & {
  light?: boolean
  tokenAddress?: string
  onMarketSellAll?: () => Promise<void>
}

const SellPopup: FC<TSellPopupProps> = ({
  popoverState,
  light,
  onChangeVisible,
  className,
  tokenAddress,
  onMarketSellAll,
}) => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const windowDimension = useWindowDimensions()
  const isSellInModal = windowDimension.isBottomTabsVisible

  const handleOpenSellPanel = () => {
    let redirectLink = isSellInModal
      ? `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.SELL_MODAL}`
      : `${AppRoute.DASHBOARD}/${mode}`
    const query = createQueryString({
      token: tokenAddress,
      tokenEvent: tokenAddress ? TokesSearchEvent.SELL : null,
      [mode === AppMode.PRO ? TabName.BUY_SELL : TabName.BUY_SELL_LITE]: isSellInModal
        ? null
        : 'sell',
    })
    redirectLink += query

    navigate(redirectLink)
    onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
  }

  const handleSellAll = async () => {
    setIsLoading(true)
    if (onMarketSellAll) {
      await onMarketSellAll()
      await dispatch(fetchOrdersHistory()).unwrap()
    }
    setIsLoading(false)
    onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
  }

  return (
    <Popover
      className={className}
      popoverState={popoverState}
      onChangeVisible={onChangeVisible}
      light={light}
    >
      <div>
        <Typography variant="body2" className={styles.title}>
          Sell
        </Typography>

        <ButtonIcon
          onClick={() => onChangeVisible({ visible: false, anchorE: popoverState.anchorE })}
          className={styles.close}
        >
          <Icon name={IconName.CLOSE_SMALL} />
        </ButtonIcon>

        <div className={styles.buttons}>
          {/* <ButtonIcon
          onClick={() => {
            onChangeVisible(false)
          }}
          className={styles.cancel}
        >
          <Typography variant="body2" textColor="gold">
            Cancel
          </Typography>
        </ButtonIcon> */}

          <ButtonIcon
            onClick={() => {
              handleOpenSellPanel()
              onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
            }}
            className={styles.sellPanel}
          >
            <Typography variant="body2" textColor="color-grey-3">
              Open sell panel
            </Typography>
          </ButtonIcon>

          <Button className={styles.confirm} onClick={handleSellAll} disabled={isLoading}>
            {isLoading ? <Spinner size={SpinnerSize.SMALL} /> : 'Market sell all'}
          </Button>
        </div>
      </div>
    </Popover>
  )
}

export { SellPopup, type TSellPopupProps }
