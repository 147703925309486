import { useCallback, useState } from 'react'

import update from 'immutability-helper'

import { DragAndDropListItem } from '@/libs/common/drag-and-drop-list-item'
import { TDraggableItemId } from '@/libs/types/draggable.type'

type TProps<T> = {
  data: T[]
  setData: (newData: T[]) => void
  renderItem: (item: T, isDragging: boolean) => React.JSX.Element
  propOfItemForKey: string
  onDragStateChange?: (isDragging: boolean) => void
  onOrderChanged?: (oldIndex: number, newIndex: number) => void
}

const DragAndDropList = <T extends Record<string, any>>({
  data,
  setData,
  renderItem,
  propOfItemForKey,
  onDragStateChange,
  onOrderChanged,
}: TProps<T>) => {
  const [currentDragging, setCurrentDragging] = useState<{
    id: TDraggableItemId
    index: number
  } | null>(null)

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const oldData = [...data]
      setData(
        update(oldData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, oldData[dragIndex]],
          ],
        }),
      )
    },
    [data, setData],
  )

  return data.map((item, index) => {
    const key = item[propOfItemForKey]

    return (
      <DragAndDropListItem
        key={key}
        id={key}
        index={index}
        moveItem={moveItem}
        renderItem={(isDragging) => renderItem(item, isDragging)}
        onDragStateChange={({ state, id, index }) => {
          if (state) {
            setCurrentDragging({ id, index })
          } else if (currentDragging) {
            if (id === currentDragging.id && index !== currentDragging.index) {
              onOrderChanged?.(currentDragging.index, index)
            }
            setCurrentDragging(null)
          }

          onDragStateChange?.(state)
        }}
      />
    )
  })
}

export { DragAndDropList }
