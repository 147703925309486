import { Grid } from '@mui/material'

import { ProfitSellTab } from '@/components/profit-sell-tab'
import { Typography } from '@/libs/common'

const ProfitModal = () => {
  return (
    <Grid>
      <Typography variant="h2" marginBottom={3}>
        Profit/Sell
      </Typography>
      <ProfitSellTab usedInModal />
    </Grid>
  )
}

export { ProfitModal }
