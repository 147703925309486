import { FC, useEffect, useState } from 'react'

import { TUserFavorite } from '@/api/favorites/types'
import { IToken } from '@/libs/types/token-info-response.type'

type TProps = {
  token?: IToken | TUserFavorite
  size?: string
}

const TokenPlaceholder: FC<TProps> = ({ token, size }) => {
  const [gradient, setGradient] = useState('')

  const createHex = () => {
    let hexCode = ''
    const hexValues = '0123456789abcdef'

    for (let i = 0; i < 6; i++) {
      hexCode += hexValues.charAt(Math.floor(Math.random() * hexValues.length))
    }

    return hexCode
  }

  const generateGradient = () => {
    const deg = Math.floor(Math.random() * 180)
    const color1 = '#' + createHex()
    const color2 = '#' + createHex()

    const generatedGradient = `linear-gradient(${deg}deg, ${color1}, ${color2})`

    setGradient(generatedGradient)
  }

  useEffect(() => {
    generateGradient()
  }, [token])

  return (
    <div>
      <div
        id="bg"
        style={{
          width: !size ? '24px' : size,
          height: !size ? '24px' : size,
          borderRadius: '50%',
          background: gradient,
        }}
      ></div>
    </div>
  )
}

export { TokenPlaceholder }
