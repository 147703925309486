import { Icon } from '@/libs/common'
import { ChainName, IconName } from '@/libs/enums'

const ChainNameToChainElement = {
  [ChainName.ETHEREUM]: (
    <>
      <Icon name={IconName.ETHEREUM} /> ETH
    </>
  ),
  [ChainName.ARBITRUM]: (
    <>
      <Icon name={IconName.ARBITRUM} /> <span>Arbitrum Chain</span>
    </>
  ),
  [ChainName.BNB]: (
    <>
      <Icon name={IconName.BNB} /> <span>BNB Smart Chain</span>
    </>
  ),
  [ChainName.BASE]: (
    <>
      <Icon name={IconName.BASE_CHAIN} /> <span>Base Chain</span>
    </>
  ),
  [ChainName.AVAX]: (
    <>
      <Icon name={IconName.AVAX_CHAIN} /> <span>Avax Chain</span>
    </>
  ),
}

export { ChainNameToChainElement }
