import { Grid } from '@mui/material'

import { BuyTab } from '@/components/buy-tab'
import { Typography } from '@/libs/common'

const BuyModal = () => {
  return (
    <Grid>
      <Typography variant="h2" marginBottom={3}>
        Buy
      </Typography>
      <BuyTab usedInModal />
    </Grid>
  )
}

export { BuyModal }
