import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { TBuyTemplate } from '@/libs/types/template'
import { store } from '@/store'

const createBuyFormStateFromTemplate = (template: TBuyTemplate) => {
  const userWallets = store.getState().user.userWallets

  const buyMode = template.setup.operation.setup.buy_mode

  const walletsPositions: string[] = []
  template.setup.wallets.forEach((wallet) => {
    const userWalletToUse = userWallets?.find((item) => item.address === wallet.public_key)
    if (userWalletToUse) {
      walletsPositions.push(userWalletToUse.address)
    }
  })

  const ordinaryBuyDefaultValues = {
    privateTransaction: template.setup.operation.private_tx,
    degenChadMode: !template.setup.operation.shields.anti_honeypot,
    slippage: template.setup.operation.setup.slippage,
    selectedWallets: walletsPositions,
    spend: '',
    receive: '',
  }

  const advancedBuyDefaultValues = {
    buyPriority: `${template.setup.operation.gas.buy.miner_tip}`,
    approvePriority: `${template.setup.operation.gas.approve.miner_tip}`,
    minPercentTokenOrFail: `${
      buyMode === EBuyMode.MIN_TOKENS
        ? template.setup.operation.setup.buy_tokens
        : buyMode === EBuyMode.MIN_PERCENT
          ? `${template.setup.operation.setup.supply_percentage}%`
          : ''
    }`,
    maxTxOrFail: buyMode === EBuyMode.MAX_TRANSACTION,
  }

  const shieldsDefaultValues = {
    buy_tax: `${template.setup.operation.shields.buy_tax}`,
    sell_tax: `${template.setup.operation.shields.sell_tax}`,
    minimum_liquidity: `${template.setup.operation.shields.minimum_liquidity}`,
    maximum_liquidity: `${template.setup.operation.shields.maximum_liquidity}`,
    maximum_market_cap: `${template.setup.operation.shields.maximum_market_cap}`,
  }

  return {
    name: template.name,
    template: {
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
    },
  }
}

export { createBuyFormStateFromTemplate }
