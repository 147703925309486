import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { addUserFavoriteToken, deleteUserFavoriteToken, getUserFavorites } from '@/api/favorites'
import { TUserFavorite } from '@/api/favorites/types'
import { getUserHistory } from '@/api/history'
import { EFavoritesErrorMessage } from '@/libs/enums/favorites-error-message'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TInitialState = {
  userFavorites: TUserFavorite[] | null
  userHistory: TUserFavorite[] | null
}

const initialState: TInitialState = {
  userFavorites: null,
  userHistory: null,
}

const { thunk: fetchUserFavorites, applyExtraReducers: applyExtraReducersForFavorites } =
  createAsyncThunkWithControll(
    'favoritesTokens/fetchUserFavorites',
    async ({ networkId }: { networkId: number }) => {
      const { data } = await getUserFavorites(networkId)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      return data.data
    },
  )

const addFavoriteToken = createAsyncThunk(
  'favoritesTokens/addFavoriteToken',
  async ({ tokenAddress, networkId }: { tokenAddress: string; networkId: number }) => {
    const { data } = await addUserFavoriteToken(tokenAddress, networkId)

    return data.data
  },
)

const removeFavoriteToken = createAsyncThunk(
  'favoritesTokens/removeFavoriteToken',
  async ({ tokenAddress, networkId }: { tokenAddress: string; networkId: number }) => {
    const { data } = await deleteUserFavoriteToken(tokenAddress, networkId)

    return data.data
  },
)

const fetchUserHistory = createAsyncThunk(
  'favoritesTokens/fetchUserHistory',
  async (networkId: number) => {
    const { data } = await getUserHistory(networkId)
    return data
  },
)

const favoritesTokensSlice = createSlice({
  name: 'favoritesTokens',
  initialState,
  reducers: {
    changeFavoritesOrder: (state, { payload }: PayloadAction<TUserFavorite[]>) => {
      state.userFavorites = payload
    },
  },
  extraReducers: (builder) => {
    applyExtraReducersForFavorites(builder, {
      onFulfilled: (state, { payload }) => {
        state.userFavorites = payload
      },
      // onRejected: (state) => {
      //   handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_FETCH)
      // },
    })

    builder.addCase(addFavoriteToken.fulfilled, (state, { payload }) => {
      state.userFavorites = payload
    })

    builder.addCase(fetchUserHistory.fulfilled, (state, { payload }) => {
      state.userHistory = payload
    })

    // builder.addCase(addFavoriteToken.rejected, (state, { error }) => {
    //   console.log('error', error)

    //   // extractErrorDescription(error)
    //   handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_ADD)
    // })

    builder.addCase(removeFavoriteToken.fulfilled, (state, { payload }) => {
      state.userFavorites = payload
    })

    // builder.addCase(removeFavoriteToken.rejected, (state) =>
    //   handleRejectReducer(state, EFavoritesErrorMessage.FAILED_TO_REMOVE),
    // )
  },
})

const { changeFavoritesOrder } = favoritesTokensSlice.actions

export {
  changeFavoritesOrder,
  favoritesTokensSlice,
  fetchUserFavorites,
  fetchUserHistory,
  addFavoriteToken,
  removeFavoriteToken,
}
