import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid, Stack } from '@mui/material'

import avax from '@/assets/img/share/avax-horizontal.jpg'
import eth from '@/assets/img/share/eth-horizontal.jpg'
import { CustomToast } from '@/components/custom-toast'
import { Button, Tabs, Typography } from '@/libs/common'
import { CanvasHandle } from '@/libs/types/canvas.type'
import { Canvas } from '@/pages/modal-page/libs/components/share-profit/libs/components/canvas'

const ShareProfit = () => {
  // const [selectedTab, setSelectedTab] = useState(0)
  const canvasRef = useRef<CanvasHandle | null>(null)
  const tokenInfo = useLocation().state.positionsSummary

  const handleSaveCanvasImage = () => {
    const canvas = canvasRef.current?.getCanvas()
    if (canvas) {
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')

      const link = document.createElement('a')
      link.href = image
      link.download = 'profit_share.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleCopyToClipboard = async () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current.getCanvas()
      if (canvas) {
        try {
          const dataUrl = canvas.toDataURL('image/png')

          const res = await fetch(dataUrl)
          const blob = await res.blob()

          await navigator.clipboard.write([
            new ClipboardItem({
              'image/png': blob,
            }),
          ])

          CustomToast('success', 'Image copied to clipboard.')
        } catch (error) {
          CustomToast('error', 'Failed to copy image to clipboard.')
        }
      }
    }
  }

  function getNetworkBackground(network: string) {
    switch (network) {
      case 'ethereum':
        return eth
      case 'avalanche':
        return avax
      default:
        return eth
    }
  }

  const tableTabs = [
    {
      label: 'Horizontal (Desktop)',
      content: (
        <Canvas
          img={getNetworkBackground('ethereum')}
          width={711}
          height={400}
          ref={canvasRef}
          isPositionOpen
          tokenInfo={tokenInfo}
        />
      ),
    },
    {
      label: 'Vertical (Phone)',
      content: (
        <Canvas
          img={getNetworkBackground('avalanche')}
          width={711}
          height={400}
          ref={canvasRef}
          isPositionOpen={false}
          tokenInfo={tokenInfo}
        />
      ),
    },
  ]

  // const handleChange = (newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  return (
    <Stack spacing={3} gap={2}>
      <Typography variant="h1" align="center">
        token
      </Typography>

      <Tabs
        tabs={tableTabs}
        defaultValue={0}
        // handleChangeTab={handleChange}
      />

      <Grid display="flex" gap={2}>
        <Button styleVariant="basic" onClick={handleCopyToClipboard}>
          Copy to clipboard
        </Button>

        <Button styleVariant="basic" onClick={handleSaveCanvasImage}>
          Save image
        </Button>
      </Grid>
    </Stack>
  )
}

export { ShareProfit }
