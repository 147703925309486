import { TChainDex } from '@/libs/types/chain.type'
import { TTokenExchangeItem } from '@/libs/types/tokens.type'
import { store } from '@/store'

const getDexForToken = (exchanges: TTokenExchangeItem[]): (TChainDex & TTokenExchangeItem)[] => {
  const currentChain = store.getState().chain.currentChain

  return exchanges
    .map((item) => {
      let dexString = item.name
      if (!dexString) {
        return null
      }
      if (item.exchangeVersion) {
        dexString += ` V${item.exchangeVersion}`
      }

      const dex = currentChain.chainDexes.find(
        (dex) => dex.label.toLowerCase() === dexString.toLowerCase(),
      )
      if (!dex) {
        return null
      }
      return { ...dex, ...item }
    })
    .filter(Boolean)
    .sort((a: any, b: any) => a.id - b.id) as (TChainDex & TTokenExchangeItem)[]
}

export { getDexForToken }
