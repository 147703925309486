import { FC, createContext, useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Modal from '@mui/material/Modal'
import cn from 'classnames'

import { useAppMode } from '@/hooks/useAppMode'
import { BackButton, ButtonIcon, Typography } from '@/libs/common'
import { TypographyProps } from '@/libs/common/typography/typography'
import { AppRoute, IconName } from '@/libs/enums'
import { getEndOfPath } from '@/libs/helper/getEndOfPath'
import { useAppDispatch, useAppSelector } from '@/store'
import { toggleSkipCleanUnconfirmedWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const UNCONFIRMED_WALLETS_ROUTES: string[] = [
  AppRoute.SELECT_WALLET_COUNT,
  AppRoute.WALLET_MANAGEMENT,
]

const wideModals: string[] = [AppRoute.IMPORT_WALLETS]
type TProps = {
  isAuth?: boolean
}

type TModalProps = {
  title?: string
  titleProps?: TypographyProps
  withBackButton?: boolean
  removeCloseBtn?: boolean
}

const initalState = { setModalProps: (_: TModalProps) => {} }
const ModalContext = createContext(initalState)
const useModal = () => useContext(ModalContext)

const ModalPage: FC<TProps> = ({ isAuth }) => {
  const tokenAddress = useAppSelector((state) => state.chain.currentToken?.token.address)

  const [modalProps, setModalProps] = useState<TModalProps | null>(null)
  const [propsSavedTo, setPropsSavedTo] = useState<string>('')

  const dispatch = useAppDispatch()

  const { mode } = useAppMode()
  const navigate = useNavigate()
  const location = useLocation()

  const isShareProfit = location.pathname.endsWith('share-profit')
  const isWideModal = wideModals.some((item) => location.pathname.endsWith(item))

  useEffect(() => {
    if (modalProps && propsSavedTo !== location.pathname) {
      setModalProps(null)
      setPropsSavedTo('')
    }
    if (AppRoute.WALLET_MANAGEMENT.includes(getEndOfPath(location.pathname))) {
      dispatch(toggleSkipCleanUnconfirmedWallets(false))
    }
  }, [location.pathname])

  const handleNewProps = (newProps: TModalProps) => {
    setModalProps(newProps)
    setPropsSavedTo(location.pathname)
  }

  const handleClose = () => {
    if (UNCONFIRMED_WALLETS_ROUTES.includes(getEndOfPath(location.pathname))) {
      dispatch(toggleSkipCleanUnconfirmedWallets(false))
    }
    if (!isAuth) {
      navigate(`${AppRoute.DASHBOARD}/${mode}${tokenAddress ? `?token=${tokenAddress}` : ''}`)
    } else {
      navigate(-1)
    }
  }

  return (
    <ModalContext.Provider value={{ setModalProps: handleNewProps }}>
      <Modal open disableEnforceFocus onClose={handleClose} id="modal-wrapper">
        <div className={cn(styles.wrapper)}>
          <TransitionGroup exit={false}>
            <CSSTransition
              key={location.pathname}
              classNames={{
                enter: styles.animationEnter,
                enterActive: styles.animationEnterActive,
                appear: styles.animationAppear,
                appearActive: styles.animationAppearActive,
              }}
              timeout={300}
              in
              appear
            >
              <div
                id="modal"
                className={cn(
                  styles.container,
                  !isShareProfit ? styles.containerModal : styles.containerShareWidth,
                  { [styles.wide]: isWideModal },
                )}
              >
                {modalProps?.withBackButton && <BackButton isAbsolute />}
                {!modalProps?.removeCloseBtn && (
                  <ButtonIcon
                    icon={IconName.CLOSE}
                    className={styles.close}
                    onClick={handleClose}
                  />
                )}
                {modalProps?.title && (
                  <Typography
                    variant="h1"
                    align="center"
                    {...modalProps.titleProps}
                    className={cn(styles.title, modalProps.titleProps?.className)}
                  >
                    {modalProps.title}
                  </Typography>
                )}
                <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </Modal>
    </ModalContext.Provider>
  )
}

export { ModalPage, useModal }
