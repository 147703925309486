import { FC, useState } from 'react'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { deleteProfitPanel } from '@/api/profit-loss'
import { TProfitSellItem } from '@/api/profit-loss/types'
import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { CustomToast } from '@/components/custom-toast'
import styles from '@/components/profit-sell-tab/libs/components/styles-cards.module.scss'
import { useUpdatingStore } from '@/hooks/updateStore.hook'
import {
  Button,
  ButtonIcon,
  Popover,
  TOnChangeVisible,
  TokenPlaceholder,
  TokenRedirect,
  Typography,
} from '@/libs/common'
import stylesPopoverDelete from '@/libs/common/popover/styles-popover-delete.module.scss'
import { IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, getPriceImpactColor, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { handleError } from '@/libs/helper/handleError'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchProfitSellForce, setCurrentPosition } from '@/store/slices/profit-sell.slice'

type TProps = {
  position: TProfitSellItem
  className?: string
}

const ProfitSellTabCard: FC<TProps> = ({ className, position }) => {
  const { updateStoreAfterBuySell } = useUpdatingStore()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentPosition = useAppSelector((state) => state.profitSell.currentPosition)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  // const navigate = useNavigate()

  // const [isActiveLoading, setIsActiveLoading] = useState(false)
  // const [isActive, setIsActive] = useState(position.panel.isActive)

  // const handleActive = async (_: BaseSyntheticEvent, newValue: boolean) => {
  //   try {
  //     setIsActiveLoading(true)
  //     setIsActive(newValue)
  //     await setProfitPanelActivity(position.panel._id, newValue)
  //     await dispatch(fetchProfitSellForce()).unwrap()
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //     setIsActive(!newValue)
  //   } finally {
  //     setIsActiveLoading(false)
  //   }
  // }

  const handleDelete = async () => {
    try {
      await deleteProfitPanel({ ...position.panel, isDeleted: true })
      await dispatch(fetchProfitSellForce()).unwrap()
      CustomToast('success', 'Position deleted successfully')
    } catch (err) {
      handleError(err)
    }
  }

  // const handleOpenShareProfitModal = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
  //     state: { position },
  //   })
  // }

  // const handleOpenEditPosition = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}/${position.panel.token_address}`)
  // }

  const switchToViewPage = () => {
    if (currentPosition?.panel.token_address !== position.panel.token_address) {
      dispatch(setCurrentPosition(position))
    }
  }

  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const onSell = async (type: 'all' | 'initial') => {
    if (!position.panel_data.userWallets.length) return

    setIsLoading(true)
    const wallets = position.panel_data.userWallets.map((item) => ({
      name: item.name,
      public_key: item.public_key,
    }))

    const payload = await createSellPayload({
      token: position.panel.token_address,
      wallets,
      mode: type === 'all' ? 0 : 2,
      sellPercentage: type === 'all' ? 100 : 0,
    })

    if (payload) {
      await handleSell(payload)
      updateStoreAfterBuySell()
    }

    setIsLoading(false)
  }

  return (
    <Grid
      className={cn(
        styles.card,
        //  { [styles.inactiveCard]: !isActive }
        className,
      )}
    >
      {/* header row */}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={styles.rowContainer}
      >
        <TokenRedirect className={styles.rowContainerItem} address={position.panel.token_address}>
          <Grid container className={styles.coinIcon}>
            {position.panel_data.tokenMetadata.logo ? (
              <img
                className={styles.tokenLogo}
                src={position.panel_data.tokenMetadata.logo}
                alt={position.panel_data.tokenMetadata.name + ' token'}
              />
            ) : (
              <TokenPlaceholder token={{} as any} />
            )}
          </Grid>
          {position.panel_data.tokenMetadata.name && (
            <Typography variant="body2" className={styles.tokenName}>
              {position.panel_data.tokenMetadata.name}
            </Typography>
          )}
        </TokenRedirect>
        {/* <Grid container className={styles.rowContainerItem}>
          <Typography variant="body2">P/S Panel active</Typography>
          <Switch checked={isActive} onChange={handleActive} disabled={isActiveLoading} />
        </Grid> */}
        <Grid container className={styles.headerButtonsGroup}>
          {/* <Button
            className={styles.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.SHARE}
            transparent
            onClick={handleOpenShareProfitModal}
            disabled
          >
            Share
          </Button>
          <Button
            className={styles.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.EDIT}
            transparent
            onClick={handleOpenEditPosition}
            disabled
          >
            Edit position
          </Button> */}
          <Button
            tooltipTitle="Delete"
            className={stylesPopoverDelete.deleteButton}
            styleVariant="borderless-red"
            iconStartName={IconName.DELETE}
            transparent
            onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
          ></Button>

          <Popover
            className={stylesPopoverDelete.popover}
            popoverState={deletePopup}
            onChangeVisible={setDeletePopup}
          >
            <Typography variant="body2" className={stylesPopoverDelete.title}>
              Confirm Delete
            </Typography>
            <Typography variant="body2">Are you sure you want to delete this position?</Typography>
            <div className={stylesPopoverDelete.popoverButtons}>
              <ButtonIcon
                onClick={() => {
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                <Typography variant="body2" className={stylesPopoverDelete.cancel}>
                  Cancel
                </Typography>
              </ButtonIcon>
              <Button
                className={stylesPopoverDelete.confirm}
                styleVariant="red"
                onClick={() => {
                  handleDelete()
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                Delete
              </Button>
            </div>
          </Popover>
        </Grid>
      </Grid>

      {/* middle rows */}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        direction="column"
        className={styles.rowContainer}
        gap={1}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={styles.rowContainer}
        >
          <VerticalGroup
            //TO DO get wallets count with positive balance from back
            // title={`Total balance (${position.panel_data.userWallets.length} wallets)`}
            title={`Total balance`}
            description={<>{formatTokenPrice(`${position.panel_data.currentTokens}`).formatted}</>}
          />
          <Grid container className={styles.middleButtonsGroup}>
            <Button
              styleVariant="black"
              className={cn(styles.middleRowButton, styles.buttonSellAll)}
              transparent
              onClick={() => onSell('all')}
              isLoading={isLoading}
            >
              Sell all
            </Button>

            <Button
              styleVariant="black"
              className={cn(styles.middleRowButton, styles.buttonSellInitial)}
              transparent
              onClick={() => onSell('initial')}
              isLoading={isLoading}
            >
              Sell initial
            </Button>

            <Button
              styleVariant="black"
              iconEndName={IconName.ARROW_RIGHT2}
              className={styles.middleRowButton}
              onClick={switchToViewPage}
              transparent
            >
              View
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Button
            className={styles.transferButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.WITHDRAW}
            transparent
            disabled
          >
            Transfer to backup wallets
          </Button>
        </Grid> */}
      </Grid>

      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={styles.rowContainer}
        marginBottom={2}
      >
        <VerticalGroup
          title={'Profit/Loss'}
          description={
            <span
              className={cn(position.panel_data.profitLoss > 0 ? styles.isProfit : styles.isLoss)}
            >
              {'%'}
              {Math.abs(position.panel_data.profitLoss).toFixed(2)}
            </span>
          }
        />
        <VerticalGroup
          title={'Cost'}
          description={
            <>
              {formatTokenPrice(`${position.panel_data.boughtAmountEth}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'Worth'}
          descClassName={
            position.panel_data.worth > position.panel_data.boughtAmountEth ? styles.greenText : ''
          }
          description={
            <>
              {formatTokenPrice(`${position.panel_data.worth}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'After Tax'}
          descClassName={
            position.panel_data.afterSell > position.panel_data.boughtAmountEth
              ? styles.greenText
              : ''
          }
          description={
            <>
              {formatTokenPrice(`${position.panel_data.afterSell}`).formatted}{' '}
              {currentChain.description}
            </>
          }
        />
        <VerticalGroup
          title={'Sold'}
          description={<>{formatNumber(position.panel_data.soldEth).formatted} ETH</>}
        />
        <VerticalGroup
          title={'Price impact'}
          descClassName={getPriceImpactColor(position.panel_data.priceImpact * 100)}
          description={<>{formatNumber(position.panel_data.priceImpact * 100).formatted}%</>}
        />
      </Grid>

      {/* footer row */}
      {/* <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="center"
        className={cn(styles.rowContainer, styles.footerRow)}
        gap={3}
      >
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Order type:
          </Typography>
          <OrderType type="snipe" />
        </Grid>
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Date:
          </Typography>
          <Typography variant="body2" textColor="light-grey" resetLineHeight noWrap>
            2024-01-25
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export { ProfitSellTabCard }
