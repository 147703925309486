type TProps = {
  keys: string[]
  data: { [key: string]: string }[]
  fileName: string
}

const downloadCsv = ({ data, keys, fileName }: TProps) => {
  const csvString = [
    keys.join(','),
    ...data.map((item) => keys.map((key) => item[key]).join(',')),
  ].join('\n')

  const blob = new Blob([csvString], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = `${fileName}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export { downloadCsv }
