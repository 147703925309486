import { FC } from 'react'
import { Link } from 'react-router-dom'

import { TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { TTokenTransactionFiltered } from '@/api/tokens/types'
import styles from '@/components/transactions-table/styles.module.scss'
import { TColumn } from '@/components/transactions-table/transactions-table'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'

type TProperty = {
  row: TTokenTransactionFiltered
  columns: TColumn[]
  styleValiant: 'basic' | 'lite'
}

const Row: FC<TProperty> = ({ row, columns, styleValiant }) => {
  return (
    <TableRow
      role="checkbox"
      tabIndex={-1}
      className={cn(styles.bodyRow, styleValiant === 'basic' ? styles.basic : styles.lite)}
    >
      {columns.map((column) => {
        const value = (row as TTokenTransactionFiltered & Record<string, string>)[column.id]

        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={cn(
              styles.cell,
              styles[column.id],
              column.id !== 'timestamp' && styles[row.eventDisplayType],
            )}
          >
            {column.label === 'Makers' ? (
              <Link
                to={`https://etherscan.io/address/${value}`}
                target="_blank"
                className={styles.addressLink}
              >
                <div className={styles.text}>
                  {row.ensName && row.ensName !== '-' ? row.ensName : hideWalletAddress(value)}
                </div>
              </Link>
            ) : column.format ? (
              column.format(value)
            ) : (
              value
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export { Row }
