import { FC, useState } from 'react'

import cn from 'classnames'

import { ButtonIcon, type TButtonIconProps, Tooltip } from '@/libs/common/index'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TButtonCopyProps = Omit<TButtonIconProps, 'icon'> & {
  value: string | undefined
}

const ButtonCopy: FC<TButtonCopyProps> = ({ value, className, large, ...props }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleCopyToClipboard = (event: React.MouseEvent) => {
    setIsTooltipVisible(true)
    setTimeout(() => setIsTooltipVisible(false), 1000)
    event.stopPropagation()
    if (value) navigator.clipboard.writeText(value)
  }

  return (
    <Tooltip title={'Copied'} withIcon={false} open={!!isTooltipVisible}>
      <ButtonIcon
        onClick={handleCopyToClipboard}
        className={cn(styles.buttonCopy, className)}
        {...props}
        icon={IconName.COPY}
      />
    </Tooltip>
  )
}

export { ButtonCopy }
