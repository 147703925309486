import { getAccessToken } from '@/api'
import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { timer } from '@/libs/helper'

type TSniperWebsocketConnectProps = {
  onOpen?: () => any
  endpoint?: string
  query?: string
  reconnect?: boolean
}

type TOnMessageFunc = (data: string) => any

type TOnErrorFunc = (ev: Event) => any

class SniperSockerService {
  private baseUrl = import.meta.env.VITE_SNIPER_SOCKET_URL
  private socket: WebSocket | null = null
  private connectionProps: TSniperWebsocketConnectProps | null = null
  private onMessageHandler: TOnMessageFunc | null = null
  private onErrorHandler: TOnErrorFunc | null = null
  private status = EWebsocketStatus.CLOSED

  constructor(baseUrl?: string) {
    if (baseUrl) this.baseUrl = baseUrl
  }

  async connect(props?: TSniperWebsocketConnectProps | null) {
    const { endpoint = '', query = '', reconnect = true, onOpen } = props || {}
    this.connectionProps = { endpoint, query, reconnect, onOpen }

    const encodedToken = await this.prepareAccessToken()

    if (!encodedToken) {
      return
    }

    this.socket = new WebSocket(`${this.baseUrl}/${endpoint}${query}`, [
      'BlazingSocket',
      encodedToken,
    ])
    this.status = EWebsocketStatus.CONNECTING

    this.socket.onclose = () => {
      if (!reconnect) {
        this.status = EWebsocketStatus.CLOSED
        return
      }

      setTimeout(() => this.reconnect(), 5000)
    }

    this.socket.onopen = () => {
      this.status = EWebsocketStatus.OPEN
      onOpen?.()
    }
  }

  disconnect() {
    if (!this.socket || ![EWebsocketStatus.OPEN, EWebsocketStatus.CONNECTING].includes(this.status))
      return

    this.socket.onclose = null
    this.socket.close()
    this.socket = null
    this.status = EWebsocketStatus.CLOSED
  }

  async reconnect(newConnectionProps?: TSniperWebsocketConnectProps) {
    if (newConnectionProps) {
      this.connectionProps = newConnectionProps
    }
    this.disconnect()
    await this.connect(this.connectionProps)

    if (this.onMessageHandler) this.onMessage(this.onMessageHandler)
    if (this.onErrorHandler) this.onError(this.onErrorHandler)
  }

  onMessage(cb: TOnMessageFunc) {
    if (!this.socket) return

    this.onMessageHandler = cb
    this.socket.onmessage = (event) => {
      cb(event.data)
    }
  }

  onError(cb: TOnErrorFunc) {
    if (!this.socket) return

    this.onErrorHandler = cb
    this.socket.onerror = (event) => {
      cb(event)
    }
  }

  emit(event: any) {
    this.socket?.send(event)
  }

  getSocket() {
    return this.socket
  }

  getStatus() {
    return this.status
  }

  private async prepareAccessToken(previousToken?: string): Promise<string | null> {
    let tokenToUse = previousToken
    if (!tokenToUse) {
      const accessToken = localStorage.token as string | undefined
      if (!accessToken) {
        return null
        // await timer(1000)
        // return this.prepareAccessToken()
      } else {
        tokenToUse = accessToken
      }
    }
    const encoded = btoa(tokenToUse)
    if (encoded.includes('=')) return this.prepareAccessToken(tokenToUse + ' ')
    return encoded
  }
}

export { SniperSockerService }
export type { TSniperWebsocketConnectProps }
