import { FC, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'
import moment from 'moment'

import { EAuthQueryParams } from '@/components/app/libs/enums/auth-query-params.enum'
import { CustomToast } from '@/components/custom-toast'
import { authRoutes } from '@/libs/configs/auth-routes.config'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { AppMode, AppRoute, LocalStorageItem } from '@/libs/enums'
import { useAppDispatch } from '@/store'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'

const AFFILIATE_EXPIRES_IN_SECONDS = 420

type TProps = {
  setIsAppLoaded: (value: boolean) => void
}

const AuthController: FC<TProps> = ({ setIsAppLoaded }) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mode } = useParams()

  const loadApp = () => {
    const wrappedTon = DEFAULT_TOKEN
    const existToken = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)
    if (
      import.meta.env.VITE_IS_TEST_INSTANCE !== 'true' &&
      authRoutes.includes(location.pathname)
    ) {
      navigate(`${AppRoute.DASHBOARD}/${mode || AppMode.PRO}?token=${existToken || wrappedTon}`, {
        replace: true,
      })
    }
    setIsAppLoaded(true)
  }

  const handleAuth = async (): Promise<void> => {
    const token = localStorage.token as string | undefined

    if (!token) {
      loadApp()
      return
    }

    const decoded = jwtDecode(token)
    const userId = decoded?.sub as string

    try {
      const userData = await dispatch(fetchUserById(userId)).unwrap()

      if (!userData) {
        loadApp()
        return
      }

      dispatch(fetchUserBuyTemplates())

      loadApp()
    } catch (err) {
      CustomToast('error', (err as any).message)
      loadApp()
    }
  }

  useEffect(() => {
    const affiliateId = searchParams.get(EAuthQueryParams.AFFILIATE_ID)

    if (affiliateId) {
      localStorage.setItem(
        EAuthQueryParams.AFFILIATE_ID,
        JSON.stringify({
          affiliateId,
          expires: moment().add(AFFILIATE_EXPIRES_IN_SECONDS, 'seconds').valueOf(),
        }),
      )
    }
    // Affiliate id expiration check. Use this during the signup process
    // if (!!affiliateInfo && moment(affiliateInfo.expires).diff(moment(), 'seconds') > 0) {
    //   affiliateId = affiliateInfo.affiliateId
    // }

    handleAuth()
  }, [])

  return <></>
}

export { AuthController }
