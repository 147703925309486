import * as yup from 'yup'

import { stringOfNumbersValidation } from '@/libs/validations/common'

type TProps = {
  excludeBuyFields: boolean
}

const buyTemplateValidationObject = {
  privateTransaction: yup.boolean().default(true),
  degenChadMode: yup.boolean().default(false),
  slippage: stringOfNumbersValidation.required().test((value) => +value <= 100),
  selectedWallets: yup.array().of(yup.string()).required().min(1),
}

const getOrdinaryBuyValidation = (availableTokens: number, options?: TProps) =>
  yup.object({
    ...buyTemplateValidationObject,
    ...(options?.excludeBuyFields
      ? null
      : {
          spend:
            import.meta.env.VITE_IS_TEST_INSTANCE === 'true'
              ? stringOfNumbersValidation.required()
              : stringOfNumbersValidation.required().test((value) => +value <= availableTokens),
          receive: stringOfNumbersValidation.required(),
        }),
  })

const advancedBuyValidation = yup.object({
  buyPriority: stringOfNumbersValidation,
  approvePriority: stringOfNumbersValidation,
  minPercentTokenOrFail: yup
    .string()
    .when('maxTxOrFail', ([maxTxOrFail], schema) => (maxTxOrFail ? schema.optional() : schema)),
  maxTxOrFail: yup.boolean().default(false),
})

const shieldsValidation = yup.object({
  buy_tax: stringOfNumbersValidation.test((value) => (value ? +value <= 100 : true)),
  sell_tax: stringOfNumbersValidation.test((value) => (value ? +value <= 100 : true)),
  minimum_liquidity: stringOfNumbersValidation,
  maximum_liquidity: stringOfNumbersValidation,
  maximum_market_cap: stringOfNumbersValidation,
})

const getManualBuyValidationSchema = (availableTokens: number, options?: TProps) =>
  yup.object({
    ordinaryBuy: getOrdinaryBuyValidation(availableTokens, options),
    advancedBuy: advancedBuyValidation,
    shields: shieldsValidation,
  })

export {
  getOrdinaryBuyValidation,
  advancedBuyValidation,
  shieldsValidation,
  getManualBuyValidationSchema,
}
