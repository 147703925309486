import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { TProfitSellItem } from '@/api/profit-loss/types'
import styles from '@/components/holdings-table/styles.module.scss'
import { SellPopup } from '@/components/sell-popup'
import { ButtonIcon, Icon, TOnChangeVisible, TokenPlaceholder, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppDispatch } from '@/store'
import { fetchNewCurrentToken } from '@/store/slices/chain.slice'
import { fetchUserWalletsWithTokensForce } from '@/store/slices/user.slice'

type TProperty = {
  row: TProfitSellItem
  handleMarketSellAll: (tokenAddress: string) => Promise<void>
}

const Row: FC<TProperty> = ({ row, handleMarketSellAll }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [sellPopup, setSellPopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const handlePopUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSellPopup({ visible: true, anchorE: event.currentTarget })
  }

  const handleChangeCurrentToken = () => {
    dispatch(fetchNewCurrentToken(row.panel.token_address))
    dispatch(fetchUserWalletsWithTokensForce(row.panel.token_address))
    navigate(`${AppRoute.DASHBOARD}/${AppMode.LITE}?&token=${row.panel.token_address}`)
  }

  return (
    <TableRow className={styles.row} onClick={handleChangeCurrentToken}>
      <TableCell className={styles.cell}>
        <div className={styles.token}>
          {row.panel_data.tokenMetadata.logo ? (
            <img
              className={styles.tokenLogo}
              src={row.panel_data.tokenMetadata.logo}
              alt={row.panel_data.tokenMetadata.name + ' token'}
            />
          ) : (
            <TokenPlaceholder token={{} as any} />
          )}
          <div>
            <Typography variant="body2" className={cn(styles.cellText, styles.name)}>
              {row.panel_data.tokenMetadata.name || 'Token'}
            </Typography>
            <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
              {row.panel_data.tokenMetadata.symbol}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          <Icon name={IconName.ETH_CURRENCY} />
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            {formatNumber(row.panel_data.boughtAmountEth).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          <Icon name={IconName.ETH_CURRENCY} />
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            {formatNumber(+row.panel_data.worth).formatted}
          </Typography>
        </div>
        <Typography variant="caption" className={styles.cellText}>
          {formatNumber(row.panel_data.currentTokens).formatted}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          <Icon name={IconName.ETH_CURRENCY} />
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            {formatNumber(row.panel_data.soldEth).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography
          variant="body2"
          textColor="color-white-3"
          className={cn(styles.cellText, {
            [styles.greenText]: row.panel_data.profitLoss > 0,
            [styles.redText]: row.panel_data.profitLoss < 0,
          })}
        >
          {row.panel_data.profitLoss > 0 && '+'}
          {formatNumber(row.panel_data.profitLoss).formatted}%
        </Typography>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.sell)}>
        <div className={styles.popupContainer}>
          <ButtonIcon onClick={handlePopUp}>
            <Icon name={IconName.SELL} />
            <Typography variant="body2" textColor="gold">
              Sell
            </Typography>
          </ButtonIcon>
          <SellPopup
            light
            onChangeVisible={setSellPopup}
            popoverState={sellPopup}
            tokenAddress={row.panel.token_address}
            onMarketSellAll={() => handleMarketSellAll(row.panel.token_address)}
          />
        </div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
