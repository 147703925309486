import { FC, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Layout } from '@/components/layout'
import { Spinner } from '@/components/spinner/spinner'
import { useAppDispatch } from '@/store'
import { setAppIdleState } from '@/store/slices/app.slice'

import { AuthController } from './libs/components/auth-controller'
import { DataWatcher } from './libs/components/data-watcher'
import { NavigationWatcher } from './libs/components/navigation-watcher'

const App: FC = () => {
  const [isAppLoaded, setIsAppLoaded] = useState(false)

  const dispatch = useAppDispatch()

  useIdleTimer({
    onIdle: () => {
      dispatch(setAppIdleState(true))
    },
    onActive: () => {
      dispatch(setAppIdleState(false))
    },
    onAction: () => {
      return
    },
    // Idle timeot in ms
    timeout: 120_000,
    throttle: 500,
  })

  return (
    <Router>
      {isAppLoaded && <Layout />}
      {!isAppLoaded && <Spinner absoluteCentered />}
      <ToastContainer theme="dark" />
      <AuthController setIsAppLoaded={setIsAppLoaded} />
      <DataWatcher />
      <NavigationWatcher />
    </Router>
  )
}

export { App }
