import { FC, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Stack, { StackProps } from '@mui/material/Stack'
import cn from 'classnames'

import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { PositionSoldCard } from '@/components/profit-sell-tab/libs/components'
import stylesCardCommon from '@/components/profit-sell-tab/libs/components/styles-cards.module.scss'
import { Spinner } from '@/components/spinner'
import { Button, Checkbox, type TCheckboxProps, Typography } from '@/libs/common'
import { PositionCardVariant, SpinnerSize } from '@/libs/enums'
import { formatNumber, getPriceImpactColor } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { PositionDTO, ValueOf } from '@/libs/types/index'

import styles from './styles.module.scss'

type TPositionCardProps = StackProps & {
  positionData: PositionDTO
  checkboxProps?: TCheckboxProps
  variant: ValueOf<typeof PositionCardVariant>
  message: React.ReactElement
  isSold?: boolean
  isMarketSellLoading?: boolean
  onMarketSellAll?: () => void
  onSellInitial?: () => void
}

const soldTokenData = {
  initialValue: '12.580000 PEPE',
  soldValue: '2.344546 ETH',
  percent: '434.34',
}

const PositionCard: FC<TPositionCardProps> = ({
  className,
  message,
  variant,
  positionData,
  checkboxProps,
  isSold,
  isMarketSellLoading,
  onMarketSellAll,
  onSellInitial,
  ...props
}) => {
  // const [isActiveCard, setIsActiveCard] = useState(positionData.isActive)
  // const [openSettings, setOpenSettings] = useState(false)
  const [soldMessage, setSoldMessage] = useState(isSold)
  const [isSellAll, setIsSellAll] = useState(false)
  const [isSellInitial, setIsSellInitial] = useState(false)

  // const navigate = useNavigate()

  useEffect(() => {
    setSoldMessage(isSold)
  }, [isSold])

  // const handleSellAll = () => {
  //   setIsSellAll(!isSellAll)
  // }

  const handleSellInitial = () => {
    if (onSellInitial) {
      onSellInitial()
    }
  }

  // const handleOpenSettings = () => {
  //   setOpenSettings(!openSettings)
  // }

  // const handleActive = (_: BaseSyntheticEvent, newValue: boolean) => {
  //   // sending request to pause all positins related to this card
  //   setIsActiveCard(newValue)
  // }
  const handleMarketSellAll = () => {
    if (onMarketSellAll) {
      onMarketSellAll()
    }
  }

  const handleCloseSellMessage = () => {
    if (isSellAll) {
      setIsSellAll(false)
    } else if (isSellInitial) {
      setIsSellInitial(false)
    }
  }

  // const handleOpenEditPosition = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}/${positionData.id}`)
  // }

  // const handleOpenShareProfitModal = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
  //     state: { positionsSummary: positionData },
  //   })
  // }

  return (
    <>
      {!isSellAll && !isSellInitial && (
        <Stack
          className={cn(
            stylesCardCommon.card,
            styles.positionCard,
            {
              [styles.border]: checkboxProps?.checked,
              // [stylesCardCommon.inactiveCard]: !isActiveCard,
              [styles.sold]: soldMessage,
            },
            className,
          )}
          {...props}
        >
          {soldMessage ? (
            message
          ) : (
            <Grid container rowGap={2} direction="column">
              {/* header row */}
              {variant === PositionCardVariant.PROFIT && (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="space-between"
                  className={stylesCardCommon.rowContainer}
                >
                  <Grid container className={stylesCardCommon.rowContainerItem}>
                    <Typography variant="body2">
                      Position #{hideWalletAddress(positionData.id)}
                    </Typography>
                  </Grid>
                  {/* <Grid container className={stylesCardCommon.rowContainerItem}>  
                    <Typography variant="body2">Position active</Typography>
                    <Switch checked={isActiveCard} onChange={handleActive} />
                  </Grid> */}
                  <Grid container gap={1} className={stylesCardCommon.headerButtonsGroup}>
                    {/* <Button
                      className={stylesCardCommon.headerButton}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.SHARE}
                      transparent
                      onClick={handleOpenShareProfitModal}
                    >
                      Share
                    </Button> */}
                    {/* <Button
                      className={stylesCardCommon.headerButton}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.EDIT}
                      transparent
                      onClick={handleOpenEditPosition}
                      disabled
                    >
                      Edit position
                    </Button> */}
                  </Grid>
                </Grid>
              )}
              {/* middle rows */}
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                direction="column"
                className={stylesCardCommon.rowContainer}
                gap={1}
              >
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="space-between"
                  className={stylesCardCommon.rowContainer}
                >
                  <Grid container gap={1}>
                    <Checkbox {...checkboxProps} />
                    <VerticalGroup
                      title={hideWalletAddress(positionData.id) || ''}
                      description={
                        <>
                          {formatNumber(positionData.totalBalance).formatted} {positionData.coin}
                        </>
                      }
                    />
                  </Grid>
                  <Grid container className={stylesCardCommon.middleButtonsGroup}>
                    {variant === PositionCardVariant.PROFIT && (
                      <>
                        <Button
                          styleVariant="black"
                          className={cn(stylesCardCommon.middleRowButton, styles.buttonSellAll)}
                          transparent
                          onClick={handleMarketSellAll}
                          isLoading={isMarketSellLoading}
                        >
                          Market sell
                        </Button>
                        <Button
                          styleVariant="black"
                          className={cn(stylesCardCommon.middleRowButton, styles.buttonSellInitial)}
                          transparent
                          onClick={handleSellInitial}
                          isLoading={isMarketSellLoading}
                        >
                          Sell initial
                        </Button>
                      </>
                    )}
                    {variant === PositionCardVariant.SELL && (
                      <Button
                        styleVariant="black"
                        className={cn(stylesCardCommon.middleRowButton, styles.fixedWidth)}
                        transparent
                        onClick={handleMarketSellAll}
                        disabled={isMarketSellLoading}
                      >
                        {isMarketSellLoading ? (
                          <Spinner size={SpinnerSize.SMALL} />
                        ) : (
                          'Market sell all'
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {/* <Grid container>
                  <Button
                    className={stylesCardCommon.transferButton}
                    styleVariant="borderless-gold"
                    iconStartName={IconName.WITHDRAW}
                    transparent
                    disabled
                  >
                    Transfer to backup wallets
                  </Button>
                </Grid> */}
              </Grid>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                // justifyContent="space-between"
                className={cn(stylesCardCommon.rowContainer, styles.infoContainer)}
              >
                {(positionData.profitOrLoss || positionData.profitOrLoss == 0) && (
                  <VerticalGroup
                    title={'Profit/Loss'}
                    description={
                      <span
                        className={cn(
                          positionData.profitOrLoss > 0
                            ? stylesCardCommon.isProfit
                            : stylesCardCommon.isLoss,
                        )}
                      >
                        {'%'}
                        {formatNumber(Math.abs(positionData.profitOrLoss)).formatted}
                      </span>
                    }
                  />
                )}
                <VerticalGroup
                  title={'Cost'}
                  description={
                    <>
                      {formatTokenPrice(positionData.cost).formatted}{' '}
                      {positionData.coinCompairedWith}
                    </>
                  }
                />
                <VerticalGroup
                  title={'Worth'}
                  descClassName={positionData.worth > +positionData.cost ? styles.greenText : ''}
                  description={
                    <>
                      {formatNumber(positionData.worth).formatted} {positionData.coinCompairedWith}
                    </>
                  }
                />
                {(positionData.afterTax || positionData.afterTax == 0) && (
                  <VerticalGroup
                    title={'After Tax'}
                    descClassName={
                      positionData.afterTax > +positionData.cost ? styles.greenText : ''
                    }
                    description={
                      <>
                        {formatTokenPrice(`${positionData.afterTax}`).formatted}{' '}
                        {positionData.coinCompairedWith}
                      </>
                    }
                  />
                )}
                {(positionData.soldEth || positionData.soldEth == 0) && (
                  <VerticalGroup
                    title={'Sold'}
                    description={<>{formatNumber(positionData.soldEth).formatted} ETH</>}
                  />
                )}
                {(positionData.priceImpact || positionData.priceImpact == 0) && (
                  <VerticalGroup
                    title={'Price impact'}
                    descClassName={getPriceImpactColor(positionData.priceImpact * 100)}
                    description={<>{formatNumber(positionData.priceImpact * 100).formatted}%</>}
                  />
                )}
              </Grid>
              {/* footer row */}
              {/* onClick={handleOpenSettings} */}
              {variant === PositionCardVariant.PROFIT && (
                <>
                  {/* <Button
                    borderless
                    transparent
                    contentMaxWidth
                    disableRipple
                    onClick={handleOpenSettings}
                    className={styles.settingsButton}
                  >
                    <Grid
                      container
                      wrap="nowrap"
                      alignItems="center"
                      justifyContent="center"
                      className={cn(stylesCardCommon.rowContainer, styles.toggleSettings)}
                      gap={2}
                    >
                      <Typography variant="body2" textColor="light-grey">
                        Position Settings
                      </Typography>
                      <div className={styles.divider}></div>
                      <div className={cn({ [styles.iconExpanded]: openSettings })}>
                        <Icon name={IconName.EXPAND_MORE} />
                      </div>
                    </Grid>
                  </Button>
                  {!!positionData.settings && (
                    <div>
                      <Collapse in={openSettings} timeout="auto" unmountOnExit>
                        <PositionSettings
                          positionSettings={positionData.settings}
                          isActive={isActiveCard}
                        />
                      </Collapse>
                    </div>
                  )} */}
                </>
              )}
            </Grid>
          )}
        </Stack>
      )}

      {(isSellAll || isSellInitial) && (
        <PositionSoldCard
          isSellAll={isSellAll}
          isSellInitial={isSellInitial}
          soldTokenData={soldTokenData}
          handleCloseSellMessage={handleCloseSellMessage}
          setIsSellInitial={setIsSellInitial}
          tokenInfo={positionData}
        />
      )}
    </>
  )
}

export { PositionCard, type TPositionCardProps }
