import { MouseEvent, useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton, Tabs, Typography } from '@/libs/common'
import { chainsConfig } from '@/libs/configs/chains.config'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { AddTemplate } from './libs/components/add-template'
import { Templates } from './libs/components/temlates'
import styles from './styles.module.scss'

// TODO: Enable all the chains when the feature is ready
const chainTemplatesDefault = [chainsConfig[0]].map((config) => ({
  ...config,
  templatesCount: 0,
}))

const chainsCountDefault: Record<number, number> = {}
chainsConfig.forEach((config) => {
  ;(chainsCountDefault as any)[config.indexerChainId] = 0
})

const TemplateManagement = () => {
  const buyTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)

  const [chainTemplates, setChainTemplates] = useState(chainTemplatesDefault)
  const [template, setTemplate] = useState(chainTemplatesDefault[0].indexerChainId)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Template Management' })
  }, [])

  useEffect(() => {
    // TODO: Handle other tamplates
    if (!buyTemplates) {
      setChainTemplates(chainTemplatesDefault)
      return
    }
    const chainsCount = { ...chainsCountDefault }

    buyTemplates.forEach((item) => {
      chainsCount[item.setup.network.blockchain] += 1
    })

    setChainTemplates((prevValue) => {
      const newValue: typeof prevValue = []

      prevValue.forEach((item) => {
        newValue.push({
          ...item,
          templatesCount: chainsCount[item.indexerChainId],
        })
      })

      return newValue
    })
  }, [buyTemplates])

  const handleAlignment = (_: MouseEvent<HTMLElement>, newAlignment: number) => {
    if (newAlignment !== null) {
      setTemplate(newAlignment)
    }
  }

  const tabs = [
    {
      label: 'Templates',
      content: <Templates selectedChainId={template} />,
    },
    {
      label: 'Add new template',
      content: <AddTemplate />,
    },
  ]

  return (
    <div className={styles.container}>
      <Grid className={styles.wrapper}>
        <ButtonGroupRadio
          className={styles.group}
          value={template}
          onChange={handleAlignment}
          exclusive
        >
          {chainTemplates.map((item, index) => (
            <ButtonGroupRadioButton
              key={item.indexerChainId}
              value={item.indexerChainId}
              className={cn(styles.button, {
                [styles.borderLeft]: index === 4,
                [styles.borderTopRight]: index === 3,
                [styles.active]: template === item.indexerChainId,
              })}
            >
              {item.label}
              <span className={styles.value}>({item.templatesCount})</span>
            </ButtonGroupRadioButton>
          ))}
        </ButtonGroupRadio>

        <Tabs tabs={tabs} className={styles.scroll} />
      </Grid>
    </div>
  )
}

export { TemplateManagement }
