import { FC, ReactNode } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DefaultAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import cls from 'classnames'

import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ButtonIcon } from '@/libs/common/button-icon'
import { ButtonSort } from '@/libs/common/button-sort'
import { RefreshButton } from '@/libs/common/refresh-button'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { ESorting } from '@/libs/enums/sorting.enum'

import styles from './styles.module.scss'

type TProperty = {
  title: string | ReactNode
  className?: string
  children?: React.ReactNode
  enableSettings?: boolean
  enableSorting?: boolean
  sorting?: ESorting
  enableRefreshing?: boolean
  titleClassName?: string
  withDivider?: boolean
  mainColorArrow?: boolean
  value?: string
  isHorizontal?: boolean
  expanded?: boolean
  refreshLoading?: boolean
  onRefreshingClick?: () => void
  onSortingClick?: () => void
  onSettingClick?: () => void
  onChange?: (expanded: boolean) => void
  disabled?: boolean
  isComingSoon?: boolean
  contentWithScroll?: 'default' | 'templates' | 'wallets' | 'favorites' | 'holdings'
}

const Accordion: FC<TProperty> = ({
  title,
  children,
  enableSettings,
  enableSorting,
  sorting,
  onSortingClick,
  enableRefreshing,
  refreshLoading,
  className,
  titleClassName,
  withDivider,
  mainColorArrow,
  value,
  isHorizontal,
  onChange,
  onSettingClick,
  onRefreshingClick,
  disabled,
  isComingSoon,
  expanded,
  contentWithScroll,
}) => {
  const handleClick = (callback?: () => void) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (callback) {
      callback()
    }
  }

  const handleSettingClick = handleClick(onSettingClick)
  const handleRefreshingClick = handleClick(onRefreshingClick)
  const handleSortingClick = handleClick(onSortingClick)

  return (
    <DefaultAccordion
      onChange={(_, expandedOnChange: boolean) => onChange?.(expandedOnChange)}
      className={cls(styles.container, className)}
      square={true}
      disableGutters={true}
      slotProps={{
        transition: {
          unmountOnExit: true,
          mountOnEnter: true,
        },
      }}
      disabled={disabled}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={cls(styles.arrow, {
              [styles.mainColorArrow]: mainColorArrow,
              [styles.rotate]: isHorizontal,
            })}
          />
        }
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': isHorizontal
            ? {
                transform: 'rotate(-90deg)',
                color: 'black',
              }
            : {},
        }}
        className={styles.header}
        classes={{ content: styles.root }}
      >
        <Typography
          component={'span'}
          variant="body1"
          className={cls(styles.title, titleClassName, { [styles.grey]: isHorizontal })}
        >
          {title}
          {withDivider && <div className={styles.divider} />}
          <div className={styles.buttonsWrapper}>
            {enableSettings && <ButtonIcon icon={IconName.SETTING} onClick={handleSettingClick} />}
            {value && <div className={cls(styles.value, styles.textColor)}>{value}</div>}
            {enableRefreshing && (
              <RefreshButton onClick={handleRefreshingClick} isLoading={refreshLoading} />
            )}
            {/* {enableSorting && <ButtonIcon icon={IconName.FILTER} onClick={handleSortingClick} />} */}
            {enableSorting && !!sorting && (
              <ButtonSort sorting={sorting} handleSorting={handleSortingClick} />
            )}
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={cls(styles.content, {
          [styles[`scrollForLists-${contentWithScroll}`]]: contentWithScroll,
        })}
      >
        {isComingSoon && <ComingSoonOverlay top={56} />}
        {children}
      </AccordionDetails>
    </DefaultAccordion>
  )
}

export { Accordion, type TProperty as TAccordionProps }
