import { useNavigate } from 'react-router-dom'

import { Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const tabs = [
  {
    label: 'Buy',
    icon: <Icon name={IconName.SHOPPING_CART_LARGE} />,
    navigate: `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.BUY_MODAL}`,
  },
  {
    label: 'Sell',
    icon: <Icon name={IconName.BOTTOM_TABS_SELL} />,
    navigate: `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.SELL_MODAL}`,
  },
  {
    label: 'Profit/Sell panel',
    icon: <Icon name={IconName.BOTTOM_TABS_PROFIT} />,
    navigate: `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.PROFIT_MODAL}`,
  },
]

const BottomTabs = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      {tabs.map((item) => (
        <div
          key={item.label}
          className={styles.itemWrapper}
          onClick={() => navigate(item.navigate)}
        >
          <div className={styles.iconWrapper}>{item.icon}</div>
          <Typography className={styles.label}>{item.label}</Typography>
        </div>
      ))}
    </div>
  )
}

export { BottomTabs }
