import { FC, useRef, useState } from 'react'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'

import { type Identifier, type XYCoord } from 'dnd-core'

import { TDraggableItemId } from '@/libs/types/draggable.type'

type TOnDragProps = {
  state: boolean
  id: TDraggableItemId
  index: number
}

type TDragItem = {
  index: number
}

type TProps = {
  id: TDraggableItemId
  index: number
  moveItem: (dragIndex: number, hoverIndex: number) => void
  renderItem: (isDragging: boolean) => React.JSX.Element
  onDragStateChange?: (value: TOnDragProps) => void
}

// Consider having multiple DnD types
enum EItemTypes {
  CARD = 'CARD',
}

const DragAndDropListItem: FC<TProps> = ({
  id,
  index,
  moveItem,
  renderItem,
  onDragStateChange,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [localIsDragging, setLocalIsDragging] = useState(false)

  const [{ handlerId }, drop] = useDrop<TDragItem, void, { handlerId: Identifier | null }>({
    accept: EItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: TDragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: EItemTypes.CARD,
    item: () => {
      return { index }
    },
    collect: (monitor: DragSourceMonitor<TDragItem, unknown>) => {
      const currentIsDragging = monitor.isDragging()
      if (localIsDragging !== currentIsDragging) {
        setLocalIsDragging(currentIsDragging)
        onDragStateChange?.({ state: currentIsDragging, id, index })
      }
      return {
        isDragging: currentIsDragging,
      }
    },
  })

  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0 : 1 }} data-handler-id={handlerId}>
      {renderItem(isDragging)}
    </div>
  )
}

export { DragAndDropListItem }
