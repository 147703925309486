import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { SelectFees } from '@/components/select-fees'
import { SelectWallet } from '@/components/select-wallet'
import { useAppMode } from '@/hooks/useAppMode'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { BackButton, Button, InputWithRadioGroup, Typography } from '@/libs/common'
import { AmountDropdown } from '@/libs/common/input/components/amount-dropdown'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TFees } from '@/libs/types'
import { TSelectOption } from '@/libs/types/select-option.type'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.05,
    label: '0.05',
  },
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
]

const defaultValues = {
  amount: '',
  wallets: [],
}

const DisperseMulti: FC = () => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const currentWallet = useWalletFromParams()

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const [fees, setFees] = useState<TFees>()
  const [wallets, setWallets] = useState<string[]>([])
  const [isDisabled, setIsDisable] = useState(true)
  const [holding, setHolding] = useState<THoldingsToken | null>(null)

  const walletBalance = useMemo(() => {
    let balance = +(currentWallet?.balanceFormatted || 0)
    if (holding) {
      const holdingWallet = holding.w.find(
        (item) => item.a.toLowerCase() === currentWallet?.public_key.toLowerCase(),
      )
      balance = holdingWallet?.balanceFormatted || 0
    }
    return balance
  }, [holding])

  const schema = yup.object({
    amount: stringOfNumbersValidation.required().test((value) => +value > 0),
    address: yup.string(),
    wallets: yup.array().of(yup.string()).required().min(1),
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const amount = watch('amount')

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({
      title: `Disperse multi from ${currentWallet?.name}`,
      withBackButton: true,
      titleProps: { className: styles.title },
    })
  }, [currentWallet])

  useEffect(() => {
    const isValidAmount = +amount * (wallets.length || 1) <= walletBalance
    setIsDisable(!isValidAmount || !fees || !isValid || !fees.minerTip)
  }, [fees, isValid, wallets, amount])

  useEffect(() => {
    if (amount) trigger('amount')
  }, [walletBalance])

  const calculateWorth = (amount: number) => {
    return holding && holding.balanceFormatted
      ? amount * (+holding.tvu / +holding.balanceFormatted || 0)
      : amount * (currentChain?.nativeTokenPriceInUsd || 0)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!fees) return

    const payload = {
      type: 'disperse',
      token: holding || null,
      amount: {
        value: data.amount,
        valueUSD: calculateWorth(+data.amount),
      },
      wallets: data.wallets,
      minerTip: fees.minerTip,
    }
    navigate(
      `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.CONFIRM_TRANSFER}/${currentWallet?.public_key}`,
      {
        state: { payload },
      },
    )
  }

  return (
    <div className={styles.container}>
      <Typography variant="body2" align="center">
        ~ {walletBalance && formatNumber(walletBalance).formatted} {holding ? holding.s : 'ETH'} ($
        {(+(holding?.tvu || '0')).toFixed(2)})
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <Controller
              name="amount"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <InputWithRadioGroup
                    label="Amount"
                    placeholder="Enter amount to transfer"
                    isNumeric
                    className={styles.input}
                    onOptionSelect={(value) => {
                      onChange(value)
                      trigger('amount')
                    }}
                    endAdornment={
                      <AmountDropdown
                        wallet={currentWallet}
                        onTokenSelect={(value) => {
                          setHolding(value)
                        }}
                      />
                    }
                    radioGroupName="amount"
                    options={spendOptions}
                    onChange={(e) => {
                      if (!currentWallet || !currentChain.nativeTokenPriceInUsd) return
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }}
                    error={!!errors.amount?.message}
                    {...field}
                  />
                )
              }}
            />
            <Typography variant="body2">${calculateWorth(+amount).toFixed(2)}</Typography>
          </div>
          <Controller
            name="wallets"
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <SelectWallet
                wallet={wallets}
                wallets={userWallets?.filter((el) => el.address !== currentWallet?.address)}
                onChange={(value) => {
                  setWallets(value)
                  onChange(value)
                }}
                isMulti={true}
                {...field}
              />
            )}
          />
          <SelectFees fees={fees} onChange={(value) => setFees(value)} />
        </div>
        <Button type="submit" disabled={isDisabled} className={styles.button} checkOnAccountLock>
          Continue
        </Button>
      </form>
    </div>
  )
}

export { DisperseMulti }
