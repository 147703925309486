import { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import { getDeviceCodeInfo, updateDeviceCodeStatus } from '@/api/device'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { Button, Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  device: {
    id: string
    name: string
    type: string
  }
}

export const AddDevice = () => {
  const navigate = useNavigate()
  const [confirmed, setConfirmation] = useState(false)
  const [device, setDeviceInfo] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const checkUserAndExecute = useCheckUser()

  useEffect(() => {
    localStorage.code = searchParams.get('code')
    checkUserAndExecute(async () => {
      localStorage.removeItem('code')
      const code = searchParams.get('code')
      try {
        const { data } = await getDeviceCodeInfo(code as string)
        setDeviceInfo(data.data)
      } catch (e) {
        CustomToast('error', `Invalid code`)
        navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)
      }
    })
  }, [])

  const addDevice = async () => {
    try {
      await updateDeviceCodeStatus({
        id: searchParams.get('code'),
        status: 'approved',
      })
      setConfirmation(true)
    } catch (e) {
      CustomToast('error', `Something went wrong!`)
    }
  }
  return (
    <>
      {confirmed ? (
        <AuthCard className={styles.card} isHeaderButtons>
          <div className={styles.content}>
            <Stack spacing={1} useFlexGap>
              <Grid container justifyContent="center">
                <Icon name={IconName.SUCCESS} />
              </Grid>
              <Typography variant="h1" align="center">
                Success
              </Typography>
              <Typography variant="body1" align="center">
                Your have successfully linked the following device:{' '}
              </Typography>
            </Stack>
            <DeviceInfo device={device} />

            <Button onClick={() => navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)}>
              Continue to dashboard
            </Button>
          </div>
        </AuthCard>
      ) : (
        <AuthCard
          className={styles.card}
          isHeaderButtons
          footerLink={{ title: 'Go to dashboard', link: `${AppRoute.DASHBOARD}/${AppMode.PRO}` }}
        >
          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <Typography variant="h1" className={styles.title} align="center">
                Please confirm, that you <br /> want to attach this device
              </Typography>
            </div>
            <DeviceInfo device={device} />

            <Button onClick={addDevice}>Confirm</Button>
            <Button
              styleVariant="black"
              onClick={() => navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)}
            >
              Deny
            </Button>
          </div>
        </AuthCard>
      )}
    </>
  )
}

export const DeviceInfo: FC<TProps> = ({ device }) => {
  return (
    <div className={styles.deviceContainer}>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device Type</div>
        <div className={styles.deviceValue}>{device?.type}</div>
      </div>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device Name</div>
        <div className={styles.deviceValue}>{device?.name}</div>
      </div>
      <div className={styles.deviceCol}>
        <div className={styles.deviceTitle}>Device ID</div>
        <div className={styles.deviceValue}>{device?.id}</div>
      </div>
    </div>
  )
}
