const getFontSize = (componentWidth: number) => {
  let fontSize

  switch (true) {
    case componentWidth < 600:
      fontSize = 12
      break
    case componentWidth > 1300 && componentWidth < 1600:
      fontSize = 13
      break
    case componentWidth > 1600:
      fontSize = 15
      break
    default:
      fontSize = 15
  }

  return fontSize
}

export { getFontSize }
