import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import QRCode from 'qrcode'

import logoText from '@/assets/img/logo/logo-text.svg'
import logo from '@/assets/img/logo/logo.svg'
import { PositionSummaryDTO } from '@/libs/types'
import { CanvasHandle } from '@/libs/types/canvas.type'
import { drawGradientText } from '@/pages/modal-page/libs/helpers'

import styles from './styles.module.scss'

type TProps = {
  height: number
  width: number
  img: string
  isPositionOpen: boolean
  tokenInfo: PositionSummaryDTO
}

const Canvas = forwardRef<CanvasHandle, TProps>(
  ({ height, width, img, isPositionOpen, tokenInfo }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    useImperativeHandle(ref, () => ({
      getCanvas: () => canvasRef.current,
    }))

    const loadImage = (src: string): Promise<HTMLImageElement> =>
      new Promise((resolve) => {
        const image = new Image()
        image.src = src
        image.onload = () => resolve(image)
      })

    useEffect(() => {
      const context = canvasRef.current?.getContext('2d')
      const image = new Image()

      image.src = img
      image.onload = async () => {
        if (!context) {
          return
        }

        context.drawImage(image, 0, 0, width, height)
        context.fillStyle = '#ffffff'
        context.font = '600 23px Space Grotesk'
        context?.fillText(tokenInfo.coin, 44, isPositionOpen ? 130 : 170)

        context.font = '300 23px Space Grotesk'
        context?.fillText(`($${tokenInfo.coin})`, 104, isPositionOpen ? 130 : 170)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('Holding', 44, isPositionOpen ? 155 : 215)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('0.026 PEPE', 44, isPositionOpen ? 173 : 233)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('0.054 ETH ($62.89)', 44, isPositionOpen ? 187 : 247)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('Total Spent', 200, isPositionOpen ? 155 : 215)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('0.026 ETH', 200, isPositionOpen ? 173 : 233)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('$62.89', 200, isPositionOpen ? 187 : 247)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('Total revenue', 344, isPositionOpen ? 155 : 215)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('0.026 ETH', 344, isPositionOpen ? 173 : 233)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('$62.89', 344, isPositionOpen ? 187 : 247)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('Realized P/L', 44, isPositionOpen ? 220 : 280)

        context.font = '500 34px Space Grotesk'
        drawGradientText('+20,003.23%', 44, isPositionOpen ? 252 : 312, context)

        context.font = '500 20px Space Grotesk'
        drawGradientText('(+0.501 ETH)', 266, isPositionOpen ? 252 : 312, context)

        if (isPositionOpen) {
          context.fillStyle = '#ffffff'
          context.font = '500 14px Space Grotesk'
          context?.fillText('Potential P/L', 44, 280)

          context.font = '500 34px Space Grotesk'
          drawGradientText('+20,003.23%', 44, 312, context)

          context.font = '500 20px Space Grotesk'
          drawGradientText('(+0.501 ETH)', 266, 312, context)
        }

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('Trade by', 44, 350)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('Slice', 44, 370)

        context.fillStyle = '#BDBDBD'
        context.font = '400 12px Space Grotesk'
        context?.fillText('Time Stamp', 200, 350)

        context.fillStyle = '#ffffff'
        context.font = '500 14px Space Grotesk'
        context?.fillText('2024-01-24', 200, 370)

        const image2 = new Image()
        image2.src = logo
        image2.onload = () => {
          const newWidthImage2 = 50
          const newHeightImage2 = image2.height * (newWidthImage2 / image2.width)
          context.drawImage(image2, 44, isPositionOpen ? 27 : 57, newWidthImage2, newHeightImage2)
        }

        const image3 = new Image()
        image3.src = logoText
        image3.onload = () => {
          const newWidthImage3 = 130
          const newHeightImage3 = image3.height * (newWidthImage3 / image3.width)
          context.drawImage(image3, 107, isPositionOpen ? 40 : 67, newWidthImage3, newHeightImage3)
        }

        //@ TODO add url
        const imageUrl = 'https://example.com/path/to/your/image.jpg'
        const qrCodeDataURL = await QRCode.toDataURL(imageUrl)
        const qrCodeImage = await loadImage(qrCodeDataURL)
        context.drawImage(qrCodeImage, width - 200, height - 160, 130, 130)
      }
    }, [height, img, width, isPositionOpen, tokenInfo])

    return <canvas ref={canvasRef} width={width} height={height} className={styles.canvas}></canvas>
  },
)

Canvas.displayName = 'Canvas'

export { Canvas }
