import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import styles from '@/components/pending-orders/libs/components/order-history-table/styles.module.scss'
import { ChipToOrderStatus } from '@/components/pending-orders/libs/maps/chip-to-order-status'
import rowStyles from '@/components/pending-orders/row-styles.module.scss'
import { Chip, Icon, TokenRedirect, Typography } from '@/libs/common'
import { IconWithTooltip } from '@/libs/common/icon-with-tooltip'
import { IconName } from '@/libs/enums'
import { calculateETHAmountByHash, formatNumber } from '@/libs/helper'
import { TOrderHistory } from '@/libs/types'

type TProps = {
  row: TOrderHistory
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  const [open, setOpen] = useState(false)
  const date = moment(row.date).format('YYYY-MM-DDTHH:mm')

  return (
    row.hash[0] && (
      <>
        <TableRow
          className={cn(rowStyles.tableRow, {
            [rowStyles.backgroundEven]: index % 2 !== 0,
            [rowStyles.backgroundOdd]: index % 2 === 0,
          })}
        >
          <TableCell className={cn(rowStyles.cell, rowStyles.rowCell)}>
            {row.wallets.length !== 1 && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUpIcon className={rowStyles.icon} />
                ) : (
                  <KeyboardArrowDownIcon className={rowStyles.icon} />
                )}
              </IconButton>
            )}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.paddingZero, styles.wallet)}
            component="th"
            scope="row"
            align="center"
          >
            {row.wallets.length}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.paddingZero, styles.type)}
            component="th"
            scope="row"
            align="center"
          >
            {row.type === 'sell' && (
              <IconWithTooltip
                iconName={IconName.SELL2}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.SELL2} />
                    <Typography textColor="light-grey">Sell</Typography>
                  </div>
                }
              />
            )}
            {row.type === 'buy' && (
              <IconWithTooltip
                iconName={IconName.SHOPPING_CART}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.SHOPPING_CART} />
                    <Typography textColor="light-grey">Buy</Typography>
                  </div>
                }
              />
            )}
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.token)}>
            <TokenRedirect address={row.token}>{row.tokenName || 'Token'}</TokenRedirect>
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.amount)}>
            {row.hash[0].status && (
              <>{formatNumber(calculateETHAmountByHash(row.hash)).formatted} ETH</>
            )}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.rowPadding, styles.date)}
          >
            <Box display="flex" flexDirection="column">
              <div>{date.split('T')[0]}</div>
              <div>{date.split('T')[1]}</div>
            </Box>
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.status)}>
            {
              <Chip
                key={row._id}
                styleVariant={
                  ChipToOrderStatus[row.hash[0].status ? row.hash[0].status.status : 'pending']
                    .color
                }
                label={
                  ChipToOrderStatus[row.hash[0].status ? row.hash[0].status.status : 'pending']
                    .label
                }
              />
            }
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.tableButtonsRow)}>
            {row.wallets.length === 1 && (
              <Link to={`https://etherscan.io/tx/${row.hash[0].hash}`} target="_blank">
                <Icon name={IconName.ETHERSCAN} />
              </Link>
            )}
          </TableCell>
        </TableRow>

        <TableRow className={rowStyles.backgroundInner}>
          <TableCell
            className={cn(rowStyles.cell, rowStyles.cellWrapper)}
            sx={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1} width={'100%'}>
                <Table classes={rowStyles} size="small" aria-label="purchases">
                  <TableHead className={rowStyles.tableHead}></TableHead>

                  <TableBody>
                    {row.wallets.map((wallet, index) => {
                      const walletHash = row.hash.find((el) => el.public_key === wallet.public_key)
                      // const date = moment(dateFromObjectId(walletHash)).format('YYYY-MM-DDTHH:mm')
                      return (
                        <TableRow key={wallet.name + index}>
                          <TableCell
                            className={cn(rowStyles.cell, styles.innerWalletName)}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {wallet.name}
                          </TableCell>

                          <TableCell className={cn(rowStyles.cell)}>
                            {walletHash?.eth && <>{formatNumber(walletHash.eth).formatted} ETH</>}
                          </TableCell>
                          <TableCell
                            className={cn(
                              rowStyles.cell,

                              styles.date,
                            )}
                          >
                            <Box display="flex" flexDirection="column">
                              <div>{date.split('T')[0]}</div>
                              <div>{date.split('T')[1]}</div>
                            </Box>
                          </TableCell>
                          <TableCell className={cn(rowStyles.cell, styles.status)}>
                            {
                              <Chip
                                key={row._id}
                                styleVariant={
                                  ChipToOrderStatus[
                                    walletHash?.status ? walletHash.status.status : 'pending'
                                  ].color
                                }
                                label={
                                  ChipToOrderStatus[
                                    walletHash?.status ? walletHash.status.status : 'pending'
                                  ].label
                                }
                              />
                            }
                          </TableCell>

                          <TableCell className={cn(rowStyles.cell, rowStyles.etherscan)}>
                            {walletHash && (
                              <Link
                                to={`https://etherscan.io/tx/${walletHash.hash}`}
                                target="_blank"
                              >
                                <Icon name={IconName.ETHERSCAN} />
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  )
}

export { Row }
