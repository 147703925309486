import { FC } from 'react'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import { Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'

import styles from './styles.module.scss'

type TAddTokenButtonContentProps = {
  holding: THoldingsToken
}

const AddTokenButton: FC<TAddTokenButtonContentProps> = ({ holding }) => {
  return (
    <Stack spacing={1} useFlexGap>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container wrap="nowrap" gap={1.5} className={styles.fitContent}>
          {/* {holding.token.logo ? (
            <img
              className={styles.tokenLogo}
              src={holding.token.logo}
              alt={holding.token.name + ' token'}
            />
          ) : (
            <TokenPlaceholder token={{} as any} />
          )} */}
          <TokenPlaceholder token={{} as any} />

          <Grid container direction="column">
            <Typography variant="body2" textColor="light-grey" className={styles.tokenName}>
              {holding.s}
            </Typography>
            <Typography variant="body2" className={styles.tokenName}>
              {holding.n}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="flex-end" className={styles.fitContent}>
          <Typography variant="body2" textColor="light-grey">
            {formatTokenPrice(`${holding.tb}`).formatted} {holding.s}
          </Typography>
          <Typography variant="body2">${formatTokenPrice(`${holding.tvu}`).formatted}</Typography>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" gap={1}>
        <Icon name={IconName.CHECK} />
        <Typography variant="body2">{holding.a}</Typography>
      </Grid>
    </Stack>
  )
}

export { AddTokenButton }
