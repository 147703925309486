import { Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { ButtonIcon, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

interface Column {
  id: 'signedIn' | 'browser' | 'ipAddress' | 'location' | 'current' | 'actionButtons'
  label: string
  minWidth?: number
  align?: 'right' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'signedIn', label: 'Signed in', minWidth: 230 },
  {
    id: 'browser',
    label: 'Browser',
    minWidth: 200,
  },
  { id: 'ipAddress', label: 'IP Address', minWidth: 190 },
  {
    id: 'location',
    label: 'Location',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'current',
    label: 'Current',
    minWidth: 170,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'actionButtons',
    label: '',
    minWidth: 40,
    format: (value: number) => value.toLocaleString('en-US'),
  },
]

interface Data {
  signedIn: string
  browser: string
  ipAddress: string
  location: string
  current: boolean
  actionButtons: string
}

function createData(
  signedIn: string,
  browser: string,
  ipAddress: string,
  location: string,
  current: boolean,
  actionButtons: string,
): Data {
  return { signedIn, browser, ipAddress, location, current, actionButtons }
}

const rows = [
  createData('about 1 hour ago', 'Chrome (Windows)', '202.124.54.144', 'Lithuania', true, ''),
  createData('3 months ago', 'Unknown', '212.222.54.144', 'Lithuania', false, ''),
  createData('3 months ago', 'Unknown', '202.124.54.144', 'Lithuania', false, ''),
]

const ActiveSessionTable = () => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className={styles.container}>
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={cn(styles.tableHeadCell, {
                    [styles.tableHeadCellWidth]: column.id === 'actionButtons',
                  })}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {rows.map((row, indx) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.signedIn + indx}
                  className={cn(styles.bodyRow, { [styles.background]: indx % 2 !== 0 })}
                >
                  {columns.map((column) => {
                    const value = row[column.id]

                    return (
                      <TableCell key={column.id} align={column.align} className={cn(styles.cell)}>
                        {column.id === 'actionButtons' ? (
                          <Grid
                            container
                            alignItems="center"
                            gap={0.35}
                            justifyContent="center"
                            width="25px"
                          >
                            <ButtonIcon tooltipTitle="Close">
                              <Icon name={IconName.CLOSE_GREY} />
                            </ButtonIcon>
                          </Grid>
                        ) : typeof value !== 'boolean' ? (
                          value
                        ) : value ? (
                          <Icon name={IconName.CHECK} />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { ActiveSessionTable }
