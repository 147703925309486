import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Divider } from '@mui/material'
import cls from 'classnames'

import styles from '@/components/dashboard-component-lite/styles.module.scss'
import { ButtonCopy, Icon, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { ITokenInfo } from '@/libs/types/token-info-response.type'
import { useAppSelector } from '@/store'

type TProperty = {
  selectedToken: ITokenInfo | null
}

const TokenCard: FC<TProperty> = ({ selectedToken }) => {
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const isDataAvailable = !!simulation
  return (
    <div className={cls(styles.block, styles.tokenCard)}>
      <div className={styles.header}>
        <div>
          <div className={styles.tokenName}>
            <Typography variant="body1" textColor="light-grey">
              {selectedToken?.token.name}
            </Typography>
            {selectedToken && <ButtonCopy value={selectedToken.token.address} />}
          </div>
          <Typography>{simulation?.l.n}</Typography>
        </div>

        <div className={styles.asideWrapper}>
          <div className={styles.aside}>
            <Link
              to={selectedToken?.socialLinks.website || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks.website,
              })}
            >
              <Icon name={IconName.LANGUAGE} />
            </Link>

            <Link
              to={selectedToken?.socialLinks.telegram || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks.telegram,
              })}
            >
              <Icon name={IconName.EXCLUDE} />
            </Link>

            <Link
              to={selectedToken?.socialLinks.twitter || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks.twitter,
              })}
            >
              <Icon name={IconName.TWITTER} />
            </Link>
          </div>

          <div className={styles.searchLink}>
            <Icon name={IconName.SEARCH} />
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.row}>
          <TokenDataItem
            title="Price USD"
            value={
              <div className={styles.column}>
                ${simulation && formatTokenPrice(simulation.p.u).formatted}
              </div>
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            title="Price WETH"
            value={
              <div className={styles.column}>
                {simulation && formatTokenPrice(simulation.p.p).formatted}
              </div>
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            title="Supply"
            className={styles.column}
            value={
              simulation && (
                <>
                  {formatNumber(simulation?.ts).formatted} {simulation.s}
                </>
              )
            }
            isDataAvailable={isDataAvailable}
          />
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title="Liquidity"
            value={
              simulation && (
                <div className={styles.column}>${formatTokenPrice(simulation.l.p.v).formatted}</div>
              )
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem title="FDV" value={null} isDataAvailable={false} />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            className={styles.column}
            title="MKT CAP"
            value={simulation && `$${formatTokenPrice(simulation.m).raw}`}
            isGreen
            isDataAvailable={isDataAvailable}
          />
        </div>
      </div>
    </div>
  )
}

export { TokenCard }
