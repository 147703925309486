import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getOrdersHistory } from '@/api/orders'
import { handleOrderHistoryResponse, handleOrdersStatusesChanging } from '@/libs/helper'
import { handleError } from '@/libs/helper/handleError'
import { TOrderHistory } from '@/libs/types/order.type'
import { TRootState } from '@/store'

import { fetchProfitSellForce } from './profit-sell.slice'

type TInitialState = {
  ordersHistory: TOrderHistory[] | null
  isLoading: boolean
  errorMessage: string | null
}

const initialState: TInitialState = {
  ordersHistory: null,
  isLoading: false,
  errorMessage: null,
}

const fetchOrdersHistory = createAsyncThunk(
  'chain/fetchOrderHistory',
  async (_, { getState, dispatch }) => {
    const ordersHistory = (getState() as TRootState).orders.ordersHistory
    const { data } = await getOrdersHistory()
    const updatedOrdersHistory = handleOrderHistoryResponse(data)
    if (!ordersHistory) return updatedOrdersHistory

    try {
      const pendingOrders = ordersHistory.filter((el) => el.hash[0] && !el.hash[0].status)
      const updatedOrders = updatedOrdersHistory.filter(
        (updatedOrder) =>
          pendingOrders.some((pendingOrder) => pendingOrder._id === updatedOrder._id) &&
          updatedOrder.hash[0].status?.status,
      )

      handleOrdersStatusesChanging(updatedOrders)

      if (updatedOrders.length !== 0) {
        dispatch(fetchProfitSellForce())
      }
    } catch (err) {
      handleError(err)
    }
    return updatedOrdersHistory
  },
)

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersHistory.fulfilled, (state, { payload }) => {
      state.ordersHistory = payload
      state.isLoading = false
    })

    builder.addMatcher(isAnyOf(fetchOrdersHistory.rejected), (state) => {
      state.isLoading = false
    })

    builder.addMatcher(isAnyOf(fetchOrdersHistory.pending), (state) => {
      state.isLoading = true
      state.errorMessage = null
    })
  },
})

export {
  orderSlice,
  // Thunks
  fetchOrdersHistory,
}
