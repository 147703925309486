import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SelectedTokenInfo } from '@/components/selected-token-info'
// import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { Spinner } from '@/components/spinner'
import { InputSearch } from '@/libs/common'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { SpinnerSize } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserHistory } from '@/store/slices/favorites-tokens.slice'

// import { GroupBundles } from './libs/components/group-bundles/group-bundles'
import { NoTokenSelected } from './libs/components/no-token-selected'
import { TokenData } from './libs/components/token-data'
import styles from './styles.module.scss'

const SearchWithTokenInfo: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isDefaultToken = searchParams.get('token') === DEFAULT_TOKEN
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(isDefaultToken)

  const handleSimulation = async () => {
    if (!selectedToken) return
    try {
      setIsLoading(true)
      await dispatch(fetchUserHistory(currentChain.id)).unwrap()
      searchParams.set('token', selectedToken.token.address)
      setSearchParams(searchParams)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleSimulation()
  }, [selectedToken])

  return (
    <section className={styles.section}>
      <div className={styles.topPart}>
        <div className={styles.searchBlock}>
          <InputSearch placeholder="Enter token name or address" />
        </div>

        <div className={styles.groupBlock}>
          {/* <ComingSoonOverlay />
          <GroupBundles /> */}
        </div>
      </div>

      <div className={styles.tokenInfoBlock}>
        {selectedToken || isDefaultToken ? (
          isLoading ? (
            <Spinner size={SpinnerSize.MEDIUM} centered />
          ) : (
            selectedToken && <SelectedTokenInfo selectedToken={selectedToken} />
          )
        ) : (
          <NoTokenSelected />
        )}
      </div>

      <div className={styles.additionalTokenInfo}>
        {selectedToken || isDefaultToken ? (
          <TokenData isDefaultToken={isDefaultToken} />
        ) : (
          <NoTokenSelected />
        )}
      </div>
    </section>
  )
}

export { SearchWithTokenInfo }
