import { useNavigate } from 'react-router-dom'

import styles from '@/components/header/styles.module.scss'
import { AppRoute } from '@/libs/enums'

export function ConnectWalletButton() {
  const navigate = useNavigate()
  return (
    <div
      className={styles.connectWalletBtn}
      onClick={() => navigate(`${AppRoute.MODAL}/${AppRoute.CONNECT_WALLET}`)}
    >
      Wallet connect
    </div>
  )
}
