import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { getProfitLossForTokens } from '@/api/profit-loss'
import { TProfitSellItem } from '@/api/profit-loss/types'
import { dateFromObjectId } from '@/libs/helper'
import { TRootState } from '@/store'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TStoreError = {
  profitSellFailed: boolean
}

type TInitialState = {
  profitSell: TProfitSellItem[] | null
  currentPosition: TProfitSellItem | null
  isLoading: boolean
  error: TStoreError
}

const initialStatus: TStoreError = {
  profitSellFailed: false,
}

const initialState: TInitialState = {
  isLoading: false,
  profitSell: null,
  currentPosition: null,
  error: initialStatus,
}

const {
  thunk: fetchProfitSell,
  thunkForce: fetchProfitSellForce,
  applyExtraReducers: applyExtraReducersForProfit,
} = createAsyncThunkWithControll(
  'profit/fetchProfitSell',
  async (_: undefined, { getState, dispatch }) => {
    const { data } = await getProfitLossForTokens()
    const filtered = data
      .filter((item) => !!item.panel._id)
      .sort(
        (a, b) => dateFromObjectId(b.panel._id).getTime() - dateFromObjectId(a.panel._id).getTime(),
      )

    const currentPosition = (getState() as TRootState).profitSell.currentPosition
    if (currentPosition) {
      const current = filtered.find((item) => item.panel._id === currentPosition.panel._id)
      dispatch(setCurrentPosition(current || null))
    }
    return filtered
  },
)

const profitSellSlice = createSlice({
  name: 'profitSell',
  initialState,
  reducers: {
    setCurrentPosition: (state, { payload }: PayloadAction<TProfitSellItem | null>) => {
      state.currentPosition = payload
    },
  },
  extraReducers: (builder) => {
    applyExtraReducersForProfit(builder, {
      onPending: (state) => {
        state.isLoading = true
      },
      onFulfilled: (state, { payload }) => {
        state.profitSell = payload.length ? payload.filter((item: any) => !!item.panel_data) : []
        if (state.error.profitSellFailed) state.error.profitSellFailed = false
        state.isLoading = false
      },
      onRejected: (state) => {
        state.error.profitSellFailed = true
        state.isLoading = false
      },
    })
  },
})

const { setCurrentPosition } = profitSellSlice.actions

export {
  profitSellSlice,
  // Reducers
  setCurrentPosition,

  // Thunks
  fetchProfitSell,
  fetchProfitSellForce,
}
