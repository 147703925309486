import { Grid } from '@mui/material'

import { SellTab } from '@/components/sell-tab'
import { Typography } from '@/libs/common'

const SellModal = () => {
  return (
    <Grid>
      <Typography variant="h2" marginBottom={3}>
        Sell
      </Typography>
      <SellTab usedInModal />
    </Grid>
  )
}

export { SellModal }
