import { FC, useMemo, useState } from 'react'

import { Grid } from '@mui/material'
import Stack from '@mui/material/Stack'

import { ButtonLink, ListButton, Typography } from '@/libs/common'
import { SearchTextfield } from '@/libs/common/input-search/libs'
import { AppMode, AppRoute } from '@/libs/enums'
import { AddTokenButton } from '@/pages/modal-page/libs/components/add-token/index'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const AddToken: FC = () => {
  const userHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket)
  const [search, setSearch] = useState('')
  const searchedHoldings = useMemo(() => {
    const keyword = search.replace(/(^\s+|\s+$)/g, '').toLowerCase()
    return userHoldings?.v.t.filter(
      (holding) =>
        holding.n?.toLowerCase().includes(keyword) || holding.a.toLowerCase().includes(keyword),
    )
  }, [search])
  return (
    <Stack className={styles.stack}>
      <Typography variant="h1" align="center" className={styles.title}>
        Add new token to Profit/Loss Panel
      </Typography>

      <Grid>
        <Typography variant="body2" textColor="light-grey">
          Select token
        </Typography>

        <SearchTextfield
          value={search}
          onChange={(event) => {
            setSearch(event.currentTarget.value)
          }}
          variant="standard"
          className={styles.search}
          placeholder="Enter token name or address"
        />

        <Stack maxHeight="80vh" overflow="auto" spacing={2} useFlexGap marginTop={2}>
          {searchedHoldings?.map((holding) => (
            <ButtonLink
              key={holding.a}
              to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.PROFIT_SELL_ADD_TOKEN_SETUP}/${holding.a}`}
            >
              <ListButton>
                <AddTokenButton holding={holding} />
              </ListButton>
            </ButtonLink>
          ))}
        </Stack>
      </Grid>
    </Stack>
  )
}

export { AddToken }
