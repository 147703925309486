import { FC, ReactElement, useState } from 'react'

import { Button, CardActionArea } from '@mui/material'
import cls from 'classnames'

import { Icon } from '@/libs/common/icon'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  iconName: ValueOf<typeof IconName>
  className?: string
  tooltip: ReactElement
  messageClassName?: string
  variant?: 'div' | 'button'
}

const IconWithTooltip: FC<TProperty> = ({
  iconName,
  className,
  tooltip,
  messageClassName,
  variant = 'button',
}) => {
  const [isShowMessage, setIsShowMessage] = useState(false)

  const handleHover = () => {
    setIsShowMessage(true)
  }
  const handleLeave = () => {
    setIsShowMessage(false)
  }

  return (
    <div className={styles.container}>
      {variant === 'button' ? (
        <Button
          disableRipple
          className={styles.iconWrapper}
          onMouseOver={handleHover}
          onMouseLeave={handleLeave}
        >
          <Icon name={iconName} />
        </Button>
      ) : (
        <CardActionArea
          component="div"
          disableRipple
          className={cls(styles.iconWrapper, className)}
          onMouseOver={handleHover}
          onMouseLeave={handleLeave}
        >
          <Icon name={iconName} />
        </CardActionArea>
      )}
      {isShowMessage && <div className={cls(styles.message, messageClassName)}>{tooltip}</div>}
    </div>
  )
}

export { IconWithTooltip }
