import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthCard } from '@/components/auth-card'
import { Button, ButtonCopy, Checkbox } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppRoute, VerificationStatus } from '@/libs/enums'
import { add2FA } from '@/libs/enums/verification-status.enum.ts'

import styles from './styles.module.scss'

const CreateMasterPassword: FC = () => {
  const [stored, setStored] = useState<boolean | any>(false)
  const [authenticator] = useState<any>(JSON.parse(localStorage.signUpData))
  const [enableCode, setEnableCode] = useState<boolean | any>(false)
  const navigate = useNavigate()

  function redirect() {
    if (enableCode) {
      add2FA()
      navigate(`/${AppRoute.ENABLE_2FA}`, { replace: false })
    } else {
      navigate(AppRoute.SETUP_WALLETS, { replace: false })
    }
  }

  return (
    <AuthCard
      verificationStatus={VerificationStatus.CREATE_MASTER_PASSWORD}
      className={styles.card}
      isHeaderButtons
    >
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title} align="center">
            Master password
          </Typography>
          <Typography variant="h2" textColor="color-grey-2" align="center">
            This master password ensures that your wallets are encrypted with your password,
            dramatically increasing the security of the system.
          </Typography>
        </div>
        <div className={styles.body}>
          <div className={styles.copyContainer}>
            <div>
              <Typography variant="body2" textColor="color-grey-2">
                Master password
              </Typography>
              <Typography variant="body1" textColor="color-grey-3">
                {authenticator.master}
              </Typography>
            </div>
            <div className={styles.btn}>
              <ButtonCopy value={authenticator.master} className={styles.copyBtn} />
            </div>
          </div>
          <Checkbox
            label="I have securely stored the master password for my account and understand that if I forget it, I will not be able to recover the account."
            value={stored}
            onChange={() => setStored(!stored)}
          />
          <Checkbox
            label="(Optional) I want to enable two-factor authentication for extra security."
            value={enableCode}
            onChange={(value) => setEnableCode(value)}
          />
        </div>

        <Button disabled={!stored} type="submit" onClick={() => redirect()}>
          Continue
        </Button>
      </div>
    </AuthCard>
  )
}

export { CreateMasterPassword }
