import { FC, useState } from 'react'

import cls from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common/button-group-radio'
import { Input, TInputProps } from '@/libs/common/input/input'
import { Typography } from '@/libs/common/typography'
import { TSelectOption } from '@/libs/types/select-option.type'

import styles from './styles.module.scss'

type TProperty = TInputProps & {
  options: TSelectOption<number | string>[]
  radioGroupName: string
  onOptionSelect: (newValue: number | string | string[]) => void
  withCustomInput?: boolean
  validateCustomInput?: (newValue: string, oldValue: string) => string
  isNumeric?: boolean
}

const InputWithRadioGroup: FC<TProperty> = ({
  options,
  disabled,
  radioGroupName,
  onOptionSelect,
  className,
  value,
  withCustomInput,
  validateCustomInput,
  isNumeric,
  ...props
}) => {
  const [customValue, setCustomValue] = useState('')

  return (
    <div className={styles.container}>
      <Input
        isNumeric={isNumeric}
        className={cls(styles.input, className)}
        value={value}
        {...props}
        disabled={disabled}
      />
      <ButtonGroupRadio
        exclusive
        className={styles.radioGroup}
        value={value}
        onChange={(_, value) => {
          onOptionSelect(value)
          setCustomValue('')
        }}
        disabled={disabled}
      >
        {withCustomInput && (
          <Input
            isNumeric={isNumeric}
            className={cls(styles.customInput, styles.radioButton)}
            value={customValue}
            onChange={(e) => {
              const newValue = e.target.value
              const validated = validateCustomInput
                ? validateCustomInput(newValue, customValue)
                : newValue
              setCustomValue(validated)
              onOptionSelect(validated)
            }}
            placeholder="Custom"
            disabled={disabled}
          />
        )}
        {options.map((button) => (
          <ButtonGroupRadioButton
            value={button.value}
            name={radioGroupName}
            className={styles.radioButton}
            key={button.value}
          >
            <Typography
              variant="caption"
              className={cls(styles.buttonContent, { [styles.disabled]: disabled })}
            >
              {button.label}
            </Typography>
          </ButtonGroupRadioButton>
        ))}
      </ButtonGroupRadio>
    </div>
  )
}

export { InputWithRadioGroup }
